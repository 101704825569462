<style scoped lang="less">

    .singleRowProduct-module{
        
        .coupon-list{
            padding: 0 10px;
			display: flex;
			
			.item{
				position: relative;
				margin-bottom: 5px;
				
				&:last-child{
					margin-bottom: 0;
				}
				
				.bg{
					display: block;
				    width: 100%;
				}
				
				.box{
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					color: #fff;
					
					display: flex;
					
					.left{
						flex: 0 0 auto;
						width: 106px;
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						font-size: 12px;
						
						.price{
							font-size: 15px;
							margin-top: -2px;
							margin-bottom: 5px;
							
							span{
								font-size: 25px;
							}
						}
					}
					
					.right{
						flex: 0 0 auto;
						width: 150px;
						margin-left: 10px;
						display: flex;
						align-self: center;
						font-size: 13px;
						line-height: 18px;
					}
				}
			}
			
        }
        
    }
    .haveSpace{
        margin-bottom: 10px;
    }
    
</style>

<template>
    <div class="singleRowProduct-module" @mouseenter="data.visible=true" @mouseleave="data.visible=false" style="position: relative;padding-left: 40px;padding-right: 45px;" :class="{haveSpace: !data.hideSpace}">
        <module-name :name="name"></module-name>
        <controler @edit="edit();data.editVisible=true" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        <!-- <edit :data="data" @submit="$emit('submit')" :visible="data.editVisible" :name="name" @close="data.editVisible=false"  /> -->
        
        <div class="coupon-list">  <!-- @click="edit();data.visible=true" -->
			<div v-if="data.list.length">
				<div class="item" v-for="item in data.list" :key="item.Id">
					<img class="bg" src="http://cdn.dkycn.cn/images/melyshop/page-manage-coupon.png" />
					<div class="box">
						<div class="left">
							<div class="price" v-if="item.UseType === 0">￥<span>{{item.MinMoney}}</span></div>
							<div class="price" v-if="item.UseType === 1"><span>{{item.Discount/10}}折</span></div>
							<div class="price" v-if="item.UseType === 2">￥<span>{{item.MinMoney}}~{{item.MaxMoney}}</span></div>
							<div class="tip">
								<span v-if="item.FullMoney">满{{item.FullMoney}}元可用</span>
								<span v-else>无门槛</span>
							</div>
						</div>
						<div class="right">
							{{item.CouponName}}
						</div>
					</div>
				</div>
			</div>
			<div class="item" v-else>
				<img class="bg" src="http://cdn.dkycn.cn/images/melyshop/page-manage-coupon.png" />
				<div class="box">
					<div class="left">
						<div class="price">￥<span>50</span></div>
						<div class="tip">无门槛使用</div>
					</div>
					<div class="right">
						优惠券名称优惠券名称 优惠券名称优惠券名称
					</div>
				</div>
			</div>
        </div>
		
		<div class="cover" v-if="data.visible" style="position: absolute;left:40px;top:0;right: 45px;height: 100%;border: 3px solid #0000FF;"></div>
		
    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';

export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        edit
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
    data () {
        return {
        };
    },
    methods: {
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        
        close(){
            this.$emit('visibleChange', false)
        }

    },
    mounted () {
		// console.log(this.data)
    },
    watch: {
        data(value){
            // console.log(value)
        }
    }
};
</script>

