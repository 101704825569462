<style lang="less">
	.channels-live-module-edit {
		// position: absolute;
		// right: -530px;
		width: 480px;
		padding: 20px 20px 30px;
		background: #fff;
		font-size: 14px;

		.title {
			position: relative;
			font-size: 18px;
			padding-bottom: 20px;
			margin-bottom: 25px;
			border-bottom: 1px solid #ddd;
		}

		.icon-close {
			position: absolute;
			right: 0;
			cursor: pointer;
		}

		.message {
			font-size: 16px;
			color: #999;
			text-align: center;
		}

		.model-tip {
			border: 1px solid #409EFF;
			padding: 6px 10px;
			font-size: 13px;
			color: #666;
			line-height: 20px;

			span {
				font-weight: bold;
			}
		}

		.tit {
			font-size: 16px;
			font-weight: bold;
			padding: 30px 0 10px;
		}

		.avatar-uploader .el-upload {
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
		}

		.avatar-uploader .el-upload:hover {
			border-color: #409EFF;
		}

		.avatar-uploader-icon {
			font-size: 28px;
			color: #8c939d;
			width: 290px;
			height: 145px;
			line-height: 145px;
			text-align: center;
		}

		.avatar {
			width: 290px;
			height: 145px;
			display: block;
		}

		.img-tip {
			font-size: 12px;
			color: #999;
			line-height: 16px;
		}
		
		.normal-img{
			width: 100px;
			height: 100px;
			overflow: hidden;
			background-color: #f5f5f5;
			margin-top: -20px;
			
			img{
				display: block;
				width: 100%;
			}
		}
		
		.custom-img{
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
			width: 100px;
			height: 100px;
			margin-top: -15px;
		}
		.custom-img .el-upload:hover {
			border-color: #409EFF;
		}
		
		.custom-img-icon {
			font-size: 28px;
			color: #8c939d;
			width: 100px;
			height: 100px;
			line-height: 100px;
			text-align: center;
		}
		
		.custom-img-url {
			width: 100px;
			height: 100px;
			display: block;
		}
		
		.custom-img-box{
			display: flex;
		}
		
		.no-auth{
			font-size: 16px;
			color: #666;
			width: 280px;
			margin: 50px auto;
			line-height: 24px;
		}
		
		

		.mod-footer {
			margin-top: 30px;
			padding-top: 20px;
			border-top: 1px solid #ddd;

			display: flex;

			.left {
				flex: 1 1 auto;
				padding-top: 6px;
			}
		}
		
		
		.diff-wraper{
			display: flex;
			justify-content: space-between;
		}
	}
	
	.select-nodata{
		padding: 30px 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		color: #999;
	}
	.select-other{
		height: 34px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
</style>

<template>
	<!-- <transition name="scale"> -->
	<div class="channels-live-module-edit" v-if="visible">

		<p class="title">
			{{name}}
			<!-- <Icon  @click="close" type="ios-close-empty" size=32 class="icon-close" color="#999"></Icon> -->
		</p>

		<div class="model-tip">
			<span>模块说明：</span>在商城页面内，添加【视频号直播】模块，客户进入商城点击模块可直接进入视频号直播间；您也可以开启视频号直播预约入口。
			<el-link type="primary" @click="exampleVisible=true">查看示例</el-link>
		</div>
		
		<div v-if="videoLiveId">
			
			<div class="tit">视频号ID:</div>
			<el-form label-width="110px">
				<el-form-item label="视频号ID">
					<div class="diff-wraper" v-if="videoLiveIsDifferentSubject">
						<div class="left">
							<span style="color: #F56C6C;">设置错误，请修改</span>
							<el-popover
							    placement="top"
							    title=""
							    width="400"
							    trigger="hover">
								
								<div>
									视频号未完成认证或与商城小程序主体不一致等原因，会造成设置错误。
									<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccnlWxJlecPmtizgkzBnZitvh" target="_blank">查看更多原因及解决方法</el-link>
								</div>
								
								<i slot="reference" class="el-icon-question" style="font-size: 15px;color: #999999;margin-left: 5px;"></i>
							  </el-popover>
						</div>
						
						<div class="right">
							<el-link type="primary" style="line-height: 1.5;" @click="toEditLiveId">修改</el-link>
						</div>
					</div>
					<div v-else>{{videoLiveId}}</div>
				</el-form-item>
			</el-form>
			
			
			<div class="tit" style="border-top: 1px solid #ddd;">进入直播:</div>
			<el-form label-width="110px">
				<el-form-item label="入口样式">
					<el-radio v-model="data.style" :label="1">系统默认样式</el-radio>
					<el-radio v-model="data.style" :label="2">自定义上传入口图片</el-radio>
				</el-form-item>
				
				<!-- 系统默认样式相关配置 -->
				<div v-if="data.style==1">
					<el-form-item label="直播间封面">
						
						<div class="custom-img-box">
							<el-upload class="custom-img" :action="uploadApi" :show-file-list="false"
								:on-success="handleCoverSuccess" :before-upload="beforeCoverUpload">
								<img v-if="data.coverImage" :src="imgUrl + data.coverImage" class="custom-img-url">
								<i v-else class="el-icon-plus custom-img-icon"></i>
							</el-upload>
							<el-link type="primary" @click="data.coverImage=''" style="margin-top: 66px;line-height: 18px;height: 18px;margin-left: 10px;">清空图片</el-link>
						</div>
						<div class="img-tip" style="margin-top: 7px;">图片建议宽高比1:1；图片大小请控制在1M以内<br/>若未上传，将使用视频号头像</div>
					</el-form-item>
				</div>
				
				<!-- 自定义上传入口图片配置 -->
				<el-form-item label="" v-if="data.style==2">
					<el-upload class="avatar-uploader" :action="uploadApi" :show-file-list="false"
						:on-success="handleSuccess" :before-upload="beforeUpload">
						<img v-if="data.customInLiveImage" :src="imgUrl + data.customInLiveImage" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<div class="img-tip">图片大小请控制在1M以内</div>
				</el-form-item>
			</el-form>
			
			
			<div class="tit" style="border-top: 1px solid #ddd;">预约直播:</div>
			<el-form label-width="110px">
				<el-form-item label="预约入口">
					<el-radio v-model="data.showSubscribe" :label="true">显示</el-radio>
					<el-radio v-model="data.showSubscribe" :label="false">隐藏</el-radio>
					<div class="img-tip">入口显示后，客户进入商城可看到直播预告，并订阅开播提醒</div>
				</el-form-item>
				
				<div v-if="data.showSubscribe">
					<el-form-item label="入口样式">
						<el-radio v-model="data.subscribeStyle" :label="1">系统默认样式</el-radio>
						<el-radio v-model="data.subscribeStyle" :label="2">自定义上传入口图片</el-radio>
					</el-form-item>
					
					<!-- 直播介绍 -->
					<div v-if="data.subscribeStyle==1">
						<el-form-item label="直播介绍">
							<el-input type="textarea" placeholder="例:直播间福利大放送，最多可输入60个字" maxlength="60" rows="1" autosize v-model="data.subscribeDesc"></el-input>
							<div class="img-tip" style="margin-top: 7px;">若未填写，将默认使用视频号昵称</div>
						</el-form-item>
					</div>
					
					
					<!-- 自定义上传预约入口样式 -->
					<el-form-item label="" v-if="data.subscribeStyle==2">
						<el-upload class="avatar-uploader" :action="uploadApi" :show-file-list="false"
							:on-success="handleSubscribeSuccess" :before-upload="beforeSubscribeUpload">
							<img v-if="data.customSubscribeImage" :src="imgUrl + data.customSubscribeImage" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
						<div class="img-tip">图片大小请控制在1M以内</div>
					</el-form-item>
				</div>
				
			</el-form>
			
			
			<div class="mod-footer">
				<div class="left">
					<el-checkbox v-model="data.hideSpace"><span style="font-size: 14px;color:#666">隐藏模块下方的间距</span>
					</el-checkbox>
				</div>
			</div>
		</div>
		<div v-else class="no-auth">
			商城还未绑定视频号ID，请
			<el-link type="primary" @click="toEditLiveId" style="font-size: 16px;">前往绑定</el-link>
		</div>
		
		<el-dialog :visible.sync="exampleVisible" title="示例" width="740px">
			<img src="https://cdn.dkycn.cn/images/melyshop/20220228093905.png" style="display: block;width: 100%;">
		</el-dialog>
		 
		
	</div>
	<!-- </transition> -->
</template>

<script>
	import config from '@/config/index';
	import {
		mapGetters
	} from 'vuex'
	import {videoliveapplyinfo} from '@/api/pageManage.js'
	import {
		channelShopchannelShopLiveConfig
	} from '@/api/goods'

	export default {
		name: 'channels-live-edit',
		components: {},
		props: {
			data: {
				type: Object,
				default: () => {
					return {}
				}
			},
			visible: {
				type: Boolean,
				default: false
			},
			name: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				uploadApi: config.UPLOAD_IMG,
				imgUrl: config.IMG_BASE,
				
				videoLiveId: '',
				videoLiveIsDifferentSubject: false,
				
				exampleVisible: false
			};
		},
		computed: {
			...mapGetters([
				"mallInfo"
			]),
		},
		methods: {
			close() {
				this.$emit('close')
			},
			
			/**
			 * 自定义直播间入口样式-上传图片
			 */
			beforeUpload(file) {
				const isLt1M = file.size / 1024 / 1024 < 1;
				const isJPG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';
				const isGIF = file.type === 'image/gif';
				
				if (!isJPG & !isPNG && !isGIF){
					this.$message.error('请上传正确的图片格式');
					return false
				}
				if (!isLt1M) {
					this.$message.error('图片大小请控制在1M以内');
					return false
				}
				return true;
			},
			handleSuccess(res, file){
				this.data.customInLiveImage = res[0]
			},
			
			/**
			 * 直播间封面-上传图片
			 */
			beforeCoverUpload(file) {
				const isLt1M = file.size / 1024 / 1024 < 1;
				const isJPG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';
				const isGIF = file.type === 'image/gif';
				
				if (!isJPG & !isPNG && !isGIF){
					this.$message.error('请上传正确的图片格式');
					return false
				}
				if (!isLt1M) {
					this.$message.error('图片大小请控制在1M以内');
					return false
				}
				return true;
			},
			handleCoverSuccess(res, file){
				this.data.coverImage = res[0]
			},
			
			
			/**
			 * 自定义预约入口样式-上传图片
			 */
			beforeSubscribeUpload(file) {
				const isLt1M = file.size / 1024 / 1024 < 1;
				const isJPG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';
				const isGIF = file.type === 'image/gif';
				
				if (!isJPG & !isPNG && !isGIF){
					this.$message.error('请上传正确的图片格式');
					return false
				}
				if (!isLt1M) {
					this.$message.error('图片大小请控制在1M以内');
					return false
				}
				return true;
			},
			handleSubscribeSuccess(res, file){
				this.data.customSubscribeImage = res[0]
			},
			
			/**
			 * 获取视频号id：ChannelShopLiveId
			 * 获取是否是同一主体： VideoLiveIsDifferentSubject
			 */
			async getData(){
				try{
					const res = await channelShopchannelShopLiveConfig();
					this.videoLiveId = res.Result.ChannelShopLiveId;
					this.videoLiveIsDifferentSubject = res.Result.VideoLiveIsDifferentSubject;
				}catch(e){
					//TODO handle the exception
				}
			},
			
			/**
			 * 去绑定视频号
			 */
			toEditLiveId(){
				this.$router.push({
					name: 'videoLiveBinding',
				})
			}
		},
		mounted() {
			this.getData();
		},
		watch: {}
	};
</script>
