<style scoped lang="less">
	.singleRow-module-edit{
        padding: 20px 20px 30px;
        background: #fff;
        font-size: 14px;
		
		.title{
		    position: relative;
		    font-size: 18px;
		    padding-bottom: 20px;
		    margin-bottom: 25px;
		    border-bottom: 1px solid #ddd;
		}
		
		.tip{
			font-size: 12px;
			color: #999;
			width: 370px;
			line-height: 18px;
		}
		
		.add-btn {
			text-align: center;
			border: 1px dashed #ddd;
			font-size: 16px;
			color: #666;
			height: 48px;
			line-height: 48px;
			text-align: center;
			margin-bottom: 18px;
			cursor: pointer;
			width: 370px;
			margin-top: 30px;
		}
		
		
		.share-image-wraper{
			display: flex;
			align-items: center;
			
			.right{
				width: 226px;
				margin-left: 10px;
				padding-top: 8px;
				
				.right-tip{
					font-size: 12px;
					color: #999;
					line-height: 16px;
				}
				.right-clear{
					font-size: 12px;
					color: #1890ff;
					cursor: pointer;
				}
			}
		}
		
		.share-uploader .el-upload {
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
			
		}
		
		.share-uploader .el-upload:hover {
			border-color: #409EFF;
		}
		
		.share-uploader {
			font-size: 28px;
			color: #8c939d;
			width: 120px;
			height: 67.5px;
			line-height: 67.5px;
			text-align: center;
			border: 1px dashed #ddd;
			overflow: hidden;
		}
		.avatar-uploader-icon{
			font-size: 28px;
			color: #8c939d;
			width: 120px;
			height: 67.5px;
			line-height: 67.5px;
			text-align: center;
		}
		
		.avatar {
			width: 120px;
			height: 67.5px;
			display: block;
		}
		
		
		.videoBox {
			position: relative;
		
			.deletIcon {
				width: 152px;
				height: 152px;
				position: absolute;
				top: 0;
				left: 0;
				background: rgba(0, 0, 0, .4);
				cursor: pointer;
		
				.deleteImg {
					position: absolute;
					font-size: 20px;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
		
			}
		
			.Displayblock {
				display: block;
			}
		
			.Displaynone {
				display: none;
			}
		}
		
	}
	
	.mod-footer{
	    margin-top: 30px;
	    padding-top: 20px;
	    border-top: 1px solid #ddd;
	
	    display: flex;
	
	    .left{
	        flex: 1 1 auto;
	        padding-top: 6px;
	    }
	}

    
</style>

<template>
	<!-- <transition name="scale"> -->
	<div class="singleRow-module-edit" v-if="visible">

		<p class="title">
			{{name}}
			<Icon @click="close" type="ios-close-empty" size=32 class="icon-close" color="#999"></Icon>
		</p>

		<el-form label-width="40px" size="mini">
			<el-form-item label="视频">
				<el-radio-group v-model="data.videoType">
					<el-radio :label="1">上传视频</el-radio>
					<el-radio :label="2">粘贴视频地址</el-radio>
				</el-radio-group>
			</el-form-item>
		</el-form>

		<div class="tip" v-if="data.videoType=='1'">建议视频宽高比16:9，视频大小不超过30MB；目前仅支持上传mp4格式的文件</div>
		<div class="tip" v-if="data.videoType=='2'">仅支持mp4格式的视频源地址</div>

		<!-- <div class="add-btn" v-if="data.videoType=='1'">
			<el-upload :action="imgApi" :show-file-list="false" :on-success="handleVideoSuccess" :before-upload="beforeVideoUpload">
				<i class="el-icon-circle-plus-outline" style="margin-right: 5px;vertical-align: -2px;font-size: 18px;"></i>上传视频
			</el-upload>
		</div> -->
		<el-form label-width="70px" size="mini" style="margin-top: 20px;">
			<el-form-item label="主图视频" v-if="data.videoType=='1'">
				<div class="videoBox">
					<el-button type="text" style="font-size: 12px;" @click="deleteVideo" :disabled="!data.videoUrl">删除视频</el-button>
					<el-upload :action="imgApi" :show-file-list="false" :on-success="handleVideoSuccess" list-type="picture-card"
					 :before-upload="beforeVideoUpload">
						<video v-if="data.videoUrl" :src="imgUrl+data.videoUrl" class="avatar" controls="controls" style="width:148px;height:148px">您的浏览器不支持视频播放</video>
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</div>
			</el-form-item>
		<!-- </el-form> -->

		<!-- <el-form label-width="70px" size="mini" style="margin-top: 20px;" v-if="data.videoType=='2'"> -->
			<el-form-item label="视频地址" v-if="data.videoType=='2'">
				<el-input type="text" style="width: 365px;" v-model="data.customVideoUrl" @blur="validVideo"></el-input>
			</el-form-item>
		<!-- </el-form> -->
		
		<!-- <el-form label-width="70px" size="mini" style="margin-top: 30px;"> -->
			<el-form-item label="视频封面" prop="title">
				<div class="share-image-wraper">
					<el-upload class="share-uploader" :action="imgApi" :show-file-list="false" :on-success="handleSuccess"
					 :before-upload="beforeUpload">
						<img v-if="data.coverImage" :src="imgUrl + data.coverImage" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<div class="right">
						<div class="right-tip">建议图片宽高比16:9,宽高比不匹配时,图片将被压缩或拉伸以铺满画面;大小请控制在1M以内</div>
						<div class="right-clear" @click="handleClear">清空图片</div>
					</div>
				</div>

			</el-form-item>
		</el-form>
		
		<div class="mod-footer">
			<div class="left">
				<el-checkbox v-model="data.hideSpace"><span style="font-size: 14px;color:#666">隐藏模块下方的间距</span></el-checkbox>
			</div>
			<!-- <div class="right">
		            <Button type="primary" @click="saveData">保存</Button>
		        </div> -->
		</div>
		
	</div>
	<!-- </transition> -->
</template>

<script>
	import config from '@/config/index'

	export default {
		name: 'normal-module-edit',
		components: {},
		props: {
			data: {
				type: Object,
				default: () => {
					return {}
				}
			},
			visible: {
				type: Boolean,
				default: false
			},
			name: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_ZIP,
				
				ruleForm: {
					VideoUrl: ''
				}
			};
		},
		methods: {
			close() {
				this.$emit('close')
			},
			handleSuccess(res, file) {
				this.data.coverImage = res[0];
				// this.$forceUpdate()
			},
			beforeUpload(file) {
				const isLt1M = file.size / 1024 / 1024 < 1;

				if (!isLt1M) {
					this.$message.error('视频封面图片大小请控制在1M以内');
				}
				return isLt1M;
			},
			handleClear() {
				this.data.coverImage = '';
			},
			
			handleVideoSuccess(res, file) {
				this.data.videoUrl = res[0];
			},
			beforeVideoUpload(file) {
				console.log(file)
				const isLt30M = file.size / 1024 / 1024 < 30;
				
				if (['video/mp4'].indexOf(file.type) == -1) {
					this.$message.error('仅支持mp4格式文件');
					return false;
				}
				
				if (!isLt30M) {
					this.$message.error('视频大小请控制在30M及以内');
					return false;
				}
			},
			deleteVideo(){
				this.data.videoUrl = ''
			},
			validVideo(){
				console.log(this.data.customVideoUrl)
				var url = this.data.customVideoUrl;
				var mp4 = this.data.customVideoUrl.substr(url.length-4, 4);
				if (mp4.toLowerCase() != '.mp4') {
					this.$message.error('不支持此格式的视频源地址');
					this.data.customVideoUrl = ''
					return false;
				}
			}
			
			
		},
		mounted() {

		},
		watch: {
			data(value) {}
		}
	};
</script>
