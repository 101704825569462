<style scoped lang="less">
    .singleRowProduct-module{
        .mainBox{
          display: flex;
          justify-content: center;
          align-items: flex-start;
          .shop{
            width: 100%;
            .label{
              width: 100%;
              background: white;
              font-size: 14px;
              color: #606266;
              display: flex;
              align-items: center;
              overflow: hidden;
              box-sizing: border-box;
              padding: 0 7px;
              .demo{
                position: relative;
                padding: 5px 10px;
                flex-shrink: 0;
                // border: 1px solid black;
                height: 52px;
              }
              .winnow{
                position: absolute;
                bottom: 15px;
                width: 20px;
                height: 5px;
                border-radius: 5px;
                left: calc(50% - 10px);
                background: linear-gradient(to right,#E02E24 ,rgba(224,46,36,0.2));
              }
            }
            .screen{
              width: 100%;
              color: #666666;
              display: flex;
              align-items: center;
              justify-content: space-around;
              overflow: hidden;
              box-sizing: border-box;
              padding: 5px 20px;
              .module{
                font-size: 12px;
                padding: 5px ;
                .icon-zoom{
                  transform: scale(.8);
                }
              }
            }
            .productBox{
              padding: 0 7px;
              gap:10px;
              .block{
                border-radius: 3px;
                padding: 7px;
                background: white;
                display: flex;
                align-items: flex-start;
                font-size: 14px;
                img{
                  width: 110px;
                  height: 110px;
                  border-radius: 5px;
                }
                .right{
                  .inline-tag{
                    display: inline-block;
                    padding: 3px;
                    background: rgb(224, 46, 36);
                    color: white;
                    font-size: 12px;
                    line-height: 14px;
                    border-radius: 2px;
                  }
                  .vouchers{
                    border: 1px solid #E02E24FF;
                    border-radius: 2px;
                    font-size: 12px;
                    color: #E02E24FF;
                    height: 17px;
                    line-height: 17px;
                    padding: 0 2px;
                    .line{
                      border: 1px dashed #E02E24FF;
                      margin: 0 2px;
                    }
                  }
                  .red-button{
                    background: #E02E24FF;
                    padding: 2px 5px;
                    border-radius: 3px;
                    color: white;
                    .white-box{
                      width: 14px;
                      height: 14px;
                      text-align: center;
                      line-height: 14px;
                      font-size: 12px;
                      background: white;
                      color: #E02E24FF;
                      border-radius: 1px;
                    }
                  }
                }
              }
            }
          }
        }
        
    }
    .haveSpace{
        margin-bottom: 20px;
    }
</style>

<template>
    <div class="singleRowProduct-module"  @mouseenter="data.visible=true" @mouseleave="data.visible=false" style="position: relative;padding-left: 40px;padding-right: 45px;" :class="{haveSpace: !data.hideSpace}">
        <module-name :name="name"></module-name>
        <controler @edit="edit();data.editVisible=true" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        
        
        <div class="mainBox">
          <div class="shop">
            <div class="label" v-if="data.showBoutique||(data.labelList&&data.labelList.length)">
              <div class="demo flex flex-column font-bold font-16" v-if="data.showBoutique">
                {{data.title||'精选'}}<div class="winnow"></div>
              </div>
              <div class="demo" v-for="(v,i) in data.labelList" :key="i">{{v.TagName}}
                <div class="winnow" v-if="i==0&&!data.showBoutique"></div>
              </div>
            </div>
            <div class="screen" v-if="data.screen&&data.screen.length">
              <div class="module flex flex-align-center color-f56c6c">
                <span>全部</span>
              </div>
              <div class="module" v-for="(v,i) in screenList" :key="i" v-if="data.screen.includes(v.value)">
                <div class="flex flex-align-center">
                  <span>{{v.label}}</span>
                  <div class="icon-zoom flex flex-column flex-align-center">
                    <i class="el-icon-caret-top" style="margin-bottom:-2px"></i>
                    <i class="el-icon-caret-bottom color-999999" style="margin-top:-2px"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="productBox flex flex-column gap-10">
              <div class="block gap-10" v-for="(v,i) in shoplist" :key="i">
                <img :src="v.ImgUrl||defaultImg" alt="">
                <div class="right flex flex-column flex-justify-between flex-1">
                  <div class="ellipsis-two color-303133">
                    <span class="inline-tag">拼多多</span>
                    {{v.Name}}
                  </div>
                  <div class="flex flex-align-end flex-justify-between margin-top-10">
                    <div class="flex flex-align-end font-bold font-14 color-303133">
                      <span>￥</span>
                      <span class="font-16">{{v.MinGroupPrice}}</span>
                      <span class="font-12 color-999999 margin-left-10" style="text-decoration:line-through">￥{{v.MinNormalPrice}}</span>
                    </div>
                    <div class="font-12 color-999999">
                      已拼200万+件
                    </div>
                  </div>
                  <div class="flex flex-align-end flex-justify-between margin-top-10">
                    <div>
                      <div class="flex flex-align-center gap-10">
                        <div class="vouchers flex">
                          <span>券</span>
                          <div class="line"></div>
                          <span>15元</span>
                        </div>
                        <!-- <div class="vouchers flex">
                          <span>收益</span>
                          <div class="line"></div>
                          <span>10.83%</span>
                        </div> -->
                      </div>
                      <div class="font-12 color-999999 margin-top-5">店铺名称店铺名称</div>
                    </div>
                    <div class="red-button flex flex-align-center font-12" style="border:none">
                      <div class="white-box">{{i%2==0?'赚':'省'}}</div>
                      <span>￥</span>
                      <span class="font-16">1.99</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        
		    <div class="cover" v-if="data.visible" style="position: absolute;left:40px;top:0;right: 45px;height: 100%;border: 3px solid #0000FF;"></div>
    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';
import {
  taoKeProductlist
} from "@/api/sv3.0.0"


export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        edit,
        
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
    data () {
        return {
          defaultList:[
            {
              Name:'商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称',
              ImgUrl:'',
              MinNormalPrice:'199',
              MinGroupPrice:'119',
            },
            {
              Name:'商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称',
              ImgUrl:'',
              MinNormalPrice:'199',
              MinGroupPrice:'119',
            },
            {
              Name:'商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称',
              ImgUrl:'',
              MinNormalPrice:'199',
              MinGroupPrice:'119',
            },
          ],
          shoplist:[],
          defaultImg:'http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/duoduoShop-defaultImg.png',
          screenList:[
            // {value:0,label:'收益比例'},
            {value:1,label:'拼团价'},
          ]
        };
    },
    watch: {
      'data.labelList'(list){
        this.IsSearch()
      },
      'data.showBoutique'(val){
        this.IsSearch()
      }
    },
    mounted () {
      this.IsSearch()
    },
    methods: {
      IsSearch(){
        if(this.data.showBoutique||(this.data.labelList&&this.data.labelList.length)){
          this.getShop()
        }else{
          this.shoplist = this.defaultList
        }
      },
      async getShop(){
        try{
          let data = {
            TaoKeQidianTagId:'',
            IsBoutique:this.data.showBoutique==true?this.data.showBoutique:null,
            PlatformType:1,
						Skip:0, // 略过数据
						Take:9, // 取的数据
          }
          if(this.data.labelList&&this.data.labelList.length){
            if(this.data.showBoutique){
              data.TaoKeQidianTagId = ''
            }else{
              data.TaoKeQidianTagId = this.data.labelList[0].TagId
            }
          }
          let res = await taoKeProductlist(data)
          if(res.IsSuccess){
            if(res.Result.Results&&res.Result.Results.length){
              this.shoplist = res.Result.Results
            }else{
              this.shoplist = this.defaultList
            }
          }
        }finally{
          //
        }
      },
        getScreen(e){
          if(e==0){
            return '收益比例'
          }else if(e==1){
            return '拼团价'
          }
          return ''
        },
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        
        close(){
            this.$emit('visibleChange', false)
        }

    },
};
</script>

