<style scoped lang="less">
	.singleRow-module-edit {
		// position: absolute;
		// right: -530px;
		width: 480px;
		padding: 20px 20px 30px;
		background: #fff;
		font-size: 14px;

		.title {
			position: relative;
			font-size: 18px;
			padding-bottom: 20px;
			margin-bottom: 25px;
			border-bottom: 1px solid #ddd;
		}

		.add-btn {
			text-align: center;
			border: 1px dashed #ddd;
			font-size: 16px;
			color: #666;
			height: 48px;
			line-height: 48px;
			text-align: center;
			margin-bottom: 18px;
			cursor: pointer;
			width: 440px;
		}

		.product-list {
			padding-top: 10px;
			overflow: hidden;

			.product-item {
				position: relative;
				float: left;
				width: 65px;
				height: 65px;
				margin-right: 10px;
				margin-bottom: 10px;
			}

			.img {
				position: relative;
				width: 65px;
				height: 65px;
				overflow: hidden;

				img {
					display: block;
					width: 100%;
				}
			}

			.control {
				position: absolute;
				top: -10px;
				right: -10px;
			}
		}

		.link-info {
			width: 440px;
			height: 28px;
			line-height: 28px;
			background: #409eff;
			padding: 0 5px;
			margin-bottom: 10px;
			color: #fff;
			font-size: 14px;

			display: flex;

			.cont {
				flex: 1 1 auto;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.icon {
				flex: 0 0 auto;
			}
		}

		.mod-footer {
			margin-top: 50px;
			padding-top: 20px;
			border-top: 1px solid #ddd;

			display: flex;

			.left {
				flex: 1 1 auto;
				padding-top: 6px;
			}
		}
	}
</style>

<template>
	<!-- <transition name="scale"> -->
	<div class="singleRow-module-edit" v-if="visible">
		<p class="title">
			{{ name }}
			<!-- <Icon  @click="close" type="ios-close-empty" size=32 class="icon-close" color="#999"></Icon> -->
		</p>

		<div v-if="data.list && data.list.length">
			<draggable class="product-list" :list="data.list" :options="{ animation: 300, handle: '.product-item' }">
				<transition-group name="bounce-out-left">
					<div class="product-item" :key="item.id" v-for="item in data.list">
						<div class="img"><img :src="$store.state.pageManageConfig.imghost + item.ImgUrl" /></div>
						<div class="control" @click="deleteProduct(item)"><i class="el-icon-error" style="font-size: 24px;opacity: .6;"></i></div>
					</div>
				</transition-group>
			</draggable>
		</div>
		<div class="add-btn" @click="showShopModal">
			<i class="el-icon-circle-plus-outline" style="margin-right: 5px;vertical-align: -2px;font-size: 18px;"></i>
			添加商品
		</div>

		<div class="mod-footer">
			<div class="left">
				<el-checkbox v-model="data.hideSpace"><span style="font-size: 14px;color:#666">隐藏模块下方的间距</span></el-checkbox>
			</div>
		</div>

		<shop-modal :visible="shopVisible" :selectedList="data.list" :activityId="data.activityData.Id" @change="dataChange"
		 @cancel="shopVisible=false"></shop-modal>
		
	</div>
	<!-- </transition> -->
</template>

<script>
	import shopModal from '../../../../components/wechatGroupShopListModal.vue';

	import {
		editSelectDiscountProduct
	} from '@/api/pageManage.js';

	import draggable from 'vuedraggable';

	export default {
		name: 'normal-module-edit',
		components: {
			shopModal,
			draggable
		},
		props: {
			data: {
				type: Object,
				default: () => {
					return {};
				}
			},
			visible: {
				type: Boolean,
				default: false
			},
			name: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				shopVisible: false,
				days: 0,
				hours: 0,
				minutes: 0,
				seconds: 0
			};
		},
		methods: {
			close() {
				this.$emit('close');
			},
			saveData() {
				this.$emit('submit');
			},
			showShopModal() {
				this.shopVisible = true;
			},
			dataChange(record) {
				this.data.list = record.map(item=>{
					item = Object.assign(item, this.getTime(item));
					return item;
				});
			},
			deleteProduct(record) {
				this.data.list = this.data.list.filter(item => {
					return item.id != record.id;
				})
			},
			getTime(record) {
				var timeStr = 0;
				if (record.State === 0) {
					timeStr = record.StartTime;
				} else if (record.State === 1) {
					timeStr = record.EndTime;
				}
				if (timeStr || record.Timer) {
					var mss = 0;
					if (timeStr){
						var split = timeStr.split(' ');
						var year = +split[0].split('-')[0];
						var month = (+split[0].split('-')[1]) - 1;
						var day = +split[0].split('-')[2];
						var hour = +split[1].split(':')[0];
						var minute = +split[1].split(':')[1]
						var second = +split[1].split(':')[2]
						mss = new Date(year, month, day, hour, minute, second).getTime() - new Date().getTime();
					}
					
					if (record.Timer){
						mss = record.Timer * 1000;
					}
					
					var days = parseInt(mss / (1000 * 60 * 60 * 24));
					var hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
					var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
					var seconds = parseInt((mss % (1000 * 60)) / 1000);

					var obj = {
						days: days,
						hours: hours < 10 ? ('0' + hours) : hours,
						minutes: minutes < 10 ? ('0' + minutes) : minutes,
						seconds: seconds < 10 ? ('0' + seconds) : seconds
					}
					
					return obj;
				}else{
					return {}
				}

			}
		},
		mounted() {
			// if (this.data.activityData.Id) {
			// 	this.getTime();
			// }
			this.dataChange(this.data.list);
		},
		watch: {
			data(value) {
				//this.$set(this.data,'btnType',1)
				//this.data.btnType = 1;
				//console.log(value)
			}
		}
	};
</script>
