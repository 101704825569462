<style scoped lang="less">
    .search{
        flex: 0 0 auto;
        margin-bottom: 10px;
    }
    .tab-box{
        flex: 1 1 auto;
        margin-bottom: 10px;
    }
    .pagination{
        flex: 0 0 auto;
    }
	.filter-item{
		display: inline-block;
	}
</style>
<template>
    <el-dialog
        :visible="show"
        :width="showTableType === 1 ? '1110px' : '990px'"
        title="选择优惠券"
		:append-to-body="true"
        @close="cancel">
 
		<div class="search">
			<span style="padding-right: 10px;">关键字：</span>
		    <el-input v-model="keyword" style="width:200px;margin-right:30px" size="mini" placeholder="优惠券名称"/>
			
			<div class="filter-item" v-if="IsOpenB2B2C">
				<span style="padding-right: 10px;">优惠券归属: </span>
				<el-select v-model="productType" style="width: 160px;margin-right: 10px;" clearable size="mini">
					<el-option v-for="item in productTypeList" :key="item.id" :label="item.type" :value="item.id">
					</el-option>
				</el-select>
			</div>
			
			<div class="filter-item" v-if="productType === 1">
				<span style="padding-right: 10px;">选择店铺: </span>
				<el-select filterable v-model="storeType" style="width: 160px;margin-right: 10px;" clearable size="mini">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in storeList" :key="item.Value" :label="item.Text" :value="item.Value">
					</el-option>
				</el-select>
			</div>
			
		    <el-button type="primary" size="mini" @click="pageIndex=1;getData();">搜索</el-button>
		</div>

        <div class="tab-box">
            <el-table :data="data" v-loading="loading" size="mini" :row-key="getRowKey" ref="tab" @selection-change="handleSelectionChange">
            	<el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
            	<el-table-column prop="CouponName" label="优惠券" width="305px"></el-table-column>
            	<el-table-column label="类型" width="100px">
            		<template slot-scope="scope">
            			<span v-if="scope.row.UseType===0">满减券</span>
            			<span v-if="scope.row.UseType===1">打折券</span>
						<span v-if="scope.row.UseType===2">随机金额券</span>
            		</template>
            	</el-table-column>
            	<el-table-column label="优惠内容" width="150px">
            		<template slot-scope="scope">
						<span v-if="scope.row.FullMoney">满{{scope.row.FullMoney}}元,</span>
						<span v-else>无门槛,</span>
            			<span v-if="scope.row.UseType===0">减{{scope.row.MinMoney}}元</span>
						<span v-if="scope.row.UseType===1">打{{scope.row.Discount/10}}折</span>
						<span v-if="scope.row.UseType===2">减{{scope.row.MinMoney}}~{{scope.row.MaxMoney}}元</span>
            		</template>
            	</el-table-column>
				<el-table-column label="适用商品" width="140px">
					<template slot-scope="scope">
						<span v-if="scope.row.ActivityRangeType===0">全部商品可用</span>
						<span v-else>部分商品可用</span>
					</template>
				</el-table-column>
				<el-table-column label="限领次数" width="100px">
					<template slot-scope="scope">
						<span v-if="scope.row.ReceiveLimit">限领{{scope.row.ReceiveLimit}}次</span>
						<span v-else>不限制</span>
					</template>
				</el-table-column>
            	<el-table-column prop="RemainCount" label="剩余数量" width="100px"></el-table-column>
				<el-table-column prop="StoreName" v-if="showTableType === 1" label="店铺" width="120px"></el-table-column>
            </el-table>
        </div>

        <div class="pagination">
            <el-pagination :current-page="pageIndex" :total="total" :page-size="pageSize" @current-change="pageChange"></el-pagination>
        </div>
        
        <div slot="footer">
            <el-button type="primary" @click="btnSure">确定</el-button>
        </div>

    </el-dialog>
</template>
<script>

import {editSelectCoupon} from '../../api/pageManage.js';
import {
	editSelectinit
} from '@/api/goods';
	
import config from '@/config/index'

export default {
    components: {
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        isSingle: { //是否是单选
            type: Boolean,
            default: false
        },
        selectedList: {
            type: Array,
            default: ()=>{
                return []
            }
        }
    },
    data () {
        return {
            show: false,
            keyword: '',
            pageIndex: 1,
            pageSize: 10,
            total: 0,
            data: [],
            loading: false,
            selectionData: [],
			imgUrl: config.IMG_BASE,
			
			IsOpenB2B2C: false,
			storeList: [],
			storeType: null,
			
			productType: 0,
			productTypeList: [{
					id: 0,
					type: '平台优惠券'
				},
				{
					id: 1,
					type: '店铺优惠券'
				},
			],
			
			showTableType: 0
        };
    },
    methods: {
        cancel(){
            this.$emit('cancel');
        },
		//筛选数据
		async getInfor() {
			try {
				let result = await editSelectinit();
				this.storeList = result.Result.StoreSelectList || [];
		
			} catch (e) {
				console.log(e)
			} finally {
		
			}
		
		},
        async getData(){
            const params = {
                Keywords: this.keyword,
                Skip: (this.pageIndex-1)*this.pageSize,
                Take: this.pageSize
            }
			
			if (this.IsOpenB2B2C){
				params.CouponBelong = this.productType;
				
				if (this.productType == 1){
					params.StoreId = this.storeType
				}
			}

            this.loading = true;

            const res = await editSelectCoupon(params);

            this.data = res.Result.Results
            this.total = res.Result.Total;
            this.loading = false;
			this.showTableType = this.productType;
			
			if (!this.isReady){
				if (this.$refs['tab']){
					this.selectedList.map(item => {
						this.$refs['tab'].toggleRowSelection(item, true);
					})
					this.isReady = true;
				}
			}

        },
		getRowKey(row){
			return row.Id
		},
        pageChange(value){
            this.pageIndex = value;
            this.getData();
        },
		//当前页全选
		handleSelectionChange(val) {
			this.selectionData = val;
		},
        // selectionChange(selection){
        //     this.selectionData = selection;
        // },
        // selectedRow(record, row){
        //     this.singleData = row;
        // },
        btnSure(){
            // if (this.isSingle && this.selectedData.length > this.disableList.length+1){
            //     this.$Message.error('修改产品只能单选！');
            //     return;
            // }

            this.$emit('change', this.selectionData);
            this.cancel();
        }
    },
    watch: {
        visible(value){
            this.show = value;

            if (value){
				if (this.$refs['tab']){
					this.$refs['tab'].clearSelection()
					this.selectedList.map(item => {
						this.$refs['tab'].toggleRowSelection(item, true);
					})
				}
				this.getInfor()
                this.getData();
            }
        }
    },
    mounted () {
         this.IsOpenB2B2C = window.localStorage.getItem('IsOpenB2B2C') == 'false' ? false:true
    },
    created () {

    },
    dispatch () {
        
    }
};
</script>
