<style scoped lang="less">
	.search-box{
        margin-bottom: 20px;
    }
    .table-box{
        margin-bottom: 15px;
    }
	
	.shop-name{
		display: flex;
	}
	.shop-name .img{
		flex: 0 0 auto;
	}
	.shop-name .name{
		flex: 1 1 auto;
		overflow: hidden;
		display: flex;
		align-items: center;
	}
	
	.filter-label{
		display: inline-block;
		width: 72px;
		text-align: right;
	}
	.filter-item{
		margin-bottom: 15px;
	}
</style>
<template>
	<el-dialog :visible="show" :append-to-body="true" width='990px' title="选择商品" @close="cancel">

		<div class="search-box filter-container">
			<div class="filter-item">
				<span class="filter-label" style="padding-right: 10px;">关键字: </span>
				<el-input v-model="keywords" style="width:208px;" size="mini" placeholder="商品名称、编码" />
			</div>
			
			<div class="filter-item" v-if="IsOpenB2B2C">
				<span class="filter-label" style="padding-right: 10px;">商品归属: </span>
				<el-select v-model="productType" style="width: 208px;" clearable size="mini">
					<el-option v-for="item in productTypeList" :key="item.id" :label="item.type" :value="item.id">
					</el-option>
				</el-select>
			</div>
			
			<div class="filter-item" v-if="productType === 1">
				<span class="filter-label" style="padding-right: 10px;">选择店铺: </span>
				<el-select filterable v-model="storeType" style="width: 208px;" clearable size="mini">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in storeList" :key="item.Value" :label="item.Text" :value="item.Value">
					</el-option>
				</el-select>
			</div>
			
			<div class="filter-item" v-if="productType !== 1">
				<span class="filter-label" style="padding-right: 10px;">销售类型: </span>
				<el-select v-model="saleType" style="width: 208px;" clearable size="mini">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in saleSelect" :key="item.id" :label="item.type" :value="item.id">
					</el-option>
				</el-select>
			</div>
			
			<div class="filter-item">
				<span class="filter-label" style="padding-right: 10px;">销售状态: </span>
				<el-select v-model="saleState" style="width: 208px;" clearable size="mini">
					<el-option v-for="item in stateOptions" :key="item.id" :label="item.type" :value="item.id">
					</el-option>
				</el-select>
			</div>

			<div class="filter-item">
				<span class="filter-label" style="padding-right: 10px;">商品分组: </span>
				<el-select v-model="goodsType" size="mini" placeholder="请选择" style="width: 208px;" clearable
				 filterable>
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in productSortList" :key="item.Value" :label="item.Text" :value="item.Value">
					</el-option>
				</el-select>
			</div>
			
			<div class="filter-item" v-if="productType !== 1">
				<span class="filter-label" style="padding-right: 10px;">商品品牌: </span>
				<el-select v-model="brandType" size="mini" placeholder="请选择" style="width: 208px;" clearable filterable>
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in productList" :key="item.Value" :label="item.Text" :value="item.Value">
					</el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<span class="filter-label" style="padding-right: 10px;">价格区间: </span>
				<el-input v-model="priceMin" placeholder="最小价格" style="width: 95px;" clearable size="mini"></el-input>
				<span style="margin-left: 5px;">~</span>
				<el-input v-model="priceMax" placeholder="最大价格" style="width: 95px;margin:0 5px;" clearable size="mini"></el-input>
			</div>
			<div class="filter-item"> 
				<span class="filter-label" style="padding-right: 10px;">销量区间: </span>
				<el-input v-model="saleMin" placeholder="最小销量" style="width: 95px;" clearable size="mini"></el-input>
				<span style="margin-left: 5px;">~</span>
				<el-input v-model="saleMax" placeholder="最大销量" style="width: 95px;margin:0 5px;" clearable size="mini"></el-input>
			</div>

			<div class="filter-item">
				<el-button type="primary" size="mini" @click="search">搜索</el-button>
			</div>
		</div>

		<div class="table-box">
			<el-table :data="data" v-loading="loading" size="mini" :row-key="getRowKey" ref="tab" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
				<el-table-column label="商品" width="415px">
					<template slot-scope="scope">
						<div class="shop-name" style="overflow: hidden;">
							<div class="img" style="width: 66px;height: 66px;">
								<img :src="imgUrl + scope.row.ImgUrl" style="display: block;width: 100%;" />
							</div>
							<div class="name" style="margin-left: 10px;">
								<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
							</div>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column label="店铺" v-if="showTableType === 1" prop="StoreName" width="160px"></el-table-column>
				<el-table-column label="销售类型" v-else prop="ProductTypeDisplay" width="160px"></el-table-column>
				
				<el-table-column label="价格">
					<template slot-scope="scope">
						&yen;{{scope.row.ProductPrice}}
					</template>
				</el-table-column>
				<el-table-column prop="Stock" label="库存"></el-table-column>
				<el-table-column prop="Stock" label="备注">
					<template slot-scope="scope">
						<div v-if="scope.row.SupplierMallId >0">分销商品</div>
						<div v-if="scope.row.ProductState == 3">已下架</div>
						<div v-if="scope.row.ProductState == 2">已售罄</div>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<el-pagination :current-page="pageIndex" :total="total" :page-size="pageSize" @current-change="pageChange"></el-pagination>

		<div slot="footer">
			<el-button type="primary" @click="btnSure">确定</el-button>
		</div>

	</el-dialog>
</template>
<script>
	import {
		editSelectProduct
	} from '../../api/pageManage.js';
	import {
		editSelectinit
	} from '@/api/goods';
	import config from '@/config/index'
	export default {
		components: {

		},
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			isSingle: { //是否是单选
				type: Boolean,
				default: false
			},
			selectedList: {
				type: Array,
				default: () => {
					return []
				}
			}
		},
		data() {
			return {
				goodsType:null,
				productSortList: [],
				brandType:null,
				productList: [],
				saleType:null, //销售类型
				saleSelect: [{
						id: 0,
						type: '单品销售'
					},
					{
						id: 1,
						type: '组合销售'
					},
				],
				saleState: null, 
				stateOptions: [{
						id: null,
						type: '全部'
					},
					{
						id: 1,
						type: '已上架'
					},
					{
						id: 2,
						type: '已售罄'
					},
					{
						id: 3,
						type: '已下架'
					},
				],
				keywords: '',
				priceMin: '',
				priceMax: '',
				saleMin: '',
				saleMax: '',
				pageIndex: 1,
				pageSize: 10,
				total: 0,
				loading: false,
				selectedData: [],
				singleData: {},
				data: [],
				show: false,
				imgUrl: config.IMG_BASE,
				
				productType: 0,
				productTypeList: [{
						id: 0,
						type: '平台商品'
					},
					{
						id: 1,
						type: '店铺商品'
					},
				],
				
				IsOpenB2B2C: false,
				storeList: [],
				storeType: null,
				
				showTableType: 0
			};
		},
		methods: {
			//筛选数据
			async getInfor() {
				try {
					let result = await editSelectinit()
					// 品牌
					this.productList = result.Result.ProductBrandSelectList;
					this.productSortList = result.Result.ProductGroupSelectList;
					this.storeList = result.Result.StoreSelectList || [];
					console.log(this.storeList)

				} catch (e) {
					console.log(e)
				} finally {

				}

			},
			async getData() {
				if (this.priceMin.trim() !== ''){
					if (isNaN(this.priceMin) || +this.priceMin < 0){
						this.$message.error('请输入正确的价格区间');
						return;
					}
				}
				if (this.priceMax.trim() !== ''){
					if (isNaN(this.priceMax) || +this.priceMax < 0){
						this.$message.error('请输入正确的价格区间');
						return;
					}
				}
				if(this.priceMin.trim() !== '' && this.priceMax.trim() !== '' && +this.priceMax < +this.priceMin){
					this.$message.error('请输入正确的价格区间');
					return;
				}
				if (this.saleMin.trim() !== ''){
					if (isNaN(this.saleMin) || +this.saleMin < 0){
						this.$message.error('请输入正确的销量区间');
						return;
					}
				}
				if (this.saleMax.trim() !== ''){
					if (isNaN(this.saleMax) || +this.saleMax < 0){
						this.$message.error('请输入正确的销量区间');
						return;
					}
				}
				if(this.saleMin.trim() !== '' && this.saleMax.trim() !== '' && +this.saleMax < +this.saleMin){
					this.$message.error('请输入正确的销量区间');
					return;
				}
				 
				let params = {
					Keywords: this.keywords,
					ProductType:this.saleType,
					ProductState: this.saleState,
					ProductGroupId:this.goodsType,
					ProductBrandId:this.brandType,
					Skip: (this.pageIndex - 1) * this.pageSize,
					Take: this.pageSize,
					PriceMin: this.priceMin,
					PriceMax: this.priceMax,
					SellMin: this.saleMin,
					SellMax: this.saleMax,
				}
				
				if (this.IsOpenB2B2C){
					params.ProductBelong = this.productType;
					
					if (this.productType == 1){
						params.StoreId = this.storeType
					}
				}

				this.loading = true;

				const result = await editSelectProduct(params);

				this.data = result.Result.Results;
				this.total = result.Result.Total;
				this.loading = false;
				this.showTableType = this.productType;

				if (!this.isReady) {
					if (this.$refs['tab']) {
						this.selectedList.map(item => {
							this.$refs['tab'].toggleRowSelection(item, true);
						})
						this.isReady = true;
					}
				}

			},
			getRowKey(row) {
				return row.Id
			},
			cancel() {
				this.$emit('cancel');
			},
			pageChange(value) {
				this.pageIndex = value;
				this.getData();
			},
			search() {
				this.pageIndex = 1;
				this.getData();
			},
			//当前页全选
			handleSelectionChange(val) {
				this.selectedData = val;
			},
			selectedRow(record, row) {
				this.singleData = row;
			},
			btnSure() {
				// if (this.isSingle && this.selectedData.length > this.disableList.length+1){
				//     this.$Message.error('修改产品只能单选！');
				//     return;
				// }

				this.$emit('change', this.selectedData);
				this.cancel();
			}
		},
		watch: {
			visible(value) {
				this.show = value;

				if (value) {
					if (this.$refs['tab']) {
						this.$refs['tab'].clearSelection()
						this.selectedList.map(item => {
							this.$refs['tab'].toggleRowSelection(item, true);
						})
					}
					// this.saleType = null
					// this.goodsType = null
					// this.brandType =null
					this.getInfor()
					this.getData();

				}
			}
		},
		mounted() {
			// this.selectedList.map(item => {
			// 	this.$refs['tab'].toggleRowSelection(item, true);
			// })
			//this.getData();
			this.IsOpenB2B2C = window.localStorage.getItem('IsOpenB2B2C') == 'false' ? false:true
		},
		created() {

		},
		dispatch() {

		}
	};
</script>
