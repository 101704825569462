<style scoped lang="less">

    .singleRowProduct-module{
        
        .coupon-list{
			overflow: hidden;
			.item{
				margin-top: 10px;
				
				&:first-child {
					margin-top: 0;
				}
			}
			.item1{
				float: left;
				width: 173px;
				margin-left: 10px;
				margin-bottom: 5px;
			}
        }
        
    }
	
	.tabs{
		height: 42px;
		border-bottom: 1px solid #eee;
		display: flex;
		align-items: center;
		padding-left: 10px;
		background: #fff;
		overflow: auto;
		
		.tab{
			height: 24px;
			line-height: 24px;
			padding: 0 15px;
			border-radius: 12px;
			font-size: 14px;
			color: #666;
			background: #fff;
			white-space: nowrap;
			margin-right: 15px;
		}
		
		.current{
			background: #ff547b;
			color: #fff;
		}
		
		
	}
	
	
    .haveSpace{
        margin-bottom: 10px;
    }
    
</style>

<template>
    <div class="singleRowProduct-module" @mouseenter="data.visible=true" @mouseleave="data.visible=false" style="position: relative;padding-left: 40px;padding-right: 45px;" :class="{haveSpace: !data.hideSpace}">
        <module-name :name="name"></module-name>
        <controler @edit="edit();data.editVisible=true" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        <!-- <edit :data="data" @submit="$emit('submit')" :visible="data.editVisible" :name="name" @close="data.editVisible=false"  /> -->
        
		
		<div class="tabs" v-if="data.type==2 && data.groupList.length">
			<div class="tab" :class="{current: index === 0}" v-for="(item, index) in data.groupList" :key="item.Id">
				{{item.GroupName}}
			</div>
		</div>
		
        <div class="coupon-list" style="background-color: #fff;padding-top: 10px;padding-bottom: 10px;" v-if="data.style==2">
			<div v-if="liveList.length">
				<div class="item1" v-for="item in liveList" :key="item.Id">
					<item1 :item="item"></item1>
				</div>
			</div>
			<div class="item1" v-else v-for="i in 2" :key="i">
				<item1 :item="normalData"></item1>
			</div>
        </div>
		<div class="coupon-list" v-else>
			<div v-if="liveList.length">
				<div class="item" v-for="item in liveList" :key="item.Id">
					<item :item="item"></item>
				</div>
			</div>
			<div class="item" v-else>
				<item :item="normalData"></item>
			</div>
			
		</div>
		
		<div class="cover" v-if="data.visible" style="position: absolute;left:40px;top:0;right: 45px;height: 100%;border: 3px solid #0000FF;"></div>
		
    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';

import item from './item.vue';
import item1 from './item1.vue';

export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        edit,
		item,
		item1
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
	computed:{
		liveList(){
			if(this.data.type == 2){
				if (this.data.groupList.length){
					var obj = this.data.groupList[0];
					if(obj.Id === 0){
						return []
					}else{
						var list = (obj.LiveRoomList || []).map(item=>{
							// item.ProductCoverImgUrl = item.ProductCoverImgUrl;
							// item.ProductPrice = item.ProductPrice || item.Price;
							item.Id = this.guid();
							return item;
						})
						return list;
					}
				}else{
					return []
				}
			}else{
				return this.data.list;
			}
		}
	},
    data () {
        return {
			normalData:{
				AnchorImgUrl: 'http://cdn.dkycn.cn/images/dyj/no-pic.png',
				RoomName: '此处显示直播间标题',
				State: 1,
				AnchorNickName: '主播昵称'
			}
        };
    },
    methods: {
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        
        close(){
            this.$emit('visibleChange', false)
        },
		guid() {
			var a = function() {
				return (65536 * (1 + Math.random()) | 0).toString(16).substring(1)
			};
			return 'a' + a() + a() + "-" + a() + "-" + a() + "-" + a() + "-" + a() + a() + a()
		}

    },
    mounted () {
		// console.log(this.data)
    },
    watch: {
        data(value){
            // console.log(value)
        }
    }
};
</script>

