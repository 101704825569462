<style scoped lang="less">
    .singleRowProduct-module{
        
       

        .product-list{

            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;

            .product-item{

                width: 170px;
                background: #fff;
                padding-bottom: 20px;
                margin-bottom: 10px;

                img{
                    width: 170px;
                    height: 170px;
                    border: 1px solid #ddd;
                }

                .name{
                    margin: 5px 10px 10px;
                    font-size: 14px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                
                .pro-info{
                    display: flex;
                    margin: 0 10px;

                    .left{
                        flex: 1 1 auto;
                        color: #ff547b;
                        font-weight: bold;
                        font-size: 16px;
                    }
                }
            }
            
        }
        
    }
    .haveSpace{
        margin-bottom: 10px;
    }
	
	
</style>

<template>
    <div class="singleRowProduct-module" @mouseenter="data.visible=true" @mouseleave="data.visible=false" style="position: relative;padding-left: 40px;padding-right: 45px;" :class="{haveSpace: !data.hideSpace}">
        <module-name :name="name"></module-name>
        <controler @edit="edit();data.editVisible=true" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        
        <div style=" background:#fff;padding-top: 10px;">
            <!-- <img style="display: block; margin: 0 auto 10px;" :src="$store.state.pageManageConfig.staticImage.guessyourlike" /> -->

            <div class="product-list">
                <div class="product-item" :key="i" v-for="i in 2">
                    <img :src="normalData.url" />
                    <p class="name">{{normalData.name}}</p>
                    <div class="pro-info">
                        <div class="left">
                            ￥{{normalData.price}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		
		<div class="cover" v-if="data.visible" style="position: absolute;left:40px;top:0;right: 45px;height: 100%;border: 3px solid #0000FF;"></div>
		
    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';



export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        edit,
        
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
    data () {
        return {
            
            btnType: 1, //按钮三种样式
            normalData: {
                url: this.$store.state.pageManageConfig.staticImage.noPic,
                name: '此处是商品名称',
                price: 99.00,
                marketPrice: 100.00,
            }
        };
    },
    methods: {
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        
        close(){
            this.$emit('visibleChange', false)
        }

    },
    mounted () {
    },
    watch: {
        
    }
};
</script>

