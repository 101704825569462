<style scoped lang="less">
    .singleRow-module-edit{
        position: absolute;
        right: -530px;
        width: 480px;
        padding: 20px 20px 30px;
        background: #fff;
        font-size: 14px;

        .title{
            position: relative;
            font-size: 18px;
            padding-bottom: 20px;
            margin-bottom: 25px;
            border-bottom: 1px solid #ddd;
        }

        .icon-close{
            position: absolute;
            right: 0;
            cursor: pointer;
        }

        .tip{
            margin-bottom: 20px;
        }

        .product-list{

            margin-bottom: 20px;

            .product-item{
                display: flex;
                margin-bottom: 10px;
            }
            .box{
                display: flex;
                flex: 1 1 auto;
                padding: 10px;
                background: #f5f5f5;

                .img{
                    position: relative;
                    width: 140px;
                    height: 70px;
                    overflow: hidden;
                    flex: 0 0 auto;
                    background: #fff;

                    img{
                        display: block;
                        height: 100%;
                    }
                }
                .info{
                    flex: 1 1 auto;
                    margin-left: 10px;
                }

            }
            .control{
                flex: 0 0 auto;
                width: 45px;
                text-align: right;
                margin-top: 20px;

                span{
                    display: inline-block;
                    cursor: pointer;
                }
                .edit{
                    color: #1890ff
                }
                .delete{
                    color: #f00000;
                    margin-top: 10px;
                }
            }
        }

        .tip1{
            text-align: center;
            color: #999;

            .count{
                color: #1890ff;
            }
        }

        .add-btn{
            width: 440px;
            text-align: center;
            border: 1px dashed #ddd;
            font-size: 16px;
            color: #666;
            height: 48px;
            line-height: 48px;
            text-align: center;
            margin-top: 10px;
            cursor: pointer;
        }

        .tip2{
            margin-top: 20px;
            margin-bottom: 10px;
            color: #999;
        }

        .mod-footer{
            margin-top: 30px;
            padding-top: 20px;
            border-top: 1px solid #ddd;

            display: flex;

            .left{
                flex: 1 1 auto;
                padding-top: 6px;
            }
        }

        .choose-box{
            display: flex;
            width: 225px;
        }

        .route{
            line-height: 24px;
            flex: 1 1 auto;
            font-size: 12px;
            color: #666;
            font-family: simsun;
            margin-left: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    
</style>

<template>
    <transition name="scale">
        <div class="singleRow-module-edit" v-if="visible">
            
            <p class="title">
                {{name}} 
                <Icon  @click="close" type="ios-close-empty" size=32 class="icon-close" color="#999"></Icon>
            </p>

            <p class="tip">选择样式</p>
            <RadioGroup v-model="data.style" style="margin-bottom: 40px">
                <Radio :label="1" style="margin-right:50px">
                    <span style="display: inline-block;vertical-align: -22px;font-size:14px">
                        样式一（两列）<br/>
                        <span style="font-size:12px;color:#999">*至少添加两张图片</span>
                    </span>
                </Radio>
                <Radio :label="2">
                    <span style="display: inline-block;vertical-align: -22px;font-size:14px">
                        样式二（左一右二）<br/>
                        <span style="font-size:12px;color:#999">*只展示三张图片</span>
                     </span>
                </Radio>
            </RadioGroup>

            <p class="tip">添加图片</p>

            <draggable class="product-list" :list="data.list" :options="{animation: 300, handle:'.product-item'}">
                <transition-group name="bounce-out-left">
                    <div class="product-item" :key="item.uid" v-for="item in data.list">
                        <div class="box">
                            <div class="img">
                                <img :src="$store.state.pageManageConfig.imghost+item.imgUrl" />
                            </div>
                            <div class="info">
                                <RadioGroup v-model="item.type">
                                    <Radio :label="1" style="margin-right:35px;"><span style="font-size:14px;color: #666;">系统链接</span></Radio>
                                    <Radio :label="2"><span style="font-size:14px;color: #666;">自定义链接</span></Radio>
                                </RadioGroup>

                                <div class="choose-box" style="margin-top:15px;" v-if="item.type === 1">
                                    <Button type="ghost" style="background:#fff" size="small" @click="showModal(item)">选择</Button>
                                    <span class="route" :title="item.selectedData ? item.selectedData.text : ''">{{item.selectedData ? item.selectedData.text : ''}}</span>
                                </div>
                                <div class="custom-link" style="margin: 10px 10px 0 0" v-if="item.type === 2">
                                    <Input v-model="item.link" placeholder="请输入链接地址"/>
                                </div>

                            </div>
                        </div>
                        <div class="control">
                            <Upload
                                ref="upload"
                                :show-upload-list="false"
                                :format="['jpg','jpeg','png']"
                                :max-size="2048"
                                :on-success="data=>handleSuccess(data, item)"
                                :on-format-error="handleFormatError"
                                :on-exceeded-size="handleMaxSize"
                                :action="$store.state.pageManageConfig.uploadUrl"
                                >
                                <span class="edit">修改</span>
                            </Upload>
                            <span class="delete" @click="deleteData(item)">删除</span>
                        </div>
                    </div>
                </transition-group>
            </draggable>

            
            <p class="tip1">您已添加<span class="count">{{data.list.length}}</span>张图片</p>
            

            <Upload
                ref="upload"
                :show-upload-list="false"
                :format="['jpg','jpeg','png']"
                :max-size="2048"
                :on-success="data=>handleSuccess(data, null)"
                :on-format-error="handleFormatError"
                :on-exceeded-size="handleMaxSize"
                :action="$store.state.pageManageConfig.uploadUrl"
                >
                <div class="add-btn">
                    <Icon type="ios-plus-outline" style="margin-right: 5px;vertical-align: -2px;" size=18></Icon>添加图片
                </div>
            </Upload>

            <p class="tip2">建议图片宽度750，高200~950，支持类型：jpg、png。</p>

            <div class="mod-footer">
                <div class="left">
                    <Checkbox v-model="data.hideSpace"><span style="font-size: 14px;color:#666">隐藏模块下方的间距</span></Checkbox>
                </div>
                <div class="right">
                    <Button type="primary" @click="saveData">保存</Button>
                </div>
            </div>

            <link-modal :showLiveTab="true" :visible="shopVisible" @change="dataChange" @cancel="shopVisible=false"></link-modal>

        </div>
    </transition>
</template>

<script>

import linkModal from '@/views/components/linkModal';
import draggable from 'vuedraggable';

export default {
    name: 'normal-module-edit',
    components: {
        linkModal,
        draggable
    },
    props: {
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        visible: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            shopVisible: false,
            editData: {}
        };
    },
    methods: {
        close(){
            this.$emit('close')
        },
        showModal(record){
            this.editData = record;
            this.shopVisible = true;
        },
        dataChange(record){

            this.data.list.map(item=>{
                if (item.uid == this.editData.uid){
                    item.selectedData = record;
                }
                return item;
            })
            console.log(this.data)
            
        },

        //添加产品，并且过滤掉相同产品
        addData(record){
            const list = this.data.list || [];
            record.map(item =>{
                let like = false;
                list.map(item1=>{
                    if (item1.Id === item.Id){
                        like = true;
                    }
                })

                if (!like){
                    list.push(item);
                }

            })

            this.data.list.concat(list);
        },

        //修改产品， 覆盖掉需替换的产品
        changeRecordData(record){

            this.data.list.map(item=>{
                if (item.Id === this.editData.Id){
                    for (var i in record){
                        item[i] = record[i]
                    }
                }
                return item;
            })

        },
        deleteData(record){

            this.data.list = this.data.list.filter(item=>{
                return item.uid !== record.uid;
            })

        },
        changeData(record){

            this.shopVisible = true;
            this.isSingle = true;

            this.editData = record;

        },
        handleMaxSize(){
            this.$Message.error('上传图片最大2M！');
        },
        handleFormatError(){
            this.$Message.error('上传失败！');
        },
        handleSuccess(data, record){

            if (record){
                this.data.list.map(item=>{
                    if (item.uid == record.uid){
                        item.imgUrl = data[0]
                    }
                    return item;
                })
            }else{
                this.data.list.push({
                    uid: this.guid(),
                    imgUrl: data[0],
                    type: 1, //链接类型
                    link: '', //自定义链接
                    selectedData: {}, //弹框选中数据
                })
            }

        },
        saveData(){
            this.$emit('submit')
        },
        guid(){
            var a=function(){return(65536*(1+Math.random())|0).toString(16).substring(1)};
            return a()+a()+"-"+a()+"-"+a()+"-"+a()+"-"+a()+a()+a()
        }

    },
    mounted () {
        // this.data.list.push({
        //         uid: this.guid(),
        //         imgUrl: '/ShopDecorate/61f677cf-ce0d-4364-b3d8-9cdeffe089aa.jpg',
        //         type: 1, //链接类型
        //         link: '', //自定义链接
        //         selectedData: {}, //弹框选中数据
        //     })
    },
    watch: {
        data(value){
            //this.$set(this.data,'btnType',1)
            
            //this.data.btnType = 1;
            //console.log(value)
        }
    }
};
</script>

