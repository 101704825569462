<style scoped lang="less">
    .singleRowProduct-module{
        
        .product-list{

            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;

            .product-item{

                width: 115px;
                background: #fff;
                padding-bottom: 10px;
                margin-bottom: 10px;

                &:nth-last-child(-n+3){
                    margin-bottom: 0;
                }

                img{
                    width: 115px;
                    height: 115px;
                    border: 1px solid #ddd;
                }

                .name{
                    margin: 5px 5px 10px;
                    font-size: 14px;
                    
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                
                .pro-info{
                    display: flex;
                    margin: 0 5px;

                    .left{
                        flex: 1 1 auto;
                        color: #1890ff;
                        font-weight: bold;
                        font-size: 16px;
                        margin-top: -3px;

                        .old{
                            font-size: 16px;
                            color: #ccc;
                            font-weight: 400;
                            text-decoration: line-through;
                        }
                    }
                    .textCenter{
                        text-align: center;
                    }

                    .btn-type-1{
                        flex: 0 0 auto;
                        width: 15px;
                        height: 15px;

                        img{
                            display: block;
                            width: 100%;
                            height: 100%;
                            border: none;
                        }
                    }

                    .btn-type-2{
                        flex: 0 0 auto;
                        width: 60px;
                        height: 24px;
                        line-height: 24px;
                        font-size: 12px;
                        color: #1890ff;
                        border: 1px solid #1890ff;
                        text-align: center;
                    }

                    .btn-type-3{
                        flex: 0 0 auto;
                        width: 24px;
                        height: 24px;
                        padding-top: 5px;
                        color: #fff;
                        background: #1890ff;
                        border-radius: 100%;
                        text-align: center;
                    }
                }
            }
            
        }
        
    }
    .haveSpace{
        margin-bottom: 20px;
    }
</style>

<template>
    <div class="singleRowProduct-module"  :class="{haveSpace: !data.hideSpace}">
        <module-name :name="name"></module-name>
        <controler @edit="edit();data.editVisible=!data.editVisible" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        <edit :data="data" @submit="$emit('submit')" :visible="data.editVisible" :name="name" @close="data.editVisible=false"  />
        
        <div @click="edit();data.visible=true">
            <div class="product-list" v-if="!data.list.length">
                <div class="product-item" :key="i" v-for="i in 3">
                    <img :src="normalData.url" />
                    <p class="name">{{normalData.name}}</p>
                    <div class="pro-info">
                        <div class="left" :class="{textCenter: data.btnType===2}">
                            ￥{{normalData.price}}</span>
                        </div>
                        <div class="btn-type-1" v-if="data.btnType===1">
                            <img :src="$store.state.pageManageConfig.staticImage.iconPlus" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="product-list" v-else>
                <div class="product-item" :key="index" v-for="(item,index) in data.list">
                    <img :src="$store.state.pageManageConfig.imghost+item.ImgUrl" />
                    <p class="name">{{item.Name}}</p>
                    <div class="pro-info">
                        <div class="left" :class="{textCenter: data.btnType===2}">
                            ￥{{item.Price}}</span>
                        </div>
                        <div class="btn-type-1" v-if="data.btnType===1">
                            <img :src="$store.state.pageManageConfig.staticImage.iconPlus" />
                        </div>
                    </div>
                </div>
                <div class="product-item" v-if="data.list.length%3===1||data.list.length%3===2" style="background: transparent">
                </div>
                <div class="product-item" v-if="data.list.length%3===1" style="background: transparent">
                </div>
            </div>
        </div>


    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';



export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        edit,
        
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
    data () {
        return {
            
            btnType: 1, //按钮三种样式
            normalData: {
                url: this.$store.state.pageManageConfig.staticImage.noPic,
                name: '此处是商品名称-此处是商品名称',
                price: 99.00,
                marketPrice: 100.00,
            }
        };
    },
    methods: {
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        
        close(){
            this.$emit('visibleChange', false)
        }

    },
    mounted () {
    },
    watch: {
        
    }
};
</script>

