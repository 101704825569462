<style scoped lang="less">
    .singleRow-module-edit{
        // position: absolute;
        // right: -530px;
        width: 480px;
        padding: 20px 20px 30px;
        background: #fff;
        font-size: 14px;

        .title{
            position: relative;
            font-size: 18px;
            padding-bottom: 20px;
            margin-bottom: 25px;
            border-bottom: 1px solid #ddd;
        }

        .icon-close{
            position: absolute;
            right: 0;
            cursor: pointer;
        }

        .tip{
            margin-bottom: 20px;
        }

        .mod-footer{
            margin-top: 30px;
            padding-top: 20px;
            border-top: 1px solid #ddd;

            display: flex;

            .left{
                flex: 1 1 auto;
                padding-top: 6px;
            }
        }

    }

    
</style>

<template>
    <!-- <transition name="scale"> -->
        <div class="singleRow-module-edit" v-if="visible">
            
            <p class="title">
                {{name}}
                <!-- <i @click="close" class="el-icon-close icon-close" style="font-size: 32px;color: #999;top:-12px"></i> -->
            </p>

            <p class="tip">搜索热词</p>
            <el-input placeholder="请输入商品名称" size="large" v-model="data.text" :maxlength="20" style="width:400px;"/>
			
			<el-form label-width="90px" size="mini" style="margin-top: 20px;">
				<el-form-item label="扫码购入口">
					<el-radio-group v-model="data.showScan">
						<el-radio style="width: 80px;" :label="true">显示</el-radio>
						<el-radio style="width: 80px;" :label="false">隐藏</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			
			<div class="mod-footer">
			    <div class="left">
			        <el-checkbox v-model="data.hideSpace"><span style="font-size: 14px;color:#666">隐藏模块下方的间距</span></el-checkbox>
			    </div>
			    <!-- <div class="right">
			        <Button type="primary" @click="saveData">保存</Button>
			    </div> -->
			</div>
        </div>
    <!-- </transition> -->
</template>

<script>


export default {
    name: 'normal-module-edit',
    components: {
    },
    props: {
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        visible: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
        };
    },
    methods: {
        close(){
            this.$emit('close')
        },
        saveData(){
            this.$emit('submit')
        },
		// scanChange(record){
		// 		this.data.column = '3';
		// },
    },
    mounted () {
        // this.data.list.push({
        //         uid: this.guid(),
        //         imgUrl: '/ShopDecorate/61f677cf-ce0d-4364-b3d8-9cdeffe089aa.jpg',
        //         type: 1, //链接类型
        //         link: '', //自定义链接
        //         selectedData: {}, //弹框选中数据
        //     })
    },
    watch: {
        data(value){
            //this.$set(this.data,'btnType',1)
            
            //this.data.btnType = 1;
            //console.log(value)
        }
    }
};
</script>

