<style scoped lang="less">
    .search{
        flex: 0 0 auto;
        margin-bottom: 10px;
    }
    .tab-box{
        flex: 1 1 auto;
        margin-bottom: 10px;
    }
    .pagination{
        flex: 0 0 auto;
    }
</style>
<template>
    <Modal
        v-model="show"
        width=750,
        title="选择活动"
        @on-cancel="cancel">
        
        <div class="search">
            <Input v-model="keyword" style="width:250px" placeholder="活动名称"/>
            <Button type="primary" @click="pageIndex=1;getData();">搜索</Button>
        </div>

        <div class="tab-box">
            <Table border :loading="loading" @on-selection-change="selectionChange" @on-select="selectedRow" size="small" height="338" :columns="columns" :data="data"></Table>
        </div>

        <div class="pagination">
            <Page :total="total" :page-size="pageSize" @on-change="pageChange"></Page>
        </div>
        
        <div slot="footer">
            <Button type="primary" @click="btnSure">确定</Button>
        </div>

    </Modal>
</template>
<script>


export default {
    components: {
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        isSingle: { //是否是单选
            type: Boolean,
            default: false
        },
        disableList: {
            type: Array,
            default: ()=>{
                return []
            }
        }
    },
    data () {
        return {
            show: false,
            keyword: '',
            pageIndex: 1,
            pageSize: 5,
            total: 0,
            data: [],
            loading: false,
            selectionData: [],
            columns: [
                {
                    type: 'selection',
                    width: 60,
                    align: 'center'
                },
                {
                    title: '商品主图',
                    key: 'ImgUrl',
                    width: 100,
                    render: (h, params)=>{
                        return h('div', {
                            style: {
                                margin: '5px 0',
                                width: '50px',
                                height: '50px',
                                overflow: 'hidden',
                                textAlign: 'center'
                            }
                        }, [
                            h('img', {
                                attrs: {
                                    src: this.$store.state.user.imghost+params.row.ImgUrl
                                },
                                style: {
                                    width: '100%'
                                }
                            })
                        ])
                    }
                },
                {
                    title: '商品名称',
                    key: 'Name',
                },
                {
                    title: '活动时间',
                    key: 'time',
                    render: (h, params)=>{
                        return h('div', params.row.ActivityBeginTime +'至'+ params.row.ActivityEndTime);
                    }
                },
                {
                    title: '活动状态',
                    key: 'ActivityState',
                    width: 100
                },
            ]
        };
    },
    methods: {
        cancel(){
            this.$emit('cancel');
        },
        async getData(){
            const params = {
                Keywords: this.keyword,
                Skip: (this.pageIndex-1)*this.pageSize,
                Take: this.pageSize
            }

            this.loading = true;

            const result = await this.$ajax('GroupBuyingInDecorateNew', params);

            this.data = (result.result || []).map(item=>{
                this.disableList.map(record=>{
                    if (item.Id === record.Id){
                        item._disabled = true;
                        item._checked = true;
                    }
                })

                return item;
            });
            this.total = result.total;
            this.loading = false;

        },
        pageChange(value){
            this.pageIndex = value;
            this.getData();
        },
        selectionChange(selection){
            this.selectionData = selection;
        },
        selectedRow(record, row){
            this.singleData = row;
        },
        btnSure(){
            if (this.isSingle && this.selectedData.length > this.disableList.length+1){
                this.$Message.error('修改产品只能单选！');
                return;
            }

            this.$emit('change', this.isSingle ? this.singleData : this.selectionData);
            this.cancel();
        }
    },
    watch: {
        visible(value){
            this.show = value;

            if (value){
                this.getData();
            }
        }
    },
    mounted () {
        
    },
    created () {

    },
    dispatch () {
        
    }
};
</script>
