<style scoped lang="less">
    .singleRow-module-edit{
        position: absolute;
        right: -530px;
        width: 480px;
        padding: 20px 20px 30px;
        background: #fff;
        font-size: 14px;

        .title{
            position: relative;
            font-size: 18px;
            padding-bottom: 20px;
            margin-bottom: 25px;
            border-bottom: 1px solid #ddd;
        }

        .icon-close{
            position: absolute;
            right: 0;
            cursor: pointer;
        }

        .tip{
            margin-bottom: 20px;
        }

        .mod-footer{
            margin-top: 30px;
            padding-top: 20px;
            border-top: 1px solid #ddd;

            display: flex;

            .left{
                flex: 1 1 auto;
                padding-top: 6px;
            }
        }

    }

    
</style>

<template>
    <transition name="scale">
        <div class="singleRow-module-edit" v-if="visible">
            
            <p class="title">
                {{name}} 
                <Icon  @click="close" type="ios-close-empty" size=32 class="icon-close" color="#999"></Icon>
            </p>

            <p class="tip">标题</p>
            <Input size="large" placeholder="请输入标题" v-model="data.title" :maxlength="20" style="width:400px;" />

            <RadioGroup v-model="data.style" style="margin-bottom: 40px;margin-top:20px">
                <Radio :label="1" style="margin-right:30px">
                    <span style="font-size:14px">
                        居左
                    </span>
                </Radio>
                <Radio :label="2">
                    <span style="font-size:14px">
                        居中
                    </span>
                </Radio>
            </RadioGroup>

            <p class="tip">链接</p>
            <Input size="large" placeholder="请输入链接" v-model="data.url" style="width:400px;" />


            <div class="mod-footer">
                <div class="left">
                    <Checkbox v-model="data.hideSpace"><span style="font-size: 14px;color:#666">隐藏模块下方的间距</span></Checkbox>
                </div>
                <div class="right">
                    <Button type="primary" @click="saveData">保存</Button>
                </div>
            </div>

        </div>
    </transition>
</template>

<script>


export default {
    name: 'normal-module-edit',
    components: {
    },
    props: {
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        visible: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
        };
    },
    methods: {
        close(){
            this.$emit('close')
        },
        saveData(){
            this.$emit('submit')
        },

    },
    mounted () {
        // this.data.list.push({
        //         uid: this.guid(),
        //         imgUrl: '/ShopDecorate/61f677cf-ce0d-4364-b3d8-9cdeffe089aa.jpg',
        //         type: 1, //链接类型
        //         link: '', //自定义链接
        //         selectedData: {}, //弹框选中数据
        //     })
    },
    watch: {
        data(value){
            //this.$set(this.data,'btnType',1)
            
            //this.data.btnType = 1;
            //console.log(value)
        }
    }
};
</script>

