<style scoped lang="less">
	
	.wraper{
		
		// padding: 5px;
		// background-color: #ff557c;
	}
	.product {
		.items{
			margin: 10px 10px 0 10px;
			background-color: #fff;
			padding: 10px;
			border-radius: 5px;
		}
		.item{
			display: flex;
			
			&:first-child{
				margin-top: 0;
			}
			
			.img{
				position: relative;
				flex: 0 0 auto;
				width: 140px;
				height: 140px;
				overflow: hidden;
				
				.shouqing {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background: rgba(0, 0, 0, .4);
					display: flex;
					justify-content: center;
					align-items: center;
				
					img {
						display: block;
						width: 50%;
						transform: rotate(-30deg);
					}
				}
				>img{
					display: block;
					width: 100%;
				}
				.time{
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 24px;
					line-height: 24px;
					background: #ff547b;
					font-size: 12px;
					color: #fff;
					text-align: center;
				}
			}
			
			.right{
				flex: 1 1 auto;
				overflow: hidden;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				margin-left: 10px;
				
				.top{
					overflow: hidden;
				}
				
				.name{
					font-size: 15px;
					
					overflow: hidden;
					max-height: 42px;
					line-height: 21px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					
					.tag{
						display: inline-block;
						margin-right: 5px;
						vertical-align: 2px;
						padding: 0 10px;
						height: 16px;
						line-height: 16px;
						color: #fff;
						background: #ff547b;
						border-radius: 8px;
						font-size: 12px;
						text-align: center;
					}
					
					.name-box{
						display: inline;
					}
				}
				.maidian{
					font-size: 12px;
					color: #999;
					margin-top: 4px;
					height: 30px;
					line-height: 15px;
					display: -webkit-box; 
					-webkit-box-orient:vertical; 
					-webkit-line-clamp: 2; 
					overflow: hidden;
				}
				
				.sell-count{
					.progress{
						position: relative;
						height: 6px;
						background: #f7f7f7;
						border-radius: 6px;
						
						.current{
							position: absolute;
							left: 0;
							top: 0;
							height: 100%;
							border-radius: 6px;
							background: #ff547b;
						}
					}
					.tit{
						font-size: 12px;
						margin-top: 5px;
					}
				}
				
				.price{
					display: flex;
					margin-top: -14px;
					
					.left{
						font-size: 15px;
						color: #ff547b;
						flex: 1 1 auto;
						margin-right: 10px;
						white-space: nowrap;
						display: flex;
						align-items: center;
						
						.old{
							font-size: 12px;
							color: #999;
							padding-top: 2px;
							margin-left: 5px;
							text-decoration: line-through;
						}
					}
					.btn{
						width: 70px;
						height: 26px;
						line-height: 26px;
						text-align: center;
						font-size: 12px;
						color: #ff547b;
						border: 1px solid #ff547b;
						border-radius: 16px;
						margin-bottom: 4px;
					}
				}
			}
		}
		.bottom{
			margin: 10px 0 5px 0;
			display: flex;
			flex-direction: row;
			background-color: #f5f5f5;
			padding: 0 10px;
			.countdown{
                flex: 1 1 auto;
                align-items: center;
                padding-left: 10px;
                font-size: 12px;
                padding: 5px;
                .black-bg{   
                    display: inline-block;
                    width: 16px;
                    text-align: center;
                    line-height: 16px;
                    font-size: 12px;
                    background-color: #000;
                    color: #fff;
                    margin-right: 5px;
                }
                .white-bg{ 
                    margin-right: 5px;
                }
			}
			.tag{
                font-size: 14px;
				padding-right: 10px;
				flex: 0 0 auto;
                line-height: 25px;
                color: #ff547b;
			}
		}
	}

    .haveSpace {
        margin-bottom: 10px;
    }
</style>
 
<template>
    <div class="singleRowProduct-module" @mouseenter="data.visible=true" @mouseleave="data.visible=false" style="position: relative;padding-left: 40px;padding-right: 45px;"  :class="{haveSpace: !data.hideSpace}">
        <module-name :name="name"></module-name>
        <controler @edit="edit();data.editVisible=true" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        
		<div class="product" v-if="!data.list.length">
			<div class='items'>
				<div class="item" >
						<div class="img">
								<img :src="normalData.url" >
						</div>
						<div class="right">
								<div class="top">
										<div class="name">
												<div class="name-box">{{normalData.name}}</div>
										</div>
										<div class="maidian">{{normalData.point}}</div>
								</div>
								<div class="sell-count">
										<div class="tit">
												已售
												<span style="color:#ff547b">12件</span>
										</div>
								</div>
								<div class="price">
										<div class="left" >
												&yen;99.99
												<div class="old" >&yen;299.00</div>
										</div>
										<div class="btn">
												去拼团
										</div>
								</div>
						</div>
				</div>
				<div class="bottom">
						<div class="countdown">
								<span class="black-bg">2</span>
								<span class="white-bg">天</span>
								<span class="black-bg">16</span>
								<span class="white-bg">:</span>
								<span class="black-bg">47</span>
								<span class="white-bg">:</span>
								<span class="black-bg">06</span>
								<span>结束</span>
						</div>
						<div class="tag" >3人开团</div>
				</div>
			</div>
		</div>

        
		<div class="product" v-else>
			<div class='items' v-for="(item, index) in data.list" :key="item.ActivityFullId" >
				<div class="item" >
					<div class="img">
						<img :src="imgUrl+item.ImgUrl" >
						<div class="shouqing" v-if="item.Stock <= 0">
							<img src="https://cdn.dkycn.cn/images/melyshop/shouqing.png" mode="widthFix"/>
						</div>
					</div>
					<div class="right">
							<div class="top">
									<div class="name">
											<div class="name-box">{{item.ProductName}}</div>
									</div>
									<div class="maidian">{{item.SellPoint}}</div>
							</div>
							<div class="sell-count">
									<div class="tit">
											已售
											<span style="color:#ff547b">{{item.SellCount}}件</span>
									</div>
							</div>
							<div class="price">
								<div class="left" >
										&yen;{{item.GroupPrice}}
										<div class="old" v-if="item.OriginPrice">&yen;{{item.OriginPrice}}</div>
								</div>
								<div class="btn" v-if="item.State === 2" style="background: #999;color:#fff;border:none">已结束</div>
								<div class="btn" v-if="item.State === 1 && item.Stock>0">去拼团</div>
								<div class="btn" v-if="item.State === 0 || (item.State === 1&&item.Stock<=0)" >去看看</div>
							</div>
					</div>
				</div>
				<div class="bottom" >
						<div class="countdown">
								<div style='display:inline-block;color:#333' v-if="item.State === 0">
									<!-- 未开始 -->
									即将开始
									<span >{{item.days}}</span>
									<span >天</span>
									<span >{{item.hours}}</span>
									<span >:</span>
									<span >{{item.minutes}}</span>
									<span >:</span>
									<span >{{item.seconds}}</span>
								</div>
								<div style='display:inline-block;' v-if="item.State === 1" >
									<!-- 进行中 -->
									<span class="black-bg" v-if="item.days > 0">{{item.days}}</span>
									<span class="white-bg"  v-if="item.days > 0">天</span>
									<span class="black-bg">{{item.hours}}</span>
									<span class="white-bg">:</span>
									<span class="black-bg">{{item.minutes}}</span>
									<span class="white-bg">:</span>
									<span class="black-bg">{{item.seconds}}</span>
									<span>结束</span>
								</div>
								
								<div style='display:inline-block;color:#999;' v-if="item.State === 2" >
									<span style="margin-right: 5px">团购结束</span>
									<span>00:00:00</span>
									<!-- 已结束 -->
								</div>
						</div>
						<div class="tag" :style="item.State === 1 && item.Stock>0?'color:#ff547b':'color:#999'">{{item.GroupMemberNum}}人开团</div>
				</div>
			</div>
		</div>

		<div class="cover" v-if="data.visible" style="position: absolute;left:40px;top:0;right: 45px;height: 100%;border: 3px solid #0000FF;"></div>

    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';
import config from '@/config/index';



export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        edit,
        
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
    data () {
        return {
            
            btnType: 1, //按钮三种样式
            normalData: {
                url: this.$store.state.pageManageConfig.staticImage.noPic,
                name: '此处是商品名称',
								point: '此处是卖点',
                price: 99.00,
                marketPrice: 100.00,
						},
						imgUrl: config.IMG_BASE,
        };
    },
    methods: {
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        
        close(){
            this.$emit('visibleChange', false)
        }

    },
    mounted () {
		console.log(this.data, '社群专享品')
    },
    watch: {
        
    }
};
</script>

