<style lang="less">
	.add-friend-module-edit {
		// position: absolute;
		// right: -530px;
		width: 480px;
		padding: 20px 20px 30px;
		background: #fff;
		font-size: 14px;

		.title {
			position: relative;
			font-size: 18px;
			padding-bottom: 20px;
			margin-bottom: 25px;
			border-bottom: 1px solid #ddd;
		}

		.icon-close {
			position: absolute;
			right: 0;
			cursor: pointer;
		}

		.message {
			font-size: 16px;
			color: #999;
			text-align: center;
		}

		.model-tip {
			border: 1px solid #409EFF;
			padding: 6px 10px;
			font-size: 13px;
			color: #666;
			line-height: 20px;

			span {
				font-weight: bold;
			}
		}

		.tit {
			font-size: 16px;
			font-weight: bold;
			padding: 30px 0 10px;
		}

		.avatar-uploader .el-upload {
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
		}

		.avatar-uploader .el-upload:hover {
			border-color: #409EFF;
		}

		.avatar-uploader-icon {
			font-size: 28px;
			color: #8c939d;
			width: 290px;
			height: 145px;
			line-height: 145px;
			text-align: center;
		}

		.avatar {
			width: 290px;
			height: 145px;
			display: block;
		}

		.img-tip {
			font-size: 12px;
			color: #999;
			line-height: 16px;
		}
		
		.normal-img{
			width: 100px;
			height: 100px;
			overflow: hidden;
			background-color: #f5f5f5;
			margin-top: -20px;
			
			img{
				display: block;
				width: 100%;
			}
		}
		
		.custom-img{
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
			width: 100px;
			height: 100px;
			margin-top: -15px;
		}
		.custom-img .el-upload:hover {
			border-color: #409EFF;
		}
		
		.custom-img-icon {
			font-size: 28px;
			color: #8c939d;
			width: 100px;
			height: 100px;
			line-height: 100px;
			text-align: center;
		}
		
		.custom-img-url {
			width: 100px;
			height: 100px;
			display: block;
		}
		
		.custom-img-box{
			display: flex;
		}
		
		.no-auth{
			font-size: 16px;
			color: #666;
			width: 400px;
			margin: 50px auto;
			line-height: 24px;
		}
		
		

		.mod-footer {
			margin-top: 30px;
			padding-top: 20px;
			border-top: 1px solid #ddd;

			display: flex;

			.left {
				flex: 1 1 auto;
				padding-top: 6px;
			}
		}

	}
	
	.select-nodata{
		padding: 30px 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		color: #999;
	}
	.select-other{
		height: 34px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
</style>

<template>
	<!-- <transition name="scale"> -->
	<div class="add-friend-module-edit" v-if="visible">

		<p class="title">
			{{name}}
			<!-- <Icon  @click="close" type="ios-close-empty" size=32 class="icon-close" color="#999"></Icon> -->
		</p>

		<div class="model-tip">
			<span>模块说明：</span>在商城页面内，添加【加好友】模块，引导客户添加员工企业微信，更好的将客户沉淀至企微内并进行精细化营销
		</div>
		
		<div v-if="isAuth">
			<div class="tit">模块样式配置:</div>
			<el-form label-width="110px">
				<el-form-item label="样式配置">
					<el-radio v-model="data.style" :label="1">系统默认样式</el-radio>
					<el-radio v-model="data.style" :label="2">自定义上传入口图片</el-radio>
				</el-form-item>
				
				<!-- 系统默认样式相关配置 -->
				<div v-if="data.style==1">
					<el-form-item label="入口图片">
						<el-radio v-model="data.entranceImageType" :label="1">默认图片</el-radio>
						<el-radio v-model="data.entranceImageType" :label="2">自定义上传</el-radio>
					</el-form-item>
					<el-form-item label="">
						<div class="normal-img" v-if="data.entranceImageType===1"><img :src="imgUrl + data.entranceImageUrlNormal"></div>
						<div v-if="data.entranceImageType===2" class="custom-img-box">
							<el-upload class="custom-img" :action="uploadApi" :show-file-list="false"
								:on-success="handleEntranceSuccess" :before-upload="beforeEntranceUpload">
								<img v-if="data.entranceImageUrl" :src="imgUrl + data.entranceImageUrl" class="custom-img-url">
								<i v-else class="el-icon-plus custom-img-icon"></i>
							</el-upload>
							<el-upload :action="uploadApi" :show-file-list="false"
								:on-success="handleEntranceSuccess" :before-upload="beforeEntranceUpload">
								<el-link type="primary" style="margin-top: 66px;line-height: 18px;height: 18px;margin-left: 10px;">更换图片</el-link>
							</el-upload>
						</div>
						<div v-if="data.entranceImageType===2" class="img-tip" style="margin-top: 7px;">图片建议宽高比1:1；图片大小请控制在1M以内</div>
					</el-form-item>
					<el-form-item label="引导描述">
						<el-input type="text" v-model="data.describe" placeholder="" maxlength="30" style="width: 300px;"></el-input>
						<div class="img-tip" style="margin-top: 4px;">若未输入引导描述，系统默认文案：添加好友领福利啦~</div>
					</el-form-item>
					<el-form-item label="按钮文案">
						<el-input type="text" v-model="data.buttonText" placeholder="" maxlength="4" style="width: 300px;"></el-input>
						<div class="img-tip" style="margin-top: 4px;">若未输入按钮文案，系统默认文案：立即添加</div>
					</el-form-item>
				</div>
				
				
				<!-- 自定义上传入口图片配置 -->
				<el-form-item label="" v-if="data.style==2">
					<el-upload class="avatar-uploader" :action="uploadApi" :show-file-list="false"
						:on-success="handleSuccess" :before-upload="beforeUpload">
						<img v-if="data.imageUrl" :src="imgUrl + data.imageUrl" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<div class="img-tip">图片建议宽高比2:1；图片大小请控制在1M以内</div>
				</el-form-item>
			</el-form>
			
			<div class="tit" style="border-top: 1px solid #ddd;">「员工活码」配置:</div>
			<el-form label-width="110px">
				<el-form-item label="请选择">
					<el-select v-model="data.contactId" style="width: 300px;" filterable  placeholder="请选择员工活码">
						<el-option v-if="list.length" :value="-2">
							<div class="select-other" @click.stop="">
								<span></span>
								<el-link type="primary" @click="toAdd" style="line-height: 16px;">添加[员工活码]</el-link>
							</div>
						</el-option>
						<el-option v-for="item in list" :key="item.Id" :label="item.Remark" :value="item.Id"></el-option>
						<div class="select-nodata" slot="empty">商城还未创建员工活码，<el-link type="primary" @click="toAdd">点击前往创建</el-link></div>
					</el-select>
				</el-form-item>
			</el-form>
			
			<div class="mod-footer">
				<div class="left">
					<el-checkbox v-model="data.hideSpace"><span style="font-size: 14px;color:#666">隐藏模块下方的间距</span>
					</el-checkbox>
				</div>
			</div>
		</div>
		<div v-else class="no-auth">
			商城还未安装企店助手应用，请先前往安装，安装完成后，可添加该模块
			<el-link type="primary" @click="toInstall" style="font-size: 16px;">去安装</el-link>
		</div>

	</div>
	<!-- </transition> -->
</template>

<script>
	import config from '@/config/index';
	import {
		mapGetters
	} from 'vuex'
	import {qyWeixinContactMeListRequest} from '@/api/sv1.0.0.js'
	
	import {
		qyweixinsuiteauthinfo
	} from "@/api/sv1.0.0";

	export default {
		name: 'normal-module-edit',
		components: {},
		props: {
			data: {
				type: Object,
				default: () => {
					return {}
				}
			},
			visible: {
				type: Boolean,
				default: false
			},
			name: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				uploadApi: config.UPLOAD_IMG,
				imgUrl: config.IMG_BASE,
				isAuth: true,
				list: []
			};
		},
		computed: {
			...mapGetters([
				"mallInfo"
			]),
		},
		methods: {
			close() {
				this.$emit('close')
			},
			beforeUpload(file) {
				const isLt1M = file.size / 1024 / 1024 < 1;
				if (!isLt1M) {
					this.$message.error('图片大小请控制在1MB以内');
				}
				return isLt1M;
			},
			handleSuccess(res, file){
				this.data.imageUrl = res[0]
			},
			
			beforeEntranceUpload(file) {
				const isLt1M = file.size / 1024 / 1024 < 1;
				const isJPG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';
				const isGIF = file.type === 'image/gif';
				
				if (!isJPG & !isPNG && !isGIF){
					this.$message.error('请上传正确的图片格式');
					return false
				}
				if (!isLt1M) {
					this.$message.error('图片大小请控制在1MB以内');
					return false
				}
				return true;
			},
			handleEntranceSuccess(res, file){
				this.data.entranceImageUrl = res[0]
			},
			async getData(callback) {
				try {
					const res = await qyweixinsuiteauthinfo();
					this.isAuth = res.Result.IsFinishQyWeixinSuiteStp;
				} catch (e) {
				}
			},
			toInstall(){
				this.$router.push({
					name: 'qyWechat'
				})
			},
			toAdd(){
				var url = location.origin + '/index.html#/qyCustomer/callme';
				window.open(url)
			},
			async getList(){
				try{
					const res = await qyWeixinContactMeListRequest({
						Skip: 0,
						Take: 999999
					})
					this.list = res.Result.Results;
					
					// var list = this.list.filter(item=>{
					// 	return item.Id == this.data.contactId
					// })
					// if (!list.length){
					// 	this.data.contactId = null;
					// 	this.data.data = {};
					// }
					
				}catch(e){
					//TODO handle the exception
				}
			}
		},
		mounted() {
			console.log(this.data)
			this.getList();
			this.getData()
		},
		watch: {}
	};
</script>
