<style scoped lang="less">
    .singleRowProduct-module{
        
        .product-list{
			
			overflow: hidden;
			padding: 10px 8px 5px;
			background: #fff;
			
            .product-item{
				position: relative;
                float: left;
				width: 116px;
				height: 215px;
                background: #fff;
                
                margin-right: 5px;
				margin-bottom: 10px;
				box-shadow: 0 3px 2px 0px rgba(99, 99, 99, 0.1);
				border-radius: 6px;

                &:nth-child(3n){
                    margin-right: 0;
                }

                .img{
                    width: 116px;
                    height: 116px;
					border-radius: 6px 6px 0 0;
					overflow: hidden;
					
					img{
						display: block;
						width: 100%;
					}
                }

                .name{
                    margin: 5px 5px 10px;
                    font-size: 12px;
                    line-height: 16px;
                    height: 32px;

                    display: -webkit-box; 
                    -webkit-box-orient:vertical; 
                    -webkit-line-clamp: 2; 
                    overflow: hidden;
                }
				
				.sellpoint{
					margin: 5px 5px 10px;
					font-size: 12px;
					line-height: 16px;
					max-height: 32px;
					color: #999;
					
					display: -webkit-box; 
					-webkit-box-orient:vertical; 
					-webkit-line-clamp: 2; 
					overflow: hidden;
				}
                
                .pro-info{
                    display: flex;
                    margin: 0 5px;
					align-items: center;

                    .left{
                        flex: 1 1 auto;
                        color: #ff547b;
                        font-weight: bold;
                        font-size: 14px;
						
						display: flex;
						align-items: baseline;
						
						.old{
							font-size: 12px;
							color: #999;
							margin-left: 10px;
							text-decoration: line-through;
							font-weight: 400;
						}
                    }
					
					.right{
						flex: 0 0 auto;
						width: 13px;
						height: 12px;
						
						img{
							display: block;
							width: 100%;
						}
					}
                }
				
				
				
				.btn{
					height: 26px;
					line-height: 26px;
					text-align: center;
					background-color: #ff547b;
					color: #fff;
					font-size: 12px;
					margin-top: 10px;
				}
            }
			
			.product-item-column2{
				width: 174px;
				height: 250px;
				
				&:nth-child(3n){
				    margin-right: 5px;
				}
				
				&:nth-child(2n){
				    margin-right: 0;
				}
				
				.img{
					width: 174px;
					height: 174px;
				}
			}
			
			.product-item-column1{
				width: 360px;
				height: auto;
				padding-bottom: 10px;
				
				&:nth-child(3n){
				    margin-right: 5px;
				}
				
				&:nth-child(2n){
				    margin-right: 0;
				}
				
				.img{
					width: 360px;
					height: 360px;
				}
				
				.name{
					font-size: 14px;
					line-height: 18px;
					height: auto;
					max-height: 36px;
					margin-bottom: 0;
				}
				
				.pro-info .left{
					font-size: 16px;
				}
				.pro-info .right{
					width: 16px;
					height: 15px;
				}
				
			}
            
        }
		
		.listSlide{
			display: flex;
			
			.product-item{
				float: none;
				
				&:nth-child(3n){
				    margin-right: 5px;
				}
			}
		}
        
    }
    .haveSpace{
        margin-bottom: 10px;
    }
	
	.tabs{
		height: 42px;
		border-bottom: 1px solid #eee;
		display: flex;
		align-items: center;
		padding-left: 10px;
		// margin-right: 10px;
		background: #fff;
		overflow: auto;
		
		.tab{
			height: 24px;
			line-height: 24px;
			padding: 0 15px;
			border-radius: 12px;
			font-size: 14px;
			color: #666;
			background: #fff;
			white-space: nowrap;
			margin-right: 15px;
		}
		
		.current{
			background: #ff547b;
			color: #fff;
		}
		
		
	}
	
	.filter-wraper{
		padding-top: 5px;
		display: flex;
		background-color: #fff;
		
		.filter-wraper-item{
			flex: 1 1 auto;
			height: 40px;
			font-size: 14px;
			display: flex;
			align-items: center;
			justify-content: center;
			
			.arrow{
				margin-left: 3px;
				.arrow-up{
					border-left: 4px solid transparent;
					border-right: 4px solid transparent;
					border-bottom: 4px solid #999;
				}
				.arrow-down{
					border-left: 4px solid transparent;
					border-right: 4px solid transparent;
					border-top: 4px solid #999;
					margin-top: 1px;
				}
			}
			
			.filter-icon{
				display: flex;
				width: 14px;
			}
		}
	}
	
	.group-wraper{
		display: flex;
		min-height: 300px;
		max-height: 600px;
		overflow: hidden;
		border-top: 1px solid #eee;
		
		.group-left{
			flex: 0 0 auto;
			width: 100px;
			padding: 10px 10px 0 5px;
			border-right: 1px solid #eee;
			box-sizing: border-box;
			
			.group-left-item{
				height: 30px;
				line-height: 30px;
				margin-bottom: 20px;
				font-size: 12px;
				padding: 0 10px;
				border-radius: 15px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.group-left-item-current{
				background-color: #ff547b;
				color: #fff;
			}
		}
		
		.group-right{
			flex: 1 1 auto;
			
			.group-title{
				font-size: 13px;
				font-weight: bold;
				padding-top: 15px;
				padding-left: 10px;
			}
		}
	}
	
	.product{
		
		.item{
			position: relative;
			display: flex;
			background: #fff;
			border-top: 1px solid #eee;
			padding: 10px;
			
			&:first-child{
				margin-top: 0;
				border-top: none;
			}
			
			.img{
				position: relative;
				flex: 0 0 auto;
				width: 90px;
				height: 90px;
				overflow: hidden;
				
				>img{
					display: block;
					width: 100%;
				}
			}
			
			.right{
				flex: 1 1 auto;
				overflow: hidden;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				margin-left: 10px;
				margin-right: 10px;
				
				.top{
					overflow: hidden;
				}
				
				.name{
					font-size: 12px;
					
					overflow: hidden;
					max-height: 32px;
					line-height: 16px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;

					.name-box{
						display: inline;
					}
				}
				.maidian{
					font-size: 12px;
					color: #999;
					margin-top: 4px;
					max-height: 30px;
					line-height: 15px;
					display: -webkit-box; 
					-webkit-box-orient:vertical; 
					-webkit-line-clamp: 2; 
					overflow: hidden;
				}
				
				
				.price{
					display: flex;
					align-items: flex-end;
					margin-top: 3px;
					
					.left{
						font-size: 14px;
						color: #ff547b;
						flex: 1 1 auto;
						margin-right: 10px;
						// white-space: nowrap;
						// display: flex;
						// align-items: center;
						
						.old{
							font-size: 12px;
							color: #999;
							padding-top: 2px;
							text-decoration: line-through;
						}
					}
					.btn{
						width: 30px;
						height: 30px;
						display: flex;
						justify-content: center;
						align-items: flex-end;
							
						img{
							display: flex;
							width: 20px;
						}
					}
				}
			}
		}
		.shouqing {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, .4);
			display: flex;
			justify-content: center;
			align-items: center;
		
			img {
				display: block;
				width: 50%;
				transform: rotate(-30deg);
			}
		}
	}
	
	.tag-box{
		position: absolute;
		left: 5px;
		top: 5px;
		width: 18px;
		padding: 5px 0;
		color: #fff;
		font-size: 12px;
		border-radius: 9px;
		background: #f00000;
		text-align: center;
	}
	
	::-webkit-scrollbar {
		width: 0px;
		height: 0;
	}
	
	::-webkit-scrollbar-track {
	background-color: none;
	}
	
	::-webkit-scrollbar-thumb {
	background-color: none;
	}
	
	::-webkit-scrollbar-thumb:hover {
	background-color: none;
	}
	
	::-webkit-scrollbar-thumb:active {
	background-color: none;
	}
</style>

<template>
    <div class="singleRowProduct-module" @mouseenter="data.visible=true" @mouseleave="data.visible=false" style="position: relative;padding-left: 40px;padding-right: 45px;" :class="{haveSpace: !data.hideSpace}">
        <module-name :name="name"></module-name>
        <controler @edit="edit();data.editVisible=true" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        <!-- <edit :data="data" @submit="$emit('submit')" :visible="data.editVisible" :name="name" @close="data.editVisible=false"  /> -->
        
        <div style="background: #fff;">
			
					<div class="group-wraper" v-if="data.moduleType == 'classify' && data.groupType == 2">
						<div class="group-left" v-if="data.classifylist.length">
							<div class="group-left-item" :class="{'group-left-item-current': index === 0}" v-for="(item, index) in data.classifylist" :key="item.id">
								{{item.GroupName}}
							</div>
						</div>
						<div class="group-left" v-else>
							<div class="group-left-item" :class="{'group-left-item-current': index === 0}" v-for="(item, index) in normalGroupList" :key="item.id">
								{{item.GroupName}}
							</div>
						</div>
						<div class="group-right">
							<div class="group-title" v-if="data.classifylist.length">{{data.classifylist[0].GroupName}}</div>
							<div class="group-title" v-else>{{normalGroupList[0].GroupName}}</div>
							<div class="product">
									<div class="item" v-if="!data.classifylist.length">
									<div class="img">
										<img :src="normalData.url" >
									</div>
									<div class="right">
										<div class="top">
											<div class="name">{{normalData.name}}</div>
											<div class="maidian">{{normalData.point}}</div>
										</div>
										
										<div class="price">
											<div class="left">
												&yen;99.99
												<div class="old">&yen;299.99</div>
											</div>
											<div class="btn">
												<img src="http://cdn.dkycn.cn/images/melyshop/pc-cart-1.png">
											</div>
										</div>
									</div>
									<div class="tag-box" style="left: 15px;top:15px" v-if="data.iconType == 'show'">{{data.iconName}}</div>
								</div>
								<div class="item" v-else v-for="item in list" :key="item.Id">
									<div class="img">
										<img :src="item.ImgUrlComplete">
									</div>
									<div class="right">
										<div class="top">
											<div class="name">{{item.Name}}</div>
											<div class="maidian">{{item.SellPoint}}</div>
										</div>
										
										<div class="price">
											<div class="left">
												&yen;{{item.ProductPrice}}
												<div class="old" v-if="item.OriginPrice">&yen;{{item.OriginPrice}}</div>
											</div>
											<div class="btn">
												<img src="http://cdn.dkycn.cn/images/melyshop/pc-cart-1.png">
											</div>
										</div>
									</div>
									<div class="tag-box" style="left: 15px;top:15px" v-if="data.iconType == 'show'">{{data.iconName}}</div>
								</div>
							</div>
						</div>
					</div>
					<div v-else>
						<div class="tabs" v-if="data.moduleType == 'classify' && data.classifylist.length">
							<div class="tab" :class="{current: index === 0}" v-for="(item, index) in data.classifylist" :key="item.id">
								{{item.GroupName}}
							</div>
						</div>
						
						<div class="filter-wraper" v-if="data.styleType == 'tiled'">
							<div class="filter-wraper-item" v-if="showSaleSort">销量</div>
							<div class="filter-wraper-item" v-if="showPriceSort">
								价格
								<div class="arrow">
									<div class="arrow-up"></div>
									<div class="arrow-down"></div>
								</div>
							</div>
							<div class="filter-wraper-item" v-if="showNewSort">新品</div>
							<div class="filter-wraper-item" v-if="showGroupSort">
								筛选
								<img class="filter-icon" src="https://cdn.dkycn.cn/images/melyshop/pc-filter.png"/>
							</div>
						</div>
						
						<div class="product-list" v-if="!list.length">
								<div class="product-item" :class="{'product-item-column1': data.column == '1', 'product-item-column2': data.column == '2'}" v-for="i in 3" :key="i">
								<div class="img">
									<img :src="normalData.url" />
								</div>
										<p class="name">{{normalData.name}}</p>
								<div class="sellpoint"  v-if="data.column == '1'">{{normalData.SellPoint}}</div>
										<div class="pro-info">
												<div class="left">
														￥{{normalData.price}}
										<span class="old">￥{{normalData.OriginPrice}}</span>
												</div>
									<div class="right" v-if="data.column != '3'">
										<img src="http://cdn.dkycn.cn/images/melyshop/page-manage-cart-icon.png"/>
									</div>
										</div>
								<div class="btn" v-if="data.column == '3'">立即购买</div>
								<div class="tag-box" v-if="data.iconType == 'show'">{{data.iconName}}</div>
								</div>
						</div>
						<div class="product-list" :class="{listSlide: data.styleType=='slide'}" v-else>
								<div class="product-item" :class="{'product-item-column1': data.column == '1', 'product-item-column2': data.column == '2'}" :key="item.Id" v-for="item in list">
								<div class="img">
									<img :src="item.ImgUrlComplete" />
								</div>
										<div class="name">{{item.Name}}</div>
								<div class="sellpoint"  v-if="data.column == '1'">{{item.SellPoint}}</div>
										<div class="pro-info">
												<div class="left">
														￥{{item.ProductPrice}}
										<span class="old" v-if="item.OriginPrice">￥{{item.OriginPrice}}</span>
												</div>
											<div class="right" v-if="data.column != '3'">
												<img src="http://cdn.dkycn.cn/images/melyshop/page-manage-cart-icon.png"/>
											</div>
										</div>
								<div class="btn" v-if="data.column == '3'">立即购买</div>
								<div class="tag-box" v-if="data.iconType == 'show'">{{data.iconName}}</div>
								</div>
						</div>
					</div>
			
        </div>
		
		<div class="cover" v-if="data.visible" style="position: absolute;left:40px;top:0;right: 45px;height: 100%;border: 3px solid #0000FF;"></div>
		

    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';

export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        edit
    },
	computed:{
		list(){
			if (this.data.moduleType == 'shop' && this.data.shopType == 'shop'){
				return this.data.list;
			}else if (this.data.moduleType == 'shop' && this.data.shopType == 'classify'){
				if (this.data.shopClassifylist.length){
					return this.data.shopClassifylist[0].ProductList;
				}else{
					return []
				}
			}else{
				// console.log(this.data.classifylist)
				if (this.data.classifylist.length){
					return this.data.classifylist[this.selectedIndex].ProductList;
				}else{
					return []
				}
			}
		},
		showSaleSort(){
			var list = this.data.sortList || [];
			return list.indexOf('1') > -1;
		},
		showPriceSort(){
			var list = this.data.sortList || [];
			return list.indexOf('2') > -1;
		},
		showNewSort(){
			var list = this.data.sortList || [];
			return list.indexOf('3') > -1;
		},
		showGroupSort(){
			var list = this.data.sortList || [];
			return list.indexOf('4') > -1;
		}
	},
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
    data () {
        return {
            selectedIndex: 0,
            normalData: {
                url: this.$store.state.pageManageConfig.staticImage.noPic,
                name: '此处是商品名称-此处是商品名称',
				point: '此处是卖点此处是卖点此处是卖点此处是卖点此处是卖点此处是卖点此处是卖点此处是卖点此处是卖点',
                price: 99.00,
				OriginPrice: 100,
            },
			normalGroupList: [{
				id: 1,
				GroupName: '分组1'
			},
			{
				id: 2,
				GroupName: '分组2'
			}]
        };
    },
    methods: {
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        
        close(){
            this.$emit('visibleChange', false)
        }

    },
    mounted () {
		// console.log(this.data)
    },
    watch: {
        
    }
};
</script>

