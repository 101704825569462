<style scoped lang="less">
	
	.wraper{
		
		// padding: 5px;
		// background-color: #ff557c;
	}
	
    .product{
		
		padding: 0 10px;
		// margin: 0 10px;
		background-color: #fff;
		
		
		.item{
			display: flex;
			background: #fff;
			padding: 10px 0;
			border-top: 1px solid #ddd;
			
			&:first-child{
				margin-top: 0;
				border-top: none;
			}
			
			.img{
				position: relative;
				flex: 0 0 auto;
				width: 150px;
				height: 150px;
				overflow: hidden;
				
				>img{
					display: block;
					width: 100%;
				}
			}
			
			.right{
				flex: 1 1 auto;
				overflow: hidden;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				margin-left: 10px;
				
				.top{
					overflow: hidden;
				}
				
				.name{
					font-size: 15px;
					
					overflow: hidden;
					max-height: 36px;
					line-height: 17px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					margin-bottom: 3px;

				}
				
				.maidian{
					overflow: hidden;
					max-height: 32px;
					line-height: 16px;
					font-size: 12px;
					color: #999;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					margin-bottom: 3px;
				}
				
				.time-box{
					display: flex;
					justify-content: space-between;
					align-items: center;
					
					.time-box-left{
						width: 75px;
						
						img{
							display: block;
							width: 100%;
						}
					}
					
					.time-box-right{
						display: flex;
						align-items: center;
						font-size: 12px;
						white-space: nowrap;
						
						.num{
							height: 16px;
							line-height: 16px;
							padding: 0 5px;
							color: #fff;
							background: #000;
							border-radius: 2px;
							font-size: 12px;
							margin: 0 2px;
						}
					}
					
					.state0{
						color: #666;
					}
				}
				
				.price{
					display: flex;
					
					.left-wraper{
						flex: 1 1 auto;
						margin-right: 10px;
					}
					
					.icon{
						font-size:20px;
						font-family: 'biao ti hei';
						color: #ff547b;
						line-height: 1;
						// display: block;
						// width: 80px;
						
						// img{
						// 	display: block;
						// 	width: 100%;
						// }
					}
					
					.left{
						font-size: 17px;
						color: #ff547b;
						
						white-space: nowrap;
						display: flex;
						align-items: baseline;
						
						.small{
							font-size: 12px;
						}
						
						.old{
							font-size: 12px;
							color: #999;
							padding-top: 2px;
							margin-left: 5px;
							text-decoration: line-through;
						}
					}
					.btn{
						width: 70px;
						height: 26px;
						line-height: 26px;
						text-align: center;
						font-size: 12px;
						color: #fff;
						background: #ff547b;
						border-radius: 16px;
						margin-top: 8px;
					}
				}
			}
		}
		.shouqing {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, .4);
			display: flex;
			justify-content: center;
			align-items: center;
		
			img {
				display: block;
				width: 50%;
				transform: rotate(-30deg);
			}
		}
    }
    .haveSpace{
        margin-bottom: 10px;
    }
</style>
 
<template>
    <div class="singleRowProduct-module" @mouseenter="data.visible=true" @mouseleave="data.visible=false" style="position: relative;padding-left: 40px;padding-right: 45px;"  :class="{haveSpace: !data.hideSpace}">
        <module-name :name="name"></module-name>
        <controler @edit="edit();data.editVisible=true" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        
		<div class="wraper">
        <div class="product" v-if="!data.list.length">
            <div class="item" v-for="i in 2" :key="i">
				<div class="img">
					<img :src="normalData.url" >
				</div>
				<div class="right">
					<div class="top">
						<div class="name">{{normalData.name}}</div>
					</div>
					
					<div class="maidian">{{normalData.maidian}}</div>
					
					<div class="time-box">
						<div class="time-box-right">
							<span class="num">2</span>天
							<span class="num">16</span>:
							<span class="num">47</span>:
							<span class="num">06</span>
							结束
						</div>
						<div></div>
					</div>
					
					<div class="price">
						<div class="left-wraper">
							<div class="icon">社群专享福利</div>
							<div class="left">
								<span class="small">&yen;</span>99.99
								<div class="old">&yen;299.99</div>
							</div>
						</div>
						
						<div class="btn">立即抢购</div>
					</div>
				</div>
			</div>
        </div>
		<div class="product" v-else>
		    <div class="item" v-for="(item, index) in data.list" :key="item.id" v-if="index<18">
				<div class="img">
					<img :src="item.ImgUrlComplete" >
					<div class="shouqing" v-if="item.Stock <= 0">
						<img src="https://cdn.dkycn.cn/images/melyshop/shouqing.png" mode="widthFix"/>
					</div>
				</div>
				<div class="right">
					<div class="top">
						<div class="name">{{item.ProductName}}</div>
					</div>
					
					<div class="maidian">{{item.SellPoint}}</div>
					
					<div class="time-box">
						<div class="time-box-right" v-if="item.State === 1">
							<span v-if="item.days > 0"><span class="num">{{item.days}}</span>天</span>
							<span class="num">{{item.hours}}</span>:
							<span class="num">{{item.minutes}}</span>:
							<span class="num">{{item.seconds}}</span>
							结束
						</div>
						<div class="time-box-right state0" v-if="item.State === 0">
							<img src="https://cdn.dkycn.cn/images/melyshop/wechat-group-icon-1.jpg" style="display: block;width: 18px;margin-right:3px;margin-top: -3px;"/>
							距开始：
							<span>{{item.days}}</span>天
							<span>{{item.hours}}</span>:
							<span>{{item.minutes}}</span>:
							<span>{{item.seconds}}</span>
						</div>
						<div></div>
					</div>
					
					<div class="price">
						
						<div class="left-wraper">
							<div class="icon">{{item.ActivityFlag || '社群专享福利'}}</div>
							<div class="left">
								<span class="small">&yen;</span>{{item.ExclusivePrice}}
								<div class="old" v-if="item.OriginPrice">&yen;{{item.OriginPrice}}</div>
							</div>
						</div>
						<div class="btn" v-if="item.State === 2" style="background: #999;color:#fff;border:none">已结束</div>
						<div class="btn" v-if="item.State === 1 && item.Stock>0">立即抢购</div>
						<div class="btn" v-if="item.State === 0 || (item.State === 1&&item.Stock<=0)" style="opacity: .4;">去看看</div>
					</div>
				</div>
			</div>
		</div>
		</div>
		<div class="cover" v-if="data.visible" style="position: absolute;left:40px;top:0;right: 45px;height: 100%;border: 3px solid #0000FF;"></div>

    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';



export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        edit,
        
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
    data () {
        return {
            
            btnType: 1, //按钮三种样式
            normalData: {
                url: this.$store.state.pageManageConfig.staticImage.noPic,
                name: '此处是商品名称此处是商品名称此处是商品名称',
				maidian: '此处是卖点此处是卖点此处是卖点此处是卖点此处是卖点',
                price: 99.00,
                marketPrice: 100.00,
            }
        };
    },
    methods: {
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        
        close(){
            this.$emit('visibleChange', false)
        }

    },
    mounted () {
		console.log(this.data, '社群专享品')
    },
    watch: {
        
    }
};
</script>

