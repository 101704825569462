<style scoped lang="less">
	.singleRow-module-edit {
		// position: absolute;
		// right: -530px;
		width: 480px;
		padding: 20px 20px 30px;
		background: #fff;
		font-size: 14px;

		.title {
			position: relative;
			font-size: 18px;
			padding-bottom: 20px;
			margin-bottom: 25px;
			border-bottom: 1px solid #ddd;
		}

		.icon-close {
			position: absolute;
			right: 0;
			cursor: pointer;
		}

		.tip {
			margin-bottom: 20px;
		}

		.product-list {
			padding-top: 10px;
			overflow: hidden;

			.product-item {
				position: relative;
				float: left;
				width: 65px;
				height: 65px;
				margin-right: 10px;
				margin-bottom: 10px;
			}

			.img {
				position: relative;
				width: 65px;
				height: 65px;
				overflow: hidden;

				img {
					display: block;
					width: 100%;
				}
			}

			.control {
				position: absolute;
				top: -10px;
				right: -10px;
			}

		}

		.tip1 {
			text-align: center;
			color: #999;

			.count {
				color: #1890ff;
			}
		}

		.add-btn {
			text-align: center;
			border: 1px dashed #ddd;
			font-size: 16px;
			color: #666;
			height: 48px;
			line-height: 48px;
			text-align: center;
			margin-bottom: 18px;
			cursor: pointer;
			width: 440px;
		}

		.tip2 {
			margin-top: 30px;
			margin-bottom: 10px;
		}

		.mod-footer {
			margin-top: 50px;
			padding-top: 20px;
			border-top: 1px solid #ddd;

			display: flex;

			.left {
				flex: 1 1 auto;
				padding-top: 6px;
			}
		}


	}

	.link-info {
		width: 440px;
		height: 28px;
		line-height: 28px;
		background: #409eff;
		padding: 0 5px;
		margin-bottom: 10px;
		color: #fff;
		font-size: 14px;

		display: flex;

		.cont {
			flex: 1 1 auto;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.icon {
			flex: 0 0 auto;
		}
	}
</style>

<template>
	<div>


		<!-- <transition name="scale"> -->
			<div class="singleRow-module-edit" v-if="visible">
				<div></div>
				<p class="title">
					{{name}}
					<!-- <i @click="close" class="el-icon-close icon-close" style="font-size: 32px;color: #999;top:-12px"></i> -->
				</p>

				<!-- <p class="tip">选择商品（建议添加6个）</p> -->
				<el-radio-group v-model="data.moduleType" style="margin-bottom: 10px;">
					<el-radio-button label="shop">商品</el-radio-button>
					<el-radio-button label="classify">商品分组</el-radio-button>
				</el-radio-group>


				<div v-if="data.moduleType == 'shop'">
					<el-form label-width="70px" size="mini">
						<el-form-item label="选择商品">
							<el-radio-group v-model="data.shopType">
								<el-radio label="shop">商品</el-radio>
								<el-radio label="classify">商品分组</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-form>
				</div>

				<div v-if="data.moduleType == 'shop' && data.shopType == 'shop'">
					<draggable class="product-list" :list="data.list" :options="{animation: 300, handle:'.product-item'}">
						<transition-group name="bounce-out-left">
							<div class="product-item" :key="item.Id" v-for="item in data.list">
								<div class="img">
									<img :src="$store.state.pageManageConfig.imghost+item.ImgUrl" />
								</div>
								<div class="control" @click="deleteData(item)">
									<i class="el-icon-error" style="font-size: 24px;opacity: .6;"></i>
								</div>
							</div>
						</transition-group>
					</draggable>
				</div>

				<div v-if="data.moduleType == 'shop' && data.shopType == 'classify'" style="margin-bottom: 18px;">
					<draggable class="classify-list" :list="data.shopClassifylist" :options="{animation: 300, handle:'.link-info'}">
						<transition-group name="bounce-out-left">
							<div class="link-info" :key="item.Id" v-for="item in data.shopClassifylist">
								<div class="cont">{{item.GroupName}}</div>
								<div class="icon" @click="deleteLinkData(item)">
									<i class="el-icon-close" style="color: #fff;font-size: 16px;"></i>
								</div>
							</div>
						</transition-group>
					</draggable>
				</div>

				<div v-if="data.moduleType == 'classify'" style="margin-bottom: 18px;">
					<draggable class="classify-list" :list="data.classifylist" :options="{animation: 300, handle:'.link-info'}">
						<transition-group name="bounce-out-left">
							<div class="link-info" :key="item.Id" v-for="item in data.classifylist">
								<div class="cont">{{item.GroupName}}</div>
								<div class="icon" @click="deleteLinkData(item)">
									<i class="el-icon-close" style="color: #fff;font-size: 16px;"></i>
								</div>
							</div>
						</transition-group>
					</draggable>
				</div>


				<div class="add-btn" v-if="data.moduleType == 'shop' && data.shopType == 'shop'" @click="showShopModal">
					<i class="el-icon-circle-plus-outline" style="margin-right: 5px;vertical-align: -2px;font-size: 18px;"></i>添加商品
				</div>
				<div class="add-btn" v-if="data.moduleType == 'shop' && data.shopType == 'classify'" @click="showShopClassifyModal">
					<i class="el-icon-circle-plus-outline" style="margin-right: 5px;vertical-align: -2px;font-size: 18px;"></i>添加分组
				</div>
				<div class="add-btn" v-if="data.moduleType == 'classify'" @click="showShopClassifyModal">
					<i class="el-icon-circle-plus-outline" style="margin-right: 5px;vertical-align: -2px;font-size: 18px;"></i>添加分组
				</div>

				<el-form label-width="100px" size="mini" v-if="data.moduleType == 'classify'">
					<el-form-item label="分组导航样式">
						<el-radio-group v-model="data.groupType">
							<el-radio style="width: 80px;" :label="1">顶部</el-radio>
							<el-radio style="width: 80px;" :label="2">靠左</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-form>
				
				<el-form label-width="100px" size="mini" v-if="data.moduleType == 'shop' || data.moduleType == 'classify' && data.groupType != 2">
					<el-form-item label="商品展示">
						<el-radio-group v-model="data.styleType" @change="styleTypeChange">
							<el-radio style="width: 80px;" label="tiled">平铺</el-radio>
							<el-radio style="width: 80px;" label="slide">滑动</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-form>
				
				<el-form label-width="100px" size="mini" v-if="(data.moduleType == 'shop' || data.moduleType == 'classify' && data.groupType != 2) && data.styleType == 'tiled'">
					<el-form-item label="排序与筛选">
						<el-checkbox-group v-model="data.sortList">
						    <el-checkbox label="1">销量</el-checkbox>
						    <el-checkbox label="2">价格</el-checkbox>
						    <el-checkbox label="3">新品</el-checkbox>
						    <el-checkbox label="4">分组</el-checkbox>
						  </el-checkbox-group>
					</el-form-item>
				</el-form>
				
				<el-form label-width="100px" size="mini" v-if="(data.moduleType == 'shop' || data.moduleType == 'classify' && data.groupType != 2) && data.styleType == 'tiled'">
					<el-form-item label="排列样式">
						<el-radio-group v-model="data.column">
							<el-radio style="width: 80px;" label="1">一排一个</el-radio>
							<el-radio style="width: 80px;" label="2">一排两个</el-radio>
							<el-radio style="width: 80px;" label="3">一排三个</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-form>

				<el-form label-width="100px" size="mini">
					<el-form-item label="商品角标">
						<el-radio-group v-model="data.iconType">
							<el-radio style="width: 80px;" label="show">显示</el-radio>
							<el-radio style="width: 80px;" label="hide">不显示</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-form>


				<el-form label-width="100px" size="mini">
					<el-form-item label="角标名称">
						<el-input v-model="data.iconName" maxlength="2" style="width: 300px;"></el-input>
					</el-form-item>
				</el-form>



				<div class="mod-footer">
					<div class="left">
						<el-checkbox v-model="data.hideSpace"><span style="font-size: 14px;color:#666">隐藏模块下方的间距</span></el-checkbox>
					</div>
					<!-- <div class="right">
                    <Button type="primary" @click="saveData">保存</Button>
                </div> -->
				</div>



			</div>
		<!-- </transition> -->
		<shop-modal :visible="shopVisible" :selectedList="data.list" @change="dataChange" @cancel="shopVisible=false"></shop-modal>
		<classSingleModal :visible="shopClassifyVisible" :selectedList="data.shopClassifylist" @change="shopClassifyChange"
		 @cancel="shopClassifyVisible=false"></classSingleModal>
		<class-modal :visible="classifyVisible" :selectedList="data.classifylist" @change="shopClassifyChange" @cancel="classifyVisible=false"></class-modal>
	</div>
</template>

<script>
	import shopModal from '@/views/components/shopListModal';
	import classModal from '@/views/components/classifyListModal.vue';
	import classSingleModal from '@/views/components/classifySingleModal.vue';

	import draggable from 'vuedraggable';

	export default {
		name: 'normal-module-edit',
		components: {
			shopModal,
			draggable,
			classModal,
			classSingleModal
		},
		props: {
			data: {
				type: Object,
				default: () => {
					return {}
				}
			},
			visible: {
				type: Boolean,
				default: false
			},
			name: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				shopVisible: false,
				isSingle: false, //是否是单选
				editData: {},
				// moduleType: 'shop',
				// shopType: 'shop',
				// styleType: 'tiled',
				// iconType: 'show',
				// iconName: '',

				shopClassifyVisible: false,
				classifyVisible: false,

			};
		},
		methods: {
			close() {
				this.$emit('close')
			},
			
			styleTypeChange(record){
				// console.log(record)
				if (record == 'slide'){
					this.data.column = '3';
				}
			},

			//商品
			showShopModal() {
				this.shopVisible = true;
			},
			dataChange(record) {
				this.data.list = record
			},
			deleteData(record) {
				this.data.list = this.data.list.filter(item => {
					return item.Id !== record.Id;
				})
			},

			//商品分组
			showShopClassifyModal() {
				if (this.data.moduleType == 'shop') {
					this.shopClassifyVisible = true;
				} else {
					this.classifyVisible = true;
				}
			},
			shopClassifyChange(record) {
				if (this.data.moduleType == 'shop') {
					this.data.shopClassifylist = record;
				} else {
					this.data.classifylist = record;
				}
			},
			deleteLinkData(record) {
				if (this.data.moduleType == 'shop') {
					this.data.shopClassifylist = this.data.shopClassifylist.filter(item => {
						return item.Id !== record.Id;
					})
				} else {
					this.data.classifylist = this.data.classifylist.filter(item => {
						return item.Id !== record.Id;
					})
				}
			},


			handleMaxSize() {
				this.$Message.error('上传图片最大2M！');
			},
			handleFormatError() {
				this.$Message.error('上传失败！');
			},
			saveData() {
				this.$emit('submit')
			}

		},
		mounted() {

		},
		watch: {
			data(value) {
				//this.$set(this.data,'btnType',1)

				//this.data.btnType = 1;
				//console.log(value)
			}
		}
	};
</script>
