<style scoped lang="less">
	.singleRow-module-edit{
        // position: absolute;
        // right: -530px;
        width: 480px;
        padding: 20px 20px 30px;
        background: #fff;
        font-size: 14px;

        .title{
            position: relative;
            font-size: 18px;
            padding-bottom: 20px;
            margin-bottom: 25px;
            border-bottom: 1px solid #ddd;
        }

        .icon-close{
            position: absolute;
            right: 0;
            cursor: pointer;
        }

        .tip{
            margin-bottom: 10px;
        }

        .product-list{
            .product-item{
                display: flex;
                margin-bottom: 10px;
            }
            .box{
                flex: 1 1 auto;
                background: #f5f5f5;
                height: 60px;
				padding: 0 10px;
				font-size: 14px;
				color: #666;
				
				display: flex;
				align-items: center;
				
				img{
					flex: 0 0 auto;
					display: block;
					width: 54px;
					height: 32px;
					margin-right: 10px;
				}
				
				.info{
					flex: 1 1 auto;
				}
            }
            .control{
                flex: 0 0 auto;
                width: 45px;
                text-align: right;
                margin-top: 20px;

                span{
                    display: inline-block;
                    cursor: pointer;
                }
                .edit{
                    color: #1890ff
                }
                .delete{
                    color: #f00000;
                    margin-top: 3px;
                }
            }
        }

       

        .add-btn{
            width: 440px;
            text-align: center;
            border: 1px dashed #ddd;
            font-size: 16px;
            color: #666;
            height: 48px;
            line-height: 48px;
            text-align: center;
            margin-top: 10px;
            cursor: pointer;
        }

        .mod-footer{
            margin-top: 30px;
            padding-top: 20px;
            border-top: 1px solid #ddd;

            display: flex;

            .left{
                flex: 1 1 auto;
                padding-top: 6px;
            }
        }

        .choose-box{
            display: flex;
            width: 225px;
        }

        .route{
            line-height: 24px;
            flex: 1 1 auto;
            font-size: 12px;
            color: #666;
            font-family: simsun;
            margin-left: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
	
	.link-info {
		width: 440px;
		height: 28px;
		line-height: 28px;
		background: #409eff;
		padding: 0 5px;
		margin-bottom: 10px;
		color: #fff;
		font-size: 14px;
	
		display: flex;
	
		.cont {
			flex: 1 1 auto;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	
		.icon {
			flex: 0 0 auto;
		}
	}
	
	
	.rule-box{
		font-size: 12px;
		color: #999;
		padding: 10px 0 20px;
		
		.rule{
			color: #409eff;
			cursor: pointer;
		}
	}
    
</style>

<template>
	<!-- <transition name="scale"> -->
	<div class="singleRow-module-edit" v-if="visible">

		<p class="title">
			{{name}}
			<!-- <i @click="close" class="el-icon-close icon-close" style="font-size: 32px;color: #999;top:-12px"></i> -->
		</p>

		<el-radio-group v-model="data.type" style="margin-bottom: 10px;">
			<el-radio-button :label="1">直播间</el-radio-button>
			<el-radio-button :label="2">直播间分组</el-radio-button>
		</el-radio-group>

		<div class="rule-box"><span v-if="data.type==2">拖拽直播间分组进行排序；</span><span class="rule" @click="dialogVisible=true">点击查看直播间排序说明</span></div>

		<!-- <div style="color: #909399;margin-bottom: 20px;">商城内优先根据开播状态排序：直播中＞预告＞已结束；若开播状态相同，开播时间越早，排序越靠前</div> -->

		<!-- <draggable class="product-list" :list="data.list" :options="{animation: 300, handle:'.product-item'}"> -->
		<div class="product-list" v-if="data.type!=2">
			<transition-group name="bounce-out-left">
				<div class="product-item" :key="item.Id" v-for="item in data.list">
					<div class="box">
						<img :src="item.AnchorImgUrl" />
						<div class="info">
							<div>{{item.RoomName}}</div>
							<div style="margin-top: 5px;">{{item.StartTime}}至{{item.EndTime}}</div>
						</div>
					</div>
					<div class="control">
						<!-- <span class="edit" @click="changeData(item)">修改</span> -->
						<span class="delete" @click="deleteData(item)">删除</span>
					</div>
				</div>
			</transition-group>
			<!-- </draggable> -->
		</div>

		<div v-if="data.type==2" style="margin-bottom: 18px;">
			<draggable class="classify-list" :list="data.groupList" :options="{animation: 300, handle:'.link-info'}">
				<transition-group name="bounce-out-left">
					<div class="link-info" :key="item.Id" v-for="item in data.groupList">
						<div class="cont">{{item.GroupName}} <span v-if="item.Id === 0">（显示用户关注的主播）</span></div>
						<div class="icon" v-if="item.Id" @click="deleteLinkData(item)">
							<i class="el-icon-close" style="color: #fff;font-size: 16px;"></i>
						</div>
					</div>
				</transition-group>
			</draggable>
		</div>

		<div class="add-btn" @click="showModal" v-if="data.type!=2">
			<i class="el-icon-plus" style="margin-right: 5px;vertical-align: -2px;font-size: 18px;"></i>添加直播
		</div>
		<div class="add-btn" @click="showGroupModal" v-if="data.type==2">
			<i class="el-icon-plus" style="margin-right: 5px;vertical-align: -2px;font-size: 18px;"></i>添加直播间分组
		</div>

		<el-form label-width="100px" size="mini" style="margin-top: 20px;" v-if="data.type==2 && mallkey==='bofenfen'">
			<el-form-item label="是否显示关注">
				<el-radio-group v-model="data.showFollowGroup" @change="showFollowChange">
					<el-radio style="width: 80px;" :label="1">显示</el-radio>
					<el-radio style="width: 80px;" :label="2">隐藏</el-radio>
				</el-radio-group>
			</el-form-item>
		</el-form>

		<el-form label-width="100px" size="mini" style="margin-top: 20px;">
			<el-form-item label="列表展示">
				<el-radio-group v-model="data.style">
					<el-radio style="width: 80px;" :label="1">详细列表</el-radio>
					<el-radio style="width: 80px;" :label="2">1排2个</el-radio>
				</el-radio-group>
			</el-form-item>
		</el-form>

		<div class="mod-footer">
			<div class="left">
				<el-checkbox v-model="data.hideSpace"><span style="font-size: 14px;color:#666">隐藏模块下方的间距</span></el-checkbox>
			</div>
			<!-- <div class="right">
                    <Button type="primary" @click="saveData">保存</Button>
                </div> -->
		</div>

		<el-dialog title="直播间排序规则" :visible.sync="dialogVisible" width="40%">
			<div>
				第一排序：根据后台直播间管理设置的序号值排序，序号值 越大，排序越靠前<br/><br/>
				
				第二排序：根据直播间状态，直播中>直播预告>已结束直播；<br/><br/>
				
				第三排序：根据直播间创建的订单数量，创建的订单数量越大排序越靠前<br/><br/>
				
				第四排序：根据直播间的开播时间，开播时间越早越靠前；如果是已经结束的直播，开播时间越晚越靠前<br/>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>

		<live-modal :visible="shopVisible" :selectedList="data.list" @change="dataChange" @cancel="shopVisible=false"></live-modal>
		<liveGroupModal :visible="groupVisible" :selectedList="data.groupList" @change="groupChange" @cancel="groupVisible=false"></liveGroupModal>

	</div>
	<!-- </transition> -->
</template>

<script>
	import liveModal from '@/views/components/liveModal';
	import liveGroupModal from '@/views/components/liveGroupModal.vue';
	import draggable from 'vuedraggable';

	export default {
		name: 'normal-module-edit',
		components: {
			liveModal,
			liveGroupModal,
			draggable
		},
		props: {
			data: {
				type: Object,
				default: () => {
					return {}
				}
			},
			visible: {
				type: Boolean,
				default: false
			},
			name: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				shopVisible: false,
				groupVisible: false,
				editData: {},
				dialogVisible: false,
				mallkey: ''
			};
		},
		methods: {
			close() {
				this.$emit('close')
			},
			showModal(record) {
				this.editData = record;
				this.shopVisible = true;
			},
			dataChange(record) {
				this.addData(record);
			},
			groupChange(record) {
				if (this.data.showFollowGroup != 2 && this.mallkey=='bofenfen') {
					record = [{
						GroupName: '关注',
						Id: 0
					}].concat(record)
				}
				this.data.groupList = record.map(item => {
					item.LiveRoomList = (item.LiveRoomList || []).map(obj => {
						obj.ProductCoverImgUrl = obj.CoverImgUrl;
						obj.ProductPrice = obj.Price;
						return obj;
					})
					return item;
				});
			},
			showGroupModal() {
				this.groupVisible = true;
			},
			showFollowChange(e) {
				if (e === 1) {
					this.data.groupList = [{
						GroupName: '关注',
						Id: 0
					}].concat(this.data.groupList);
				} else {
					this.data.groupList = this.data.groupList.filter(item => {
						return item.Id != 0;
					})
				}
			},

			//添加产品，并且过滤掉相同产品
			addData(record) {
				this.data.list = record;
			},

			deleteData(record) {

				this.data.list = this.data.list.filter(item => {
					return item.Id !== record.Id;
				})

			},
			deleteLinkData(record) {
				this.data.groupList = this.data.groupList.filter(item => {
					return item.Id !== record.Id;
				})
			},
			changeData(record) {

				this.shopVisible = true;
				this.isSingle = true;

				this.editData = record;

			},
			saveData() {
				this.$emit('submit')
			},
			guid() {
				var a = function() {
					return (65536 * (1 + Math.random()) | 0).toString(16).substring(1)
				};
				return a() + a() + "-" + a() + "-" + a() + "-" + a() + "-" + a() + a() + a()
			}

		},
		mounted() {
			if (this.data.showFollowGroup == 2) {
				this.data.groupList = this.data.groupList.filter(item => {
					return item.Id !== 0;
				})
			}
			
			this.mallkey = localStorage.getItem('mlmzMallKey')
			
		},
		watch: {
			data(value) {
				//this.$set(this.data,'btnType',1)

				//this.data.btnType = 1;
				//console.log(value)
			}
		}
	};
</script>
