<style scoped lang="less">
    .singleRow-module-edit{
        // position: absolute;
        // right: -530px;
        width: 480px;
        padding: 20px 20px 30px;
        background: #fff;
        font-size: 14px;

        .title{
            position: relative;
            font-size: 18px;
            padding-bottom: 20px;
            margin-bottom: 0;
            border-bottom: 1px solid #ddd;
        }

        .icon-close{
            position: absolute;
            right: 0;
            cursor: pointer;
        }

        .tip{
            margin-bottom: 10px;
        }

        .product-list{

            margin-bottom: 20px;

            .product-item{
                display: flex;
                margin-bottom: 10px;
            }
            .box{
                padding: 20px;
                width: 370px;
                background: #f5f5f5;

                
                .info{
                    flex: 1 1 auto;
                    margin-top: 10px;
                	
                	display: flex;
                	overflow: hidden;
                	height: 28px;
                	align-items: center;
                	
                	.txt{
                		flex: 0 0 auto;
                		font-size: 14px;
                		height: 19px;
                	}
                	
                	.link-info{
                		flex: 1 1 auto;
                		overflow: hidden;
                		max-width: 100%;
                		overflow: hidden;
                		
                		display: flex;
                		align-items: center;
                		
                		height: 28px;
                		color: #fff;
                		
                		font-size: 12px;
                		padding: 0 5px;
                	}
                	
                	.cont{
                		padding: 0 5px;
                		background: #409eff;
                		height: 28px;
                		line-height: 28px;
                		white-space: nowrap;
                		overflow: hidden;
                		text-overflow: ellipsis;
                	}
                	.icon{
                		padding-right:5px;
                		background: #409eff;
                		height: 28px;
                		line-height: 32px;
                	}
                }
                .area-title{
                    font-size: 14px;
                    color: #666;
                }

                .area-box{
                    display: flex;
                    margin-top: 5px;

                    .area{
                        width: 230px;
                        height: 30px;
                        line-height: 30px;
                        text-align: center;
                        background: #fff;
                        cursor: pointer;
                        border: 1px dashed #ddd;
                    }

                    .clear{
                        margin-left: 15px;
                        margin-top: 5px;
                        color: #999;
                        cursor: pointer;

                        img{
                            vertical-align: -2px;
                            margin-right: 4px;
                        }
                    }
                }

            }
            .control{
                flex: 0 0 auto;
                width: 45px;
                text-align: right;
                margin-top: 20px;

                span{
                    display: inline-block;
                    cursor: pointer;
                }
                .edit{
                    color: #1890ff
                }
                .delete{
                    color: #f00000;
                    margin-top: 10px;
                }
            }
        }

        .tip1{
            text-align: center;
            color: #999;

            .count{
                color: #1890ff;
            }
        }

        .add-btn{
            width: 440px;
            text-align: center;
            border: 1px dashed #ddd;
            font-size: 16px;
            color: #666;
            height: 48px;
            line-height: 48px;
            text-align: center;
            margin-top: 10px;
            cursor: pointer;
        }

        .tip2{
            margin-top: 10px;
            margin-bottom: 15px;
            color: #999;
        }

        .mod-footer{
            margin-top: 30px;
            padding-top: 20px;
            border-top: 1px solid #ddd;

            display: flex;

            .left{
                flex: 1 1 auto;
                padding-top: 6px;
            }
        }

        .choose-box{
            display: flex;
            width: 225px;
        }

        .route{
            line-height: 24px;
            flex: 1 1 auto;
            font-size: 12px;
            color: #666;
            font-family: simsun;
            margin-left: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .upload-box{
            width: 440px;
            height: 270px;
            background: #eaedf1;
            text-align: center;
            // overflow-y: auto;
            .normal{
                padding-top: 75px;

                img{
                    width: 90px;
                    height: 90px;
                }
                p{
                    text-align: center;
                    font-size: 16px;
                    color: #666;
                    margin-top: 10px;
                }
            }
        }
        .haveColor{
            display: inline-block;
            width: 14px;
            height: 12px;
            vertical-align: -1px;
            margin-right: 5px;
        }
    }

    
</style>

<template>
    <!-- <transition name="scale"> -->
        <div class="singleRow-module-edit" v-if="visible">
            
            <p class="title">
                {{name}} 
                <!-- <i @click="close" class="el-icon-close icon-close" style="font-size: 32px;color: #999;top:-12px"></i> -->
            </p>

            <div class="tip2">建议图片宽度750，高200~950，支持类型：jpg、png。</div>

            <div class="tip">上传图片</div>
            <el-upload
                ref="upload"
            	:show-file-list="false"
                accept="image/jpg, image/png, image/jpeg, image/gif"
                :on-success="data=>handleSuccess(data, null)"
                :on-error="handleFormatError"
                :action="$store.state.pageManageConfig.uploadUrl"
                >
                <div class="upload-box">
                    <img style="width: 100%;height:100%" v-if="data.mainUrl!==''" :src="$store.state.pageManageConfig.imghost+data.mainUrl" />
                    <div v-else class="normal">
                        <img :src="$store.state.pageManageConfig.staticImage.plusIcon" />
                        <div>点击上传图片</div>
                    </div>
                </div>
            </el-upload>
            <el-button style="margin-top:10px;" type="ghost" @click="data.mainUrl='';data.list=[]">删除图片</el-button>

            <p class="tip" style="margin-top: 30px">编辑热区</p>

            <draggable class="product-list" :list="data.list" :options="{animation: 300, handle:'.product-item'}">
                <transition-group name="bounce-out-left">
                    <div class="product-item" :key="item.uid" v-for="(item,index) in data.list">
                        <div class="box">

                            <div class="area-title">区域{{index+1}}</div>

                            <div class="area-box">
                                <div class="area" @click="handleAddArea(item, index)">
                                    <div v-if="!item.color">点击框选区域</div>
                                    <div v-else><span class="haveColor" :style="{background: item.color}"></span>区域</div>
                                </div>
                                <div class="clear" @click="item.color=null;item.areaData=null">
                                    <img :src="$store.state.pageManageConfig.staticImage.eraserIcon" />清除区域
                                </div>
                            </div>
							
							<div class="info">  <!-- @click="showModal(item)" -->
								<div class="txt">链接：</div>
								<el-dropdown trigger="click" placement="bottom-start" class="link-info" @command="handleCommand($event, item)">
									<div class="link-info" v-if="!item.selectedData.data">
										<div class="cont" style="background: transparent;color: #409eff;">请选择页面</div>
										<div class="icon" style="background: transparent;">
											<i class="el-icon-arrow-down" style="color: #409eff;font-size: 16px;background: transparent;"></i>
										</div>
									</div>
									<div class="link-info" v-else>
										<div class="cont">{{item.selectedData.text}}</div>
										<div class="icon" @click.stop="deleteLinkData(item)">
											<i class="el-icon-close" style="color: #fff;font-size: 16px;"></i>
										</div>
										<div class="icon">
											<i class="el-icon-arrow-down" style="color: #fff;font-size: 16px;"></i>
										</div>
									</div>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item command="1">选择小程序内页面</el-dropdown-item>
										<el-dropdown-item command="2">链接到其它小程序</el-dropdown-item>
										<el-dropdown-item command="3">进入小程序客服</el-dropdown-item>
										<!-- <el-dropdown-item command="4">链接至外部网站</el-dropdown-item> -->
										<el-dropdown-item command="5">邀请视频号小店分享员</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</div>

                            <!-- <div class="info">
                                <el-radio-group v-model="item.type">
                                    <el-radio :label="1" style="margin-right:35px;"><span style="font-size:14px;color: #666;">系统链接</span></el-radio>
                                    <el-radio :label="2"><span style="font-size:14px;color: #666;">自定义链接</span></el-radio>
                                </el-radio-group>

                                <div class="choose-box" style="margin-top:15px;" v-if="item.type === 1">
                                    <Button type="ghost" style="background:#fff" size="small" @click="showModal(item)">选择</Button>
                                    <span class="route" :title="item.selectedData ? item.selectedData.text : ''">{{item.selectedData ? item.selectedData.text : ''}}</span>
                                </div>
                                <div class="custom-link" style="margin: 10px 10px 0 0" v-if="item.type === 2">
                                    <Input v-model="item.link" placeholder="请输入链接地址"/>
                                </div>

                            </div> -->
                        </div>
                        <div class="control">
                            <span class="delete" @click="deleteData(item)">删除</span>
                        </div>
                    </div>
                </transition-group>
            </draggable>

            
            <div class="tip1">您已添加<span class="count">{{data.list.length}}</span>块区域</div>
            
            <div class="add-btn" @click="addHotData">
                <i class="el-icon-plus" style="margin-right: 5px;vertical-align: -2px;font-size: 18px;"></i>添加区域
            </div>


            <div class="mod-footer">
                <div class="left">
                    <el-checkbox v-model="data.hideSpace"><span style="font-size: 14px;color:#666">隐藏模块下方的间距</span></el-checkbox>
                </div>
                <!-- <div class="right">
                    <Button type="primary" @click="saveData">保存</Button>
                </div> -->
            </div>

            <link-modal :showLiveTab="true" :visible="shopVisible" @change="dataChange" @cancel="shopVisible=false"></link-modal>
			<reply-modal :visible="replyVisible" :data="editData" @change="dataChange" @cancel="replyVisible=false"></reply-modal>
			<ext-link :visible="extLinkVisible" :data="editData" @change="dataChange" @cancel="extLinkVisible=false"></ext-link>
			<mini-modal :visible="miniVisible" :data="editData" @change="dataChange" @cancel="miniVisible=false"></mini-modal>
			
        </div>
    <!-- </transition> -->
</template>

<script>

import linkModal from '@/views/components/linkModal';
import replyModal from '@/views/components/autoReplyModal.vue';
import extLink from '@/views/components/extLinkModal.vue';
import miniModal from '@/views/components/miniProgramModal.vue';
import draggable from 'vuedraggable';

export default {
    name: 'normal-module-edit',
    components: {
        linkModal,
		replyModal,
		extLink,
        draggable,
		miniModal
    },
    props: {
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        visible: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            shopVisible: false,
			replyVisible: false,
			extLinkVisible: false,
			miniVisible: false,
            editData: {}
        };
    },
    methods: {
        close(){
            this.$emit('close')
        },
		handleCommand(e, record){
			// console.log(e, record)
			this.editData = record;
			if (e == '1'){
				this.shopVisible = true;
			}else if (e == '2'){
				this.miniVisible = true;
			}else if (e == '3'){
				this.replyVisible = true;
			}else if (e == '4'){
				this.extLinkVisible = true;
			}else if (e == '5'){
                this.dataChange({
                    text:'邀请视频号小店分享员',
                    data:{},
                    type:'videoShare',
                })
            }
		},
        // showModal(record){
        //     this.editData = record;
        //     this.shopVisible = true;
        // },
        dataChange(record){
            // console.log(record)
            this.data.list.map(item=>{
                if (item.uid == this.editData.uid){
                    item.selectedData = record;
                }
                return item;
            })
			// console.log(this.data.list)
        },
        deleteData(record){
			
            this.data.list = this.data.list.filter(item=>{
                return item.uid !== record.uid;
            })

        },
		deleteLinkData(record){
			console.log(record, this.data.list)
			this.data.list = this.data.list.map(item=>{
			    if (item.uid == record.uid){
			        item.selectedData = {};
			    }
			    return item;
			})
		},
        handleMaxSize(){
            this.$Message.error('上传图片最大2M！');
        },
        handleFormatError(){
            this.$Message.error('上传失败！');
        },
        handleSuccess(data){

            this.data.mainUrl = data[0];

        },
        addHotData(){

            if (!this.data.mainUrl){
                this.$Message.error('请先上传图片');
                return;
            }

            this.data.list.push({
                uid: this.guid(),
                color: null,
                areaData: null,
                type: 1, //链接类型
                link: '', //自定义链接
				index: this.data.list.length,
                selectedData: {}, //弹框选中数据
            })
        },
        saveData(){
            this.$emit('submit')
        },
        handleAddArea(record, index){
            if (!this.data.list[index].color){
                this.data.list[index].color = this.getColor();
            }
			// this.data.list.canMove = true;
            this.$emit('move', record, index);
            
        },  
        guid(){
            var a=function(){return(65536*(1+Math.random())|0).toString(16).substring(1)};
            return a()+a()+"-"+a()+"-"+a()+"-"+a()+"-"+a()+a()+a()
        },
        getColor(){
            var color="#";
            var colorArr=["0","1","2","3","4","5","6","7","8","9","a","b","c","d","e","f"];
            for(var i=0;i<6;i++){
                var cur=randomNum(15,0);
                color+=colorArr[cur];
            }
            function randomNum(max,min){
                return Math.floor(Math.random()*(max-min+1)+min)
            }
            return color;
        }

    },
    mounted () {
        // this.data.list.push({
        //         uid: this.guid(),
        //         imgUrl: '/ShopDecorate/61f677cf-ce0d-4364-b3d8-9cdeffe089aa.jpg',
        //         type: 1, //链接类型
        //         link: '', //自定义链接
        //         selectedData: {}, //弹框选中数据
        //     })
		console.log(this.data)
    },
    watch: {
        data(value){
            //this.$set(this.data,'btnType',1)
            
            //this.data.btnType = 1;
            //console.log(value)
        }
    }
};
</script>

