<style scoped lang="less">
    .normal-module{
        img{
            display: block;
            width: 100%;
        }
        
    }
	.normal-name{
		transform: translateY(-28px);
	}
</style>

<template>
    <div class="normal-module">
		<div class="normal-name">
			<module-name :name="name"></module-name>
		</div>
        

        <controler @edit="edit" @moveUp="moveUp" @moveDown="moveDown" @remove="remove" right="-45px" :visible="true" :move="false" :del="false"></controler>
        <!-- <edit :data="data" :visible="data.visible" :name="name" @close="close"  /> -->

        <!-- <transition name="bounce-out-left">
            <img v-if="data.isShowShopName" :src="$store.state.pageManageConfig.staticImage.name" />
        </transition>
        <transition name="bounce-out-left">
            <img v-if="data.isShowAttentionWechat" :src="$store.state.pageManageConfig.staticImage.attention" />
        </transition> -->
    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';

export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        edit
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
    data () {
        return {
            visible: false,
            dataList: []
        };
    },
    methods: {
        edit(){
            this.$emit('visibleChange', !this.data.visible)
        },
        moveUp(){

        },
        moveDown(){
            
        },
        remove(){
            
        },
        close(){
            this.$emit('visibleChange', false)
        }

    },
    mounted () {
    },
    watch: {
        list(value){
            this.dataList = value;
        },
    }
};
</script>

