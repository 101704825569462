<style scoped lang="less">
	.tips{
	font-size: 12px;
	color: #999;
	padding: 10px 20px 0;
	
	a{
		color: #3e79ed;
	}
}
</style>
<template>
	<el-dialog :visible="visible" width='600px' title="链接外部网站" :append-to-body="true" @close="cancel">

		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="ruleForm">
			<el-form-item label="网站名称" prop="name">
				<el-input v-model="ruleForm.name" style="width: 350px;" placeholder="请输入外部网站名称"></el-input>
			</el-form-item>
			<el-form-item label="URL" prop="path">
				<el-input v-model="ruleForm.path" style="width: 350px;" placeholder="请输入外部网站网址"></el-input>
			</el-form-item>
		</el-form>

		<div class="tips">
			首次添加网站前，请联系客服添加网站域名，未添加域名将导致页面跳转失败
		</div>

		<div slot="footer">
			<el-button type="primary" @click="btnSure">确定</el-button>
		</div>

	</el-dialog>
</template>
<script>
	import config from '@/config/index'

	export default {
		components: {},
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			data: {
				type: Object,
				default: ()=>{
					return {}
				}
			}
		},
		watch: {
			data(obj){
				if (obj.selectedData && obj.selectedData.data){
					this.ruleForm = {
						name: obj.selectedData.data.name,
						path: obj.selectedData.data.path
					}
				}else{
					if (this.ruleForm.name){
						this.reset()
					}
				}
			}
		},
		data() {
			return {
				show: false,
				ruleForm: {
					name: '',
					path: ''
				},
				rules: {
					name: [{
						required: true,
						message: '请输入外部网站名称',
						trigger: 'change'
					}],
					path: [{
						required: true,
						message: '请输入外部网站网址',
						trigger: 'change'
					}],
				}
			};
		},
		methods: {
			cancel() {
				this.$emit('cancel');
			},
			btnSure() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.$emit('change', {
							type: 'extLink',
							text: '外部网站|'+this.ruleForm.name,
							data: JSON.parse(JSON.stringify(this.ruleForm))
						})
						this.$refs['ruleForm'].resetFields();
						this.cancel();
					} else {
						return false;
					}
				});
			},
			reset() {
				this.$refs['ruleForm'].resetFields();
			}
		},
		
		mounted() {

		},
		created() {

		},
		dispatch() {

		}
	};
</script>
