<style scoped lang="less">
    .singleRow-module-edit{
        position: absolute;
        right: -530px;
        width: 480px;
        padding: 20px 20px 30px;
        background: #fff;
        font-size: 14px;

        .title{
            position: relative;
            font-size: 18px;
            padding-bottom: 20px;
            margin-bottom: 25px;
            border-bottom: 1px solid #ddd;
        }

        .icon-close{
            position: absolute;
            right: 0;
            cursor: pointer;
        }

        .tip{
            margin-bottom: 20px;
        }

        .product-list{
            .product-item{
                display: flex;
                margin-bottom: 20px;
            }
            .box{
                display: flex;
                flex: 1 1 auto;
                background: #f5f5f5;

                .img{
                    position: relative;
                    width: 90px;
                    height: 90px;
                    overflow: hidden;
                    flex: 0 0 auto;

                    img{
                        display: block;
                        width: 100%;
                    }
                    .change{
                        position: absolute;
                        width: 100%;
                        left:0;
                        bottom: 0;
                        height: 26px;
                        line-height: 26px;
                        text-align: center;
                        color: #fff;
                        background: rgba(0,0,0,.5);
                    }
                }
                .info{
                    flex: 1 1 auto;
                    margin-left: 10px;
                    margin-right: 20px;

                    .name{
                        height: 36px;
                        line-height: 18px;
                        margin: 10px 0;
                        color: #666;

                        display: -webkit-box; 
                        -webkit-box-orient:vertical; 
                        -webkit-line-clamp: 2; 
                        overflow: hidden;
                    }

                    .price{
                        font-size: 16px;
                        display: flex;
                        justify-content: space-between;

                        .old{
                            font-size: 14px;
                            color: #666;
                        }
                    }
                }

            }
            .control{
                flex: 0 0 auto;
                width: 45px;
                text-align: right;
                margin-top: 20px;

                span{
                    display: inline-block;
                    cursor: pointer;
                }
                .edit{
                    color: #1890ff
                }
                .delete{
                    color: #f00000;
                    margin-top: 10px;
                }
            }
        }

        .tip1{
            text-align: center;
            color: #999;

            .count{
                color: #1890ff;
            }
        }

        .add-btn{
            text-align: center;
            border: 1px dashed #ddd;
            font-size: 16px;
            color: #666;
            height: 48px;
            line-height: 48px;
            text-align: center;
            margin-top: 10px;
            cursor: pointer;
        }

        .tip2{
            margin-top: 30px;
            margin-bottom: 10px;
        }

        .mod-footer{
            margin-top: 50px;
            padding-top: 20px;
            border-top: 1px solid #ddd;

            display: flex;

            .left{
                flex: 1 1 auto;
                padding-top: 6px;
            }
        }


    }

    
</style>

<template>
    <transition name="scale">
        <div class="singleRow-module-edit" v-if="visible">
            
            <p class="title">
                {{name}} 
                <Icon  @click="close" type="ios-close-empty" size=32 class="icon-close" color="#999"></Icon>
            </p>

            <p class="tip">选择活动（建议添加6个）</p>

            <draggable class="product-list" :list="data.list" :options="{animation: 300, handle:'.product-item'}">
                <transition-group name="bounce-out-left">
                    <div class="product-item" :key="item.Id" v-for="item in data.list">
                        <div class="box">
                            <div class="img">
                                <Upload
                                    ref="upload"
                                    :show-upload-list="false"
                                    :on-success="(data)=>{handleSuccess(data, item)}"
                                    :format="['jpg','jpeg','png']"
                                    :max-size="2048"
                                    :on-format-error="handleFormatError"
                                    :on-exceeded-size="handleMaxSize"
                                    :action="$store.state.pageManageConfig.uploadUrl"
                                    style="">
                                    <img :src="$store.state.pageManageConfig.imghost+item.ImgUrl" />
                                    <p class="change">更改图片</p>
                                </Upload>
                            </div>
                            <div class="info">
                                <p class="name">{{item.Name}}</p>
                                <div class="price">
                                    <span>团购价：￥{{item.MaxGroupBuyingPrice}}</span>
                                    <span class="old">{{item.GroupCount}}人成团</span>
                                </div>
                            </div>
                        </div>
                        <div class="control">
                            <span class="edit" @click="changeData(item)">修改</span>
                            <span class="delete" @click="deleteData(item)">删除</span>
                        </div>
                    </div>
                </transition-group>
            </draggable>

            <p class="tip1">您已添加<span class="count">{{data.list.length}}</span>个活动</p>

            <div class="add-btn" @click="showModal">
                <Icon type="ios-plus-outline" style="margin-right: 5px;vertical-align: -2px;" size=18></Icon>添加活动
            </div>

            <p class="tip2">按钮样式</p>
            <RadioGroup v-model="data.style">
                <Radio :label="1"><span style="font-size: 14px;color:#666">样式一</span></Radio>
                <Radio :label="2"><span style="font-size: 14px;color:#666">样式二</span></Radio>
                <Radio :label="3"><span style="font-size: 14px;color:#666">样式三</span></Radio>
            </RadioGroup>

            <div class="mod-footer">
                <div class="left">
                    <Checkbox v-model="data.hideSpace"><span style="font-size: 14px;color:#666">隐藏模块下方的间距</span></Checkbox>
                </div>
                <div class="right">
                    <Button type="primary" @click="saveData">保存</Button>
                </div>
            </div>

            <groupBuyingModal :visible="shopVisible" :disableList="data.list" :isSingle="isSingle" @change="dataChange" @cancel="shopVisible=false"></groupBuyingModal>

        </div>
    </transition>
</template>

<script>

import groupBuyingModal from '@/views/components/groupBuyingModal';

import draggable from 'vuedraggable';

export default {
    name: 'normal-module-edit',
    components: {
        groupBuyingModal,
        draggable
    },
    props: {
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        visible: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            shopVisible: false,
            isSingle: false, //是否是单选
            editData: {}
        };
    },
    methods: {
        close(){
            this.$emit('close')
        },
        showModal(){
            this.shopVisible = true;
            this.isSingle = false;
        },
        dataChange(record){

            if (!this.isSingle){ //添加
                this.addData(record);
            }else{ //修改
                this.changeRecordData(record);
            }
            
        },

        //添加产品，并且过滤掉相同产品
        addData(record){
            const list = this.data.list || [];
            record.map(item =>{
                let like = false;
                list.map(item1=>{
                    if (item1.Id === item.Id){
                        like = true;
                    }
                })

                if (!like){
                    list.push(item);
                }

            })

            this.data.list.concat(list);
        },

        //修改产品， 覆盖掉需替换的产品
        changeRecordData(record){

            this.data.list.map(item=>{
                if (item.Id === this.editData.Id){
                    for (var i in record){
                        item[i] = record[i]
                    }
                }
                return item;
            })

        },
        deleteData(record){

            this.data.list = this.data.list.filter(item=>{
                return item.Id !== record.Id;
            })

        },
        changeData(record){

            this.shopVisible = true;
            this.isSingle = true;

            this.editData = record;

        },
        handleMaxSize(){
            this.$Message.error('上传图片最大2M！');
        },
        handleFormatError(){
            this.$Message.error('上传失败！');
        },
        handleSuccess(data, record){
            this.data.list.map(item=>{
                if (item.Id === record.Id){
                    item.ImgUrl = data[0];
                    item.isSelfUpload = true;
                }
                return item;
            })
        },
        saveData(){
            this.$emit('submit')
        }

    },
    mounted () {

    },
    watch: {
        data(value){
            //this.$set(this.data,'btnType',1)
            
            //this.data.btnType = 1;
            //console.log(value)
        }
    }
};
</script>

