<template>
  <div class="box" @mouseenter="data.visible=true" @mouseleave="data.visible=false"  :class="{haveSpace: !data.hideSpace}">
    <controler @edit="edit();data.editVisible=true" :move="true" @moveUp="$emit('moveUp')" :visible="data.visible"
      @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>

    <div class="content" v-if="data.styleType==0">
      <div class="frame">
        <div class="mallname">{{ShopName}}的公众号</div>
        <div class="main">
          <div class="main-left">
            <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/images/melyshop/OfficialAccounts-icon.png" alt="">
            <div>
              <div class="name">公众号名称</div>
              <div class="language">{{data.guideLanguage||'关注公众号引导语'}}</div>
            </div>
          </div>
          <div class="button">关注</div>
        </div>
      </div>
    </div>
    <div v-else :class="data.styleType==1&&!data.img?'defaultBgc':''">
      <img v-if="data.img" :src="imgUrl+data.img" style="width:100%;height:100%" alt="">
    </div>
    
		<div class="cover" v-if="data.visible" style="position: absolute;left:40px;top:0;right: 45px;height: 100%;border: 3px solid #0000FF;"></div>
  </div>
</template>

<script>
import controler from '../../controler';
import config from '@/config/index';
import { mapGetters } from 'vuex';
export default {
  components: {
    controler
  },
  props: {
      name: {
          type: String,
          default: ''
      },
      data: {
          type: Object,
          default: ()=>{
              return {}
          }
      },
  },
  computed: {
    ...mapGetters([
      'ShopName'
    ])
  },
  data () {
    return {
      imgUrl: config.IMG_BASE,
    }
  },
  methods:{
    edit(){
        this.$emit('visibleChange', this.data.visible)
    },
  }
}
</script>

<style lang="less" scoped>
.box{
  position: relative;
  padding-left: 40px;
  padding-right: 45px;
  .content{
    // border:1px solid black;
    padding: 10px;
    display: flex;
    align-items: center;
    overflow: hidden;
    background: white;
    .frame{
      border: 1px solid #E9E9E9;
      padding: 5px 10px;
      width:100%;
      .mallname{
        font-size:16px;
        transform: scale(.5);
        color: #B3B3B3FF;
        position: relative;
        left: -80px;
      }
      .main{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .main-left{
          display: flex;
          align-items: center;
          gap:10px;
          img{
            width:60px;
            height: 60px;
            border-radius: 50%;
          }
          .name{
            font-size: 14px;
            color: #333333;
            font-weight: bold;
            line-height: 20px;
          }
          .language{
            color: #666666;
            font-size: 12px;
            line-height: 18px;
          }
        }
        .button{
          background: #FFFFFF;
          border-radius: 2px 2px 2px 2px;
          border: 1px solid #5A9D47;
          padding: 4px 17px;
          color: #5A9D47;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
  .defaultBgc{
    height: 112px;
    background: rgb(234,237,241);
    box-sizing: border-box;
  }
}

.haveSpace{
    margin-bottom: 10px;
}
</style>