<style scoped lang="less">

    .module-content{
        
        .picture-list{

            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;

            .img{
                width: 174px;
                height: 174px;
                margin-bottom: 8px;
                border: 1px solid #ddd;
                overflow: hidden;
                background:#fff;

                &:nth-last-child(-n+2){
                    margin-bottom: 0
                }

                img{
                    display: block;
                    width: 100%;
                }
            }
            
        }

        .picture-list-1{

            display: flex;
            justify-content: space-evenly;

            .img{
                width: 174px;
                height: 174px;
                border: 1px solid #ddd;
                overflow: hidden;
                background:#fff;

                display: flex;
                flex-direction: column;
                justify-content: space-between;
                
                img{
                    display: block;
                    width: 100%;
                }

                .img1{
                    height: 48%;
                    background:#fff;
                    border: 1px solid #ddd;

                    img{
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }

                &:nth-child(n+4){
                    display: none;
                }
            }



            
            
        }
        
    }
    .haveSpace{
        margin-bottom: 20px;
    }
    
</style>

<template>
    <div class="module-content"  :class="{haveSpace: !data.hideSpace}">
        <module-name :name="name"></module-name>
        <controler @edit="edit();data.editVisible=!data.editVisible" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        <edit :data="data" @submit="$emit('submit')" :visible="data.editVisible" :name="name" @close="data.editVisible=false"  />
        
        <div @click="edit();data.visible=true">
            <div class="picture-list" v-if="!data.list.length&&data.style===1">
                <div class="img" :key="i" v-for="i in 2">
                    <img :src="$store.state.pageManageConfig.staticImage.noPic" />
                </div>
            </div>
            <div class="picture-list-1" v-if="!data.list.length&&data.style===2">
                <div class="img">
                    <img :src="$store.state.pageManageConfig.staticImage.noPic" />
                </div>
                <div class="img" style="border:none">
                    <div class="img1" :key="i" v-for="i in 2">
                        <img :src="$store.state.pageManageConfig.staticImage.noPic1" />
                    </div>
                </div>
            </div>


            <div class="picture-list" v-if="data.list.length&&data.style===1">
                <div class="img" :key="index" v-for="(item, index) in data.list">
                    <img :src="$store.state.pageManageConfig.imghost+item.imgUrl" />
                </div>
                <div class="img" v-if="data.list.length%2!==0" style="border:none;background:transparent">
                </div>
            </div>

            <div class="picture-list-1" v-if="data.list.length&&data.style===2">
                <div class="img">
                    <img :src="data.list[0]? $store.state.pageManageConfig.imghost+data.list[0].imgUrl : $store.state.pageManageConfig.staticImage.noPic" />
                </div>
                <div class="img" style="border:none;background:transparent">
                    <div class="img1" :key="i" v-for="i in 2">
                        <img :src="data.list[i] ? $store.state.pageManageConfig.imghost+data.list[i].imgUrl : $store.state.pageManageConfig.staticImage.noPic1" />
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';

export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        edit
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
    data () {
        return {
        };
    },
    methods: {
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        close(){
            this.$emit('visibleChange', false)
        }

    },
    mounted () {
    },
    watch: {
        
    }
};
</script>

