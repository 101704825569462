<style scoped lang="less">
.singleRow-module-edit {
	// position: absolute;
	// right: -530px;
	width: 480px;
	padding: 20px 20px 30px;
	background: #fff;
	font-size: 14px;

	.title {
		position: relative;
		font-size: 18px;
		padding-bottom: 20px;
		margin-bottom: 25px;
		border-bottom: 1px solid #ddd;
	}

	.add-btn {
		text-align: center;
		border: 1px dashed #ddd;
		font-size: 16px;
		color: #666;
		height: 48px;
		line-height: 48px;
		text-align: center;
		margin-bottom: 18px;
		cursor: pointer;
		width: 440px;
	}

	.product-list {
		padding-top: 10px;
		overflow: hidden;

		.product-item {
			position: relative;
			float: left;
			width: 65px;
			height: 65px;
			margin-right: 10px;
			margin-bottom: 10px;
		}

		.img {
			position: relative;
			width: 65px;
			height: 65px;
			overflow: hidden;

			img {
				display: block;
				width: 100%;
			}
		}

		.control {
			position: absolute;
			top: -10px;
			right: -10px;
		}
	}

	.link-info {
		width: 440px;
		height: 28px;
		line-height: 28px;
		background: #409eff;
		padding: 0 5px;
		margin-bottom: 10px;
		color: #fff;
		font-size: 14px;

		display: flex;

		.cont {
			flex: 1 1 auto;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.icon {
			flex: 0 0 auto;
		}
	}
	
	.activity-tips{
		color: #999;
		font-size: 14px;
	}

	.mod-footer {
		margin-top: 50px;
		padding-top: 20px;
		border-top: 1px solid #ddd;

		display: flex;

		.left {
			flex: 1 1 auto;
			padding-top: 6px;
		}
	}
}
</style>

<template>
	<!-- <transition name="scale"> -->
	<div class="singleRow-module-edit" v-if="visible">
		<p class="title">
			{{ name }}
			<!-- <Icon  @click="close" type="ios-close-empty" size=32 class="icon-close" color="#999"></Icon> -->
		</p>

		<el-form label-width="70px" size="mini">
			<el-form-item label="列表展示">
				<el-radio-group v-model="data.type">
					<el-radio style="width: 80px;" :label="1">单场活动</el-radio>
					<el-radio style="width: 80px;" :label="2">多场活动</el-radio>
				</el-radio-group>
			</el-form-item>
		</el-form>

		<div class="add-btn" @click="showActivityModal" v-if="showActivityBtn">
			<i class="el-icon-circle-plus-outline" style="margin-right: 5px;vertical-align: -2px;font-size: 18px;"></i>
			选择助力领取活动
		</div>
		
		<div class="activity-tips" v-if="data.type == 2">
			默认根据活动开始时间正序排序，若开始时间相同则根据创建时间正序排序；最多添加10场助力领取活动。
		</div>

		<div v-if="!data.type || data.type == 1">
			<div v-if="data.activityData.Id">
				<div class="classify-list">
					<div class="link-info">
						<div class="cont" @click="showActivityModal">{{ data.activityData.Name }}</div>
						<div class="icon" @click="deleteData()"><i class="el-icon-close" style="color: #fff;font-size: 16px;"></i></div>
					</div>
				</div>

				<div class="tit" style="color: #666;padding: 20px 0 10px;">活动商品</div>
				<div v-if="data.list && data.list.length">
					<draggable class="product-list" :list="data.list" :options="{ animation: 300, handle: '.product-item' }">
						<transition-group name="bounce-out-left">
							<div class="product-item" :key="item.ProductId" v-for="item in data.list">
								<div class="img"><img :src="$store.state.pageManageConfig.imghost + item.ImgUrl" /></div>
								<div class="control" @click="deleteProduct(item)"><i class="el-icon-error" style="font-size: 24px;opacity: .6;"></i></div>
							</div>
						</transition-group>
					</draggable>
				</div>
				<div v-else><div style="text-align: center;color: #999;padding-bottom: 30px;">暂无商品</div></div>
				<div class="add-btn" @click="showShopModal(false)">
					<i class="el-icon-circle-plus-outline" style="margin-right: 5px;vertical-align: -2px;font-size: 18px;"></i>
					选择商品
				</div>
			</div>
			
		</div>
		<div v-else>
			<div v-for="(item, index) in data.activitys" :key="item.Id">
				<item :name="'活动' + (index + 1)" :data="item" @expand="(bol)=>{handleExpand(item, bol)}" @selectShop="showShopModal(item)" @deleteProduct="(record)=>{mulitDeleteProduct(item, record)}" @deleteActivity="mulitDeleteActivity"></item>
			</div>
		</div>

		<div class="mod-footer">
			<div class="left">
				<el-checkbox v-model="data.hideSpace"><span style="font-size: 14px;color:#666">隐藏模块下方的间距</span></el-checkbox>
			</div>
		</div>

		<shop-modal :visible="shopVisible" :selectedList="selectedList" :activityId="selectedData.Id" @change="dataChange" @cancel="shopVisible = false"></shop-modal>
		<activityModal :visible="activityVisible" :selected="data.activityData" @change="activityChange" @cancel="activityVisible = false"></activityModal>
		<mulitActivityModal :visible="mulitActivityVisible" :selected="data.activitys" @change="mulitActivityChange" @cancel="mulitActivityVisible = false"></mulitActivityModal>
	</div>
	<!-- </transition> -->
</template>

<script>
import activityModal from '../../../../components/helpActivityModal.vue';
import mulitActivityModal from '../../../../components/mulitHelpActivityModal.vue';
import shopModal from '../../../../components/helpActivityShopListModal.vue';
import item from './item.vue';

import { editSelectHelpReceiveProduct } from '@/api/pageManage.js';

import draggable from 'vuedraggable';

export default {
	name: 'flashSale-edit',
	components: {
		activityModal,
		mulitActivityModal,
		shopModal,
		draggable,
		item
	},
	props: {
		data: {
			type: Object,
			default: () => {
				return {};
			}
		},
		visible: {
			type: Boolean,
			default: false
		},
		name: {
			type: String,
			default: ''
		}
	},
	computed: {
		showActivityBtn() {
			//单场并且没有选过活动 或 多场： 显示

			if ((!this.data.type || this.data.type == 1) && !this.data.activityData.Id) {
				return true;
			} else if (this.data.type == 2) {
				return true;
			}

			return false;
		}
	},
	data() {
		return {
			activityVisible: false,
			mulitActivityVisible: false,
			shopVisible: false,
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0,
			selectedData: {},
			selectedList: []
		};
	},
	methods: {
		close() {
			this.$emit('close');
		},
		saveData() {
			this.$emit('submit');
		},
		showShopModal(activity) {
			console.log(activity)
			if (activity){
				this.selectedData = activity;
				this.selectedList = activity.list;
				this.isSingle = false;
			}else{
				this.selectedData = this.data.activityData;
				this.selectedList = this.data.list;
				this.isSingle = true;
				console.log(this.selectedData)
			}
			this.shopVisible = true;
		},
		showActivityModal() {
			if (this.data.type == 2) {
				this.mulitActivityVisible = true;
			} else {
				this.activityVisible = true;
			}
		},
		activityChange(record) {
			this.data.activityData = record[0];
			this.data.list = [];
			this.getShopList();
			if (this.data.activityData){
				this.getTime(this.data.activityData);
			}
			
		},
		mulitActivityChange(record) {
			// console.log(record)
			// var haveExpand = false;
			record = record.sort((a,b)=>{
				return a.Id-b.Id;
			})
			
			record = this.filterList(record);
			
			record.map(item => {
				item.list = [];
				item.expand = false;
				this.data.activitys.map(obj => {
					if (obj.Id == item.Id) {
						item.list = obj.list;
						// item.expand = obj.expand
					}
				});
				
				item.dateStr = this.getDateStr(item);
				
				var date = this.splitDate(item.StartTime);
				item.timeStr = [date.hour, date.minute].join(':');
				
				
				// if (item.expand){
				// 	haveExpand = true;
				// }
				
				return item;
			});
			
			if (!this.data.activitys.length){
				record[0].expand = true;
			}

			this.data.activitys = record;
			
			if (record.length){
				this.getMulitShopList();
				this.getTime(this.data.activitys[0]);
			}
			
		},
		dataChange(record) {
			// console.log(record);
			// return;
			
			record.map(item => {
				item.price = +(item.ProductPrice * (item.Discount / 100)).toFixed(2);
				var discount = +(item.Discount / 10).toFixed(1);
				if (discount <= 0 || discount >= 10) {
					item.DiscountText = '限时折扣';
				} else {
					item.DiscountText = +(item.Discount / 10).toFixed(1) + '折';
				}
				return item;
			});
			
			if (this.isSingle){
				this.data.list = record;
			}else{
				this.data.activitys.map(item=>{
					if (item.Id == this.selectedData.Id){
						item.list = record;
					}
					return item;
				})
			}
		},
		deleteData() {
			this.data.activityData = {};
			this.data.list = [];
		},
		deleteProduct(record) {
			this.data.list = this.data.list.filter(item => {
				return item.ProductId != record.ProductId;
			});
		},
		async getShopList(type) {
			const params = {
				ActivityId: this.data.activityData.Id
			};

			const res = await editSelectHelpReceiveProduct(params);

			if (res.IsSuccess) {
				var list = res.Result.map(item => {
					// item.MinDiscountPrice = +item.MinDiscountPrice.toFixed(2);
					// item.MaxDiscountPrice = +item.MaxDiscountPrice.toFixed(2);
					// item.price = +(item.ProductPrice * (item.Discount / 100)).toFixed(2);

					// var discount = +(item.Discount / 10).toFixed(1);
					// if (discount <= 0 || discount >= 10) {
					// 	item.DiscountText = '限时折扣';
					// } else {
					// 	item.DiscountText = +(item.Discount / 10).toFixed(1) + '折';
					// }

					return item;
				});

				if (type) {
					var array = [];
					this.data.list.map(item => {
						list.map(obj => {
							if (obj.ProductId == item.ProductId) {
								array.push(obj);
							}
						});
					});
					this.data.list = array;
				} else {
					this.data.list = list;
				}
			}
		},
		async getMulitShopList(type) {
			// console.log(this.data.activitys)

			const params = {
				ActivityIdList: this.data.activitys.map(item => {
					return item.Id;
				})
			};

			const res = await editSelectHelpReceiveProduct(params);

			if (res.IsSuccess) {
				var list = JSON.parse(JSON.stringify(this.data.activitys));
				
				if (!type){
					list.map(obj => {
						if (!obj.list.length){
							res.Result.map(item => {
								if (obj.Id == item.ActivityId) {
							// 		item.MinDiscountPrice = +item.MinDiscountPrice.toFixed(2);
							// 		item.MaxDiscountPrice = +item.MaxDiscountPrice.toFixed(2);
							// 		item.price = +(item.ProductPrice * (item.Discount / 100)).toFixed(2);
							
							// 		var discount = +(item.Discount / 10).toFixed(1);
							// 		if (discount <= 0 || discount >= 10) {
							// 			item.DiscountText = '限时折扣';
							// 		} else {
							// 			item.DiscountText = +(item.Discount / 10).toFixed(1) + '折';
							// 		}
							
									obj.list.push(item);
								}
							});
						}
					});
				}else{
					list.map(obj => {
						var ids = (obj.list || []).map(item=>{
							return item.ProductId || item;
						})
						var array = [];
						res.Result.map(item => {
							if (obj.Id == item.ActivityId && ids.indexOf(item.ProductId)>-1) {
						// 		item.MinDiscountPrice = +item.MinDiscountPrice.toFixed(2);
						// 		item.MaxDiscountPrice = +item.MaxDiscountPrice.toFixed(2);
						// 		item.price = +(item.ProductPrice * (item.Discount / 100)).toFixed(2);
						
						// 		var discount = +(item.Discount / 10).toFixed(1);
						// 		if (discount <= 0 || discount >= 10) {
						// 			item.DiscountText = '限时折扣';
						// 		} else {
						// 			item.DiscountText = +(item.Discount / 10).toFixed(1) + '折';
						// 		}
						
								array.push(item);
							}
						});
						
						obj.list = array;
						
					});
				}
				
				
				this.data.activitys = list;
				this.$forceUpdate();
			}
		},
		mulitDeleteProduct(activity, product){
			console.log(activity, product)
			var list = JSON.parse(JSON.stringify(this.data.activitys));
			list.map(item=>{
				if (item.Id == activity.Id){
					item.list = item.list.filter(obj=>{
						return obj.ProductId != product.ProductId;
					})
				}
				return item;
			})
			
			list = list.filter(item=>{
				return item.list.length > 0
			})
			
			this.data.activitys = list;
			
		},
		mulitDeleteActivity(activity){
			var list = JSON.parse(JSON.stringify(this.data.activitys));
			list = list.filter(item=>{
				return item.Id != activity.Id
			})
			
			this.data.activitys = list;
		},
		handleExpand(activity, bol){
			console.log(bol)
			var list = JSON.parse(JSON.stringify(this.data.activitys));
			list.map(item=>{
				if (!bol){
					item.expand = false;
				}else{
					item.expand = item.Id == activity.Id
				}
				return item;
			})
			
			this.data.activitys = list;
		},
		
		filterList(array){
			var list = JSON.parse(JSON.stringify(array));
			list.map(item=>{
				var obj = this.splitDate(item.StartTime);
				item.time = new Date(obj.year, obj.month-1, obj.day, obj.hour, obj.minute, obj.second).getTime();
				return item;
			})
			
			list.sort((a, b)=>{
				return a.time-b.time
			})
			
			return list;
			
			
		},
		
		getDateStr(activity){
			var str = ''
			if (activity.ActivityState == 1){
				str = '抢购中'
			}else if (activity.ActivityState == 2){
				str = '已结束'
			}else{
				var activityDate = this.splitDate(activity.StartTime);
				var nowDate = this.splitDate();
				console.log(activityDate, nowDate, 'nowDate')
				if (activityDate.year == nowDate.year && activityDate.month == nowDate.month && activityDate.day == nowDate.day){
					str = '即将开始'
				}else if (new Date(activityDate.year, (+activityDate.month)-1, activityDate.day).getTime() == new Date(nowDate.year, (+nowDate.month)-1, nowDate.day+1).getTime()){
					str = '明日开抢'
				}else{
					str = [activityDate.month, activityDate.day].join('-')+'开抢'
				}
			}
			return str
		},
		getTime(activity) {
			var timeStr = 0;
			if (activity.ActivityState === 0) {
				timeStr = activity.StartTime;
			} else if (activity.ActivityState === 1) {
				timeStr = activity.EndTime;
			}
			if (timeStr) {
				var split = timeStr.split(' ');
				var year = +split[0].split('-')[0];
				var month = +split[0].split('-')[1] - 1;
				var day = +split[0].split('-')[2];
				var hour = +split[1].split(':')[0];
				var minute = +split[1].split(':')[1];
				var second = +split[1].split(':')[2];

				var time = 0;
				// if (this.data.activityData.ActivityState === 0){
				// 	time = new Date().getTime() - new Date(year, month, day, hour, minute, second).getTime();
				// }else{
				time = new Date(year, month, day, hour, minute, second).getTime() - new Date().getTime();
				// }

				var mss = time;
				var days = parseInt(mss / (1000 * 60 * 60 * 24));
				var hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
				var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
				var seconds = parseInt((mss % (1000 * 60)) / 1000);

				this.data.activityData.days = days;
				this.data.activityData.hours = hours < 10 ? '0' + hours : hours;
				this.data.activityData.minutes = minutes < 10 ? '0' + minutes : minutes;
				this.data.activityData.seconds = seconds < 10 ? '0' + seconds : seconds;

				console.log(this.data.activityData);
			}
		},
		splitDate(date){
			var year,month,day,hour,minute,second;
			if (date){
				var split = date.split(' ');
				year = +split[0].split('-')[0];
				month = +split[0].split('-')[1];
				day = +split[0].split('-')[2];
				hour = +split[1].split(':')[0];
				minute = +split[1].split(':')[1];
				second = +split[1].split(':')[2];
			}else{
				var d = new Date();
				year = d.getFullYear();
				month = d.getMonth()+1;
				day = d.getDate();
				hour = d.getHours();
				minute = d.getMinutes();
				second = d.getSeconds();
			}
			
			month = month>9?month:('0'+month)
			day = day>9?day:('0'+day)
			hour = hour>9?hour:('0'+hour)
			minute = minute>9?minute:('0'+minute)
			second = second>9?second:('0'+second)
			
			return {
				year, month, day, hour, minute, second
			}
		},
		
	},
	mounted() {
		console.log(this.data, '限时折扣111');
		if (this.data.type != 2 && this.data.activityData.Id) {
			this.getTime(this.data.activityData);
			// this.data.list = this.data.list;
			this.getShopList('filter');
		}else if (this.data.type == 2 && this.data.activitys.length){
			var list = JSON.parse(JSON.stringify(this.data.activitys));
			list = list.sort((a,b)=>{
				return a.Id-b.Id;
			})
			list = this.filterList(list);
			list.map(item => {
				item.expand = false;
				item.dateStr = this.getDateStr(item);
				
				var date = this.splitDate(item.StartTime);
				item.timeStr = [date.hour, date.minute].join(':');

				return item;
			});
			
			if (!list.length){
				list[0].expand = true;
			}
			
			this.data.activitys = list;
			
			this.getTime(this.data.activitys[0]);
			this.getMulitShopList('filter')
		}
	},
	watch: {
		data(value) {
			//this.$set(this.data,'btnType',1)
			//this.data.btnType = 1;
			//console.log(value)
		}
	}
};
</script>
