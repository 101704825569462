<style scoped lang="less">

    .singleRowProduct-module{
        
        .product-list{

            img{
                display: block;
                width: 100%;
            }
            
        }
        
    }
    .haveSpace{
        margin-bottom: 20px;
    }
    
</style>

<template>
    <div class="singleRowProduct-module"  :class="{haveSpace: !data.hideSpace}">
        <module-name :name="name"></module-name>
        <controler @edit="edit();data.editVisible=!data.editVisible" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        <edit :data="data" @submit="$emit('submit')" :visible="data.editVisible" :name="name" @close="data.editVisible=false"  />
        
        <div  @click="edit();data.visible=true">
            <div class="product-list" v-if="!data.list.length">
                <img :src="$store.state.pageManageConfig.staticImage.noSlider" />
            </div>
            <div class="product-list" v-else>
                <swiper :options="swiperOption" ref="mySwiper">
                    <swiper-slide :key="index" v-for="(item,index) in data.list">
                        <img :src="$store.state.pageManageConfig.imghost+item.imgUrl" />
                    </swiper-slide>
                </swiper>
                <div class="swiper-pagination" slot="pagination"></div>
            </div>
        </div>


    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';

import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        edit,
        swiper,
        swiperSlide
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
    data () {
        return {
            swiperOption: {
                autoplay: true,
                pagination: {
                    el: '.swiper-pagination'
                }
            },
        };
    },
    methods: {
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        
        close(){
            this.$emit('visibleChange', false)
        }

    },
    mounted () {
    },
    watch: {
        
    }
};
</script>

