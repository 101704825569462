<style scoped lang="less">
	.singleRow-module-edit{
        padding: 20px 20px 30px;
        background: #fff;
        font-size: 14px;
		
		.title{
		    position: relative;
		    font-size: 18px;
		    padding-bottom: 20px;
		    margin-bottom: 25px;
		    border-bottom: 1px solid #ddd;
		}
		
		.tip{
			font-size: 12px;
			color: #999;
			width: 370px;
			line-height: 18px;
		}
		
		.add-btn {
			text-align: center;
			border: 1px dashed #ddd;
			font-size: 16px;
			color: #666;
			height: 48px;
			line-height: 48px;
			text-align: center;
			margin-bottom: 18px;
			cursor: pointer;
			width: 100%;
			margin-top: 10px;
		}
		
		.demo{
      width: 100%;
      .content{
        background: #F5F5F5;
        padding: 10px;
        img{
          width: 70px;
          height: 70px;
        }
      }
    }
    .upload-class{
      ::v-deep .el-upload{
        width: 100%;
      }
    }
	}
	
	.mod-footer{
	    margin-top: 30px;
	    padding-top: 20px;
	    border-top: 1px solid #ddd;
	
	    display: flex;
	
	    .left{
	        flex: 1 1 auto;
	        padding-top: 6px;
	    }
	}

  .blue-box{
    border: 1px solid #409EFF;
    padding: 6px 10px;
  }

    
</style>

<template>
	<!-- <transition name="scale"> -->
	<div class="singleRow-module-edit" v-if="visible">

		<p class="title">
			{{name}}
			<Icon @click="close" type="ios-close-empty" size=32 class="icon-close" color="#999"></Icon>
		</p>

    <div>
      <div class="">
        <div class="font-14 color-606266">最多添加10个营销导航，拖动已添加导航可对其排序</div>
        <draggable class="margin-top-10" :list="data.list" :options="{animation: 300, handle:'.demo'}">
          <div class="demo flex flex-align-center gap-10 margin-top-10" v-for="(item,i) in data.list" :key="i">
            <div class="content flex-1 flex flex-align-center gap-10">
              <img :src="imgApi+item.img" alt="">
              <div class="flex-1">
                <div class="flex flex-align-center">
                  <div style="width:50px;flex-shrink:0">标题：</div>
                  <el-input v-model="item.title" maxlength="10"></el-input>
                </div>
                <div class="flex flex-align-center margin-top-10">
                  <div style="width:50px;flex-shrink:0">链接：</div>
                  <el-dropdown trigger="click" @command="handleCommand">
                    <el-button type="text">
                      {{item.linkId?item.linkName:'请选择链接频道'}}
                      <i class="el-icon-arrow-down el-icon--right"></i>
                      <i class="el-icon-close margin-left-5" v-if="item.linkId" @click.stop="clearLabel(i)"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-for="(x,y) in select" :key="y" :command="{i:i,y:y}">{{x.label}}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
            <div class="flex flex-column">
              <div>
                
                <el-upload ref="upload" :show-file-list="false" accept="image/jpg, image/png, image/jpeg, image/gif"
                 :on-success="(data)=>handleSuccess(data, i)" :before-upload="beforeUpload"
                 :action="$store.state.pageManageConfig.uploadUrl">
                  <el-button type="text">修改</el-button>
                </el-upload>
              </div>
              <div>
                <el-button type="text" style="color:#f56c6c" @click="del(i)">删除</el-button>
              </div>
            </div>
          </div>
        </draggable>
      </div>
      <div class="font-14 color-999999 margin-top-20" style="text-align:center">
        您已添加 <span class="color-409eff">{{data.list.length}}</span> 个导航
      </div>
      
      <el-upload ref="upload" :show-file-list="false" accept="image/jpg, image/png, image/jpeg, image/gif"  class="upload-class"
      :on-success="(data)=>handleSuccess(data, null)" :before-upload="beforeUpload" :action="$store.state.pageManageConfig.uploadUrl">
        <div class="add-btn" v-if="data.list.length<10">
          <i class="el-icon-plus"></i>添加导航
        </div>
      </el-upload>
      <!-- <div class="add-btn" @click="add">
        <i class="el-icon-plus"></i>添加导航
      </div> -->

    </div>

		<div class="mod-footer">
			<div class="left">
				<el-checkbox v-model="data.hideSpace"><span style="font-size: 14px;color:#666">隐藏模块下方的间距</span></el-checkbox>
			</div>
		</div>
		
	</div>
	<!-- </transition> -->
</template>

<script>
	import config from '@/config/index'
	import draggable from 'vuedraggable';
	export default {
		name: 'normal-module-edit',
		components: {
      draggable
    },
		props: {
			data: {
				type: Object,
				default: () => {
					return {}
				}
			},
			visible: {
				type: Boolean,
				default: false
			},
			name: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
        select:[
          {value:1,label:'1.9包邮'},
          {value:2,label:'今日爆款'},
          {value:3,label:'品牌清仓'},
          {value:4,label:'限时秒杀'},
          {value:5,label:'百亿补贴'},
          {value:6,label:'领券中心'},
          {value:7,label:'刮刮卡'},
          {value:8,label:'砸金蛋'},
          {value:9,label:'千万神券'},
          {value:10,label:'千万补贴'},
        ],
        imgApi:config.IMG_BASE
			};
		},
		methods: {
      clearLabel(i){
        this.data.list[i].linkId = ''
        this.data.list[i].linkName = ''
      },
      del(i){
        this.data.list.splice(i,1)
      },
      async handleSuccess(data, i){
        if(this.data.list.length>=10&&i==null){
          this.$message.error('最多添加10个导航')
          return
        }
        var that = this;
				var img = new Image();
				img.onload = await function() {
          that.changeList(data[0],img,i)
				}
				img.src = this.$store.state.pageManageConfig.imghost + data[0];
      },
      changeList(url,img,i){
        if(i!=null){
          this.data.list[i].scale = img.width/img.height
          this.data.list[i].height = img.height
          this.data.list[i].img = url
        }else{
          this.data.list.push({
            img:url,
            scale:img.width/img.height,
            title:'',
            linkId:'',
            linkName:'',
            height:''
          })
        }
      },
      beforeUpload(file){
        const size = file.size / 1024 / 1024 < 1;
				const testtype = ['image/png','image/gif', 'image/jpeg', 'image/jpg']
        // console.log(file.type)
				if (testtype.indexOf(file.type) == -1) {
					this.$message.error('图片格式错误，请重新上传');
					return false
				} else if (!size) {
					this.$message.error('图片大小请控制在1M以内');
					return false
				}
      },
      handleCommand({i,y}){
        this.data.list[i].linkId = this.select[y].value
        this.data.list[i].linkName = this.select[y].label
        this.data.list[i].title = this.data.list[i].title||this.select[y].label
      },
      add(){
        if(this.data.list&&this.data.list.length>=10){
          this.$message.error('最多添加10个导航')
          return 
        }
        this.data.list.push({
          img:'',
          title:'',
          linkId:'',
          linkName:'',
          scale:'',
          height:''
        })
      },
			close() {
				this.$emit('close')
			},
		},
		mounted() {

		},
		watch: {
			data(value) {}
		}
	};
</script>
