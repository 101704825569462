<style scoped lang="less">
	.channels-live-module {
		
		.live-wraper{
			display: flex;
			overflow: hidden;
			background-color: #fff;
			width: 100%;
			
			.cover{
				flex: 0 0 auto;
				width: 88px;
				height: 88px;
			}
			.right{
				flex: 1 1 auto;
				overflow: hidden;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				
				margin: 5px 10px 10px;
				
				.name{
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					font-size: 14px;
				}
				.bottom{
					display: flex;
					align-items: center;
					justify-content: space-between;
					
					.state {
						background: #fb4a4a;
						color: #fff;
						display: flex;
						align-items: center;
						border-radius: 3px;
						padding: 0 5px;
						font-size: 11px;
						height: 20px;
						
						img {
							display: block;
							width: 8px;
							margin-right: 3px;
						}
						
						div{
							line-height: 1;
						}
					}
					.btn{
						width: 72px;
						height: 26px;
						line-height: 26px;
						border-radius: 13px;
						color: #fff;
						text-align: center;
						font-size: 12px;
						background-color: #fb4a4a;
					}
				}
			}
		}
		
		.customStyle{
			img{
				display: block;
				width: 100%;
			}
		}
		
		.subscribe-wraper{
			display: flex;
			padding: 12px 10px 10px;
			overflow: hidden;
			background-color: #fff;
			margin-top: 10px;
			
			.icon{
				flex: 0 0 auto;
				width: 16px;
				height: 16px;
				margin-right: 10px;
				margin-top: 1px;
			}
			
			.center{
				flex: 1 1 auto;
				overflow: hidden;
				
				.name{
					font-size: 14px;
					
					span{
						color: #FF547B;
						padding-right: 2px;
					}
				}
				.bottom{
					display: flex;
					overflow: hidden;
					margin-top: 10px;
					
					.desc{
						flex: 1 1 auto;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						font-size: 12px;
						color: #606266;
						margin-right: 5px;
					}
					.btn{
						color: #5B6A91;
						font-size: 12px;
						white-space: nowrap;
					}
				}
				
			}
			.right{
				flex: 0 0 auto;
				width: 72px;
				height: 26px;
				line-height: 26px;
				border-radius: 13px;
				color: #fff;
				text-align: center;
				font-size: 12px;
				margin-left: 20px;
				margin-top: 10px;
				background-color: #fb4a4a;
			}
		}
	}

	.haveSpace {
		margin-bottom: 10px;
	}
</style>

<template>
	<div class="channels-live-module" @mouseenter="data.visible=true" @mouseleave="data.visible=false"
		style="position: relative;padding-left: 40px;padding-right: 45px;" :class="{haveSpace: !data.hideSpace}">
		<module-name :name="name"></module-name>
		<controler @edit="edit();data.editVisible=true" :move="false" @moveUp="$emit('moveUp')" :visible="data.visible"
			@moveDown="$emit('moveDown')" @remove="$emit('remove')"></controler>

	
		<div class="live-wraper" v-if="data.style === 1">
			<img class="cover" v-if="data.coverImage" :src="imgUrl + data.coverImage"></img>
			<img class="cover" v-else :src="normalData"></img>
			<div class="right">
				<div class="name">直播主题，单行显示，超出省略省略省略省略省略省略省略省略</div>
				<div class="bottom">
					<div class="state">
						<img src="https://cdn.dkycn.cn/images/melyshop/live-icon-6.gif" mode="widthFix"></img>
						<div>直播中</div>
					</div>
					<div class="btn">观看直播</div>
				</div>
			</div>
		</div>
		<div class="customStyle" v-else>
			<img v-if="data.customInLiveImage" :src="imgUrl + data.customInLiveImage"></img>
			<img v-else src="http://cdn.dkycn.cn/images/dyj/no-pic1.jpg"></img>
		</div>
		
		<div v-if="data.showSubscribe">
			<div class="subscribe-wraper" v-if="data.subscribeStyle === 1">
				<img class="icon" src="https://cdn.dkycn.cn/images/melyshop/mini-share-card-4.png">
				<div class="center">
					<div class="name">
						<span>[预告]</span>明天12:00直播
					</div>
					<div class="bottom">
						<div class="desc"> {{data.subscribeDesc || '直播介绍直播介绍直播介绍直播介绍直播介绍'}}</div>
						<div class="btn">展开</div>
					</div>
				</div>
				<div class="right">预约直播</div>
			</div>
			<div class="customStyle" style="margin-top: 10px;" v-else>
				<img v-if="data.customSubscribeImage" :src="imgUrl + data.customSubscribeImage"></img>
				<img v-else src="http://cdn.dkycn.cn/images/dyj/no-pic1.jpg"></img>
			</div>
		</div>
		

		<div class="cover" v-if="data.visible"
			style="position: absolute;left:40px;top:0;right: 45px;height: 100%;border: 3px solid #0000FF;"></div>

	</div>
</template>

<script>
	import moduleName from '../../moduleName';
	import controler from '../../controler';
	import edit from './edit';

	import config from '@/config/index';

	export default {
		name: 'add-friends',
		components: {
			moduleName,
			controler,
			edit,

		},
		props: {
			name: {
				type: String,
				default: ''
			},
			data: {
				type: Object,
				default: () => {
					return {}
				}
			},

		},
		data() {
			return {
				imgUrl: config.IMG_BASE,
				btnType: 1, //按钮三种样式
				normalData: this.$store.state.pageManageConfig.staticImage.noPic
			};
		},
		methods: {
			edit() {
				this.$emit('visibleChange', this.data.visible)
			},

			close() {
				this.$emit('visibleChange', false)
			}

		},
		mounted() {},
		watch: {

		}
	};
</script>
