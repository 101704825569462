<style scoped lang="less">
    .singleRow-module-edit{
        position: absolute;
        // right: -530px;
        // width: 480px;
        padding: 20px 20px 30px;
        background: #fff;
        font-size: 14px;

        .title{
            position: relative;
            font-size: 18px;
            padding-bottom: 20px;
            margin-bottom: 25px;
            border-bottom: 1px solid #ddd;
        }

        .icon-close{
            position: absolute;
            right: 0;
            cursor: pointer;
        }

        .tip{
            margin-bottom: 20px;
        }

        .mod-footer{
            margin-top: 30px;
            padding-top: 20px;
            border-top: 1px solid #ddd;

            display: flex;

            .left{
                flex: 1 1 auto;
                padding-top: 6px;
            }
        }

    }
	
	.info{
	    flex: 1 1 auto;
	    margin-top: 10px;
		
		display: flex;
		overflow: hidden;
		height: 28px;
		align-items: center;
		
		.txt{
			flex: 0 0 auto;
			font-size: 14px;
			height: 19px;
		}
		
		.link-info{
			flex: 1 1 auto;
			overflow: hidden;
			max-width: 100%;
			overflow: hidden;
			
			display: flex;
			align-items: center;
			
			height: 28px;
			color: #fff;
			
			font-size: 12px;
			padding: 0 5px;
		}
		
		.cont{
			padding: 0 5px;
			background: #409eff;
			height: 28px;
			line-height: 28px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.icon{
			padding-right:5px;
			background: #409eff;
			height: 28px;
			line-height: 32px;
		}
	}

    
</style>

<template> 
    <!-- <transition name="scale"> -->
        <div class="singleRow-module-edit" v-if="visible">
            
            <p class="title">
                {{name}} 
                <!-- <Icon  @click="close" type="ios-close-empty" size=32 class="icon-close" color="#999"></Icon> -->
            </p>

            <p class="tip">文本内容</p>
            <el-input type="textarea" placeholder="请输入文本内容,最多100字" v-model="data.text" :maxlength="100" style="width:400px;" :autosize="{minRows: 6}"></el-input>
			
			
			<!-- <div class="info" @click="showModal(data)">
				<div class="txt">链接：</div>
				<div class="link-info" v-if="!data.selectedData.data">
					<div class="cont" style="background: transparent;color: #409eff;">请选择页面</div>
					<div class="icon" style="background: transparent;">
						<i class="el-icon-arrow-down" style="color: #409eff;font-size: 16px;background: transparent;"></i>
					</div>
				</div>
				<div class="link-info" v-else>
					<div class="cont">{{data.selectedData.text}}</div>
					<div class="icon" @click.stop="deleteLinkData(data)">
						<i class="el-icon-close" style="color: #fff;font-size: 16px;"></i>
					</div>
					<div class="icon">
						<i class="el-icon-arrow-down" style="color: #fff;font-size: 16px;"></i>
					</div>
				</div>
			</div> -->
			
			<div class="info">
				<div class="txt">链接：</div>
				<el-dropdown trigger="click" placement="bottom-start" class="link-info" @command="handleCommand($event, data)">
					<div class="link-info" v-if="!data.selectedData.data">
						<div class="cont" style="background: transparent;color: #409eff;">请选择页面</div>
						<div class="icon" style="background: transparent;">
							<i class="el-icon-arrow-down" style="color: #409eff;font-size: 16px;background: transparent;"></i>
						</div>
					</div>
					<div class="link-info" v-else>
						<div class="cont">{{data.selectedData.text}}</div>
						<div class="icon" @click.stop="deleteLinkData(data)">
							<i class="el-icon-close" style="color: #fff;font-size: 16px;"></i>
						</div>
						<div class="icon">
							<i class="el-icon-arrow-down" style="color: #fff;font-size: 16px;"></i>
						</div>
					</div>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="1">选择小程序内页面</el-dropdown-item>
						<el-dropdown-item command="2">链接到其它小程序</el-dropdown-item>
						<el-dropdown-item command="3">进入小程序客服</el-dropdown-item>
						<el-dropdown-item command="5">邀请视频号小店分享员</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>

            <div class="mod-footer">
                <div class="left">
                    <el-checkbox v-model="data.hideSpace"><span style="font-size: 14px;color:#666">隐藏模块下方的间距</span></el-checkbox>
                </div>
                <!-- <div class="right">
                    <Button type="primary" @click="saveData">保存</Button>
                </div> -->
            </div>
			
			<link-modal :showLiveTab="true" :visible="popVisible" @change="dataChange" @cancel="popVisible=false"></link-modal>
			<reply-modal :visible="replyVisible" :data="editData" @change="dataChange" @cancel="replyVisible=false"></reply-modal>
			<ext-link :visible="extLinkVisible" :data="editData" @change="dataChange" @cancel="extLinkVisible=false"></ext-link>
			<mini-modal :visible="miniVisible" :data="editData" @change="dataChange" @cancel="miniVisible=false"></mini-modal>
			
        </div>
    <!-- </transition> -->
</template>

<script>
import linkModal from '@/views/components/linkModal';
import replyModal from '@/views/components/autoReplyModal.vue';
import extLink from '@/views/components/extLinkModal.vue';
import miniModal from '@/views/components/miniProgramModal.vue';

export default {
    name: 'normal-module-edit',
    components: {
		linkModal,
		replyModal,
		extLink,
		miniModal
    },
    props: {
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        visible: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
			popVisible: false,
			replyVisible: false,
			extLinkVisible: false,
			miniVisible: false,
			editData: {}
        };
    },
    methods: {
        close(){
            this.$emit('close')
        },
        saveData(){
            this.$emit('submit')
        },
		handleCommand(e, record){
			console.log(e, record)
			this.editData = record;
			if (e == '1'){
				this.popVisible = true;
			}else if (e == '2'){
				this.miniVisible = true;
			}else if (e == '3'){
				this.replyVisible = true;
			}else if (e == '4'){
				this.extLinkVisible = true;
			}else if (e == '5'){
				this.dataChange({
					text:'邀请视频号小店分享员',
					data:{},
					type:'videoShare',
				})
			}
		},
		// showModal(){
		// 	this.popVisible = true;
		// },
		dataChange(record){
			 this.data.selectedData = record;
		},
		deleteLinkData(record){
			this.data.selectedData = {};
		},

    },
    mounted () {
        // this.data.list.push({
        //         uid: this.guid(),
        //         imgUrl: '/ShopDecorate/61f677cf-ce0d-4364-b3d8-9cdeffe089aa.jpg',
        //         type: 1, //链接类型
        //         link: '', //自定义链接
        //         selectedData: {}, //弹框选中数据
        //     })
    },
    watch: {
        data(value){
            //this.$set(this.data,'btnType',1)
            
            //this.data.btnType = 1;
            //console.log(value)
        }
    }
};
</script>

