<style scoped lang="less">
    .singleRowProduct-module{
        
        .product-list{
            .product-item{
                
                background: #fff;
                padding-bottom: 20px;
                margin-bottom: 10px;

                &:last-child{
                    margin-bottom: 0;
                }

                img{
                    width: 375px;
                    height: 375px;
                    border: 1px solid #ddd;
                }

                .name{
                    margin: 15px 20px 10px;
                    font-size: 14px;
                    line-height: 20px;
                    max-height: 40px;

                    display: -webkit-box; 
                    -webkit-box-orient:vertical; 
                    -webkit-line-clamp: 2; 
                    overflow: hidden;
                }
                
                .pro-info{
                    display: flex;
                    margin: 0 20px;

                    .left{
                        flex: 1 1 auto;
                        color: #1890ff;
                        font-size: 14px;

                        .price{
                            font-size: 30px
                        }

                        .old{
                            font-size: 14px;
                            color: #333;
                        }
                    }

                    .btn-box{
                        flex: 0 0 auto;

                        .btn{
                            width: 80px;
                            height: 28px;
                            line-height: 28px;
                            font-size: 14px;
                            color: #fff;
                            background: #1890ff;
                            text-align: center;
                            border-radius: 18px;
                        }

                        .text{
                            font-size: 12px;
                            color: #666;
                            text-align: center;
                        }
                    }

                    

                }
            }
            
        }

        .product-list-1{

            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;

            .product-item{

                width: 170px;
                background: #fff;
                padding-bottom: 10px;
                margin-bottom: 10px;

                img{
                    width: 170px;
                    height: 170px;
                    border: 1px solid #ddd;
                }

                .name{
                    margin: 5px 10px 0;
                    font-size: 14px;
                    text-align: center;
                    
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                
                .pro-info{
                    display: flex;
                    margin: 0 10px;

                    .left{
                        flex: 1 1 auto;
                        color: #1890ff;
                        font-size: 14px;
                        text-align: center;

                        .price{
                            font-size: 20px;
                        }

                        .old{
                            font-size: 12px;
                            color: #999;
                            font-weight: 400;
                            padding-left: 5px;
                        }
                    }
                }

                .market{
                    font-size: 14px;
                    color: #666;
                    text-align: center;
                }
            }
            
        }

        .product-list-2{
            .product-item{
                
                background: #fff;
                display: flex;

                &:last-child{
                    margin-bottom: 0;
                }

                img{
                    width: 120px;
                    height: 120px;
                    border: 1px solid #ddd;
                }

                .box{
                    flex: 1 1 auto;
                    padding-left: 10px;
                    border-bottom: 1px solid #ddd;
                }

                .name{
                    margin: 15px 10px 10px 0;
                    font-size: 14px;
                    line-height: 20px;
                    height: 40px;

                    display: -webkit-box; 
                    -webkit-box-orient:vertical; 
                    -webkit-line-clamp: 2; 
                    overflow: hidden;
                }
                
                .pro-info{
                    display: flex;
                    margin-right: 10px;

                    .left{
                        flex: 1 1 auto;
                        color: #1890ff;
                        font-size: 12px;
                        line-height: 20px;
                        
                        .price{
                            font-size: 20px;
                        }

                        .old{
                            
                            font-size: 14px;
                            color: #666;
                        }

                        .tuan{
                            font-size: 14px;
                            color: #999;
                        }
                    }

                    .btn-type-1{
                        flex: 0 0 auto;
                        width: 80px;
                        height: 28px;
                        line-height: 28px;
                        font-size: 14px;
                        color: #fff;
                        background: #1890ff;
                        text-align: center;
                        border-radius: 18px;
                        margin-top: 15px;
                    }

                }
            }
            
        }
        
    }
    .haveSpace{
        margin-bottom: 20px;
    }
</style>

<template>
    <div class="singleRowProduct-module"  :class="{haveSpace: !data.hideSpace}">
        <module-name :name="name"></module-name>
        <controler @edit="edit();data.editVisible=!data.editVisible" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        <edit :data="data" @submit="$emit('submit')" :visible="data.editVisible" :name="name" @close="data.editVisible=false"  />
        
        <div @click="edit();data.visible=true">
            <div class="product-list" v-if="!data.list.length&&data.style===1">
                <div class="product-item">
                    <img :src="normalData.url" />
                    <p class="name">{{normalData.name}}</p>
                    <div class="pro-info">
                        <div class="left">
                            ￥<span class="price">{{normalData.price}} </span><span class="old">单买价：￥{{normalData.marketPrice}}</span>
                        </div>
                        <div class="btn-box">
                            <p class="text">5人成团</p>
                            <div class="btn">去开团</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="product-list-1" v-if="!data.list.length&&data.style===2">
                <div class="product-item" :key="i" v-for="i in 2">
                    <img :src="normalData.url" />
                    <p class="name">{{normalData.name}}</p>
                    <div class="pro-info">
                        <div class="left">
                            ￥<span class="price">{{normalData.price}}</span> <span class="old">5人成团</span>
                        </div>
                    </div>
                    <p class="market">单买价：￥199</p>
                </div>
            </div>
            <div class="product-list-2" v-if="!data.list.length&&data.style===3">
                <div class="product-item" :key="i" v-for="i in 2">
                    <img :src="normalData.url" />
                    <div class="box">
                        <p class="name">{{normalData.name}}</p>
                        <div class="pro-info">
                            <div class="left">
                                ￥<span class="price">{{normalData.price}}</span> <span class="old">单买价：￥{{normalData.marketPrice}}</span>
                                <div class="tuan">5人成团</div>
                            </div>
                            <div class="btn-type-1">去团购</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="product-list" v-if="data.list.length&&data.style===1">
                <div class="product-item" :key="item.Id" v-for="item in data.list">
                    <img :src="$store.state.pageManageConfig.imghost+item.ImgUrl" />
                    <p class="name">{{item.Name}}</p>
                    <div class="pro-info">
                        <div class="left">
                            ￥<span class="price">{{item.MaxGroupBuyingPrice}} </span><span class="old">单买价：￥{{item.MinPrice}}</span>
                        </div>
                        <div class="btn-box">
                            <p class="text">{{item.GroupCount}}人成团</p>
                            <div class="btn">去开团</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="product-list-1" v-if="data.list.length&&data.style===2">
                <div class="product-item" :key="item.Id" v-for="item in data.list">
                    <img :src="$store.state.pageManageConfig.imghost+item.ImgUrl" />
                    <p class="name">{{item.Name}}</p>
                    <div class="pro-info">
                        <div class="left">
                            ￥<span class="price">{{item.MaxGroupBuyingPrice}}</span> <span class="old">{{item.GroupCount}}人成团</span>
                        </div>
                    </div>
                    <p class="market">单买价：￥{{item.MinPrice}}</p>
                </div>
                <div class="product-item" style="background: transparent" v-if="data.list.length%2"></div>
            </div>
            <div class="product-list-2" v-if="data.list.length&&data.style===3">
                <div class="product-item" :key="item.Id" v-for="item in data.list">
                    <img :src="$store.state.pageManageConfig.imghost+item.ImgUrl" />
                    <div class="box">
                        <p class="name">{{item.Name}}</p>
                        <div class="pro-info">
                            <div class="left">
                                ￥<span class="price">{{item.MaxGroupBuyingPrice}}</span> <span class="old">单买价：￥{{item.MinPrice}}</span>
                                <div class="tuan">{{item.GroupCount}}人成团</div>
                            </div>
                            <div class="btn-type-1">去团购</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';



export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        edit,
        
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
    data () {
        return {
            
            btnType: 1, //按钮三种样式
            normalData: {
                url: this.$store.state.pageManageConfig.staticImage.noPic,
                name: '此处是商品名称',
                price: 99.00,
                marketPrice: 100.00,
            }
        };
    },
    methods: {
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        
        close(){
            this.$emit('visibleChange', false)
        }

    },
    mounted () {
    },
    watch: {
        
    }
};
</script>

