<style scoped lang="less">
    .singleRowProduct-module{
        
        .product-list{
            .product-item{
                
                background: #fff;
                display: flex;

                &:last-child{
                    margin-bottom: 0;
                }

                img{
                    width: 120px;
                    height: 120px;
                    border: 1px solid #ddd;
                }

                .box{
                    flex: 1 1 auto;
                    padding-left: 10px;
                    border-bottom: 1px solid #ddd;
                }

                .name{
                    margin: 15px 10px 10px 0;
                    font-size: 14px;
                    line-height: 20px;
                    height: 40px;

                    display: -webkit-box; 
                    -webkit-box-orient:vertical; 
                    -webkit-line-clamp: 2; 
                    overflow: hidden;
                }
                
                .pro-info{
                    display: flex;
                    margin-right: 10px;

                    .left{
                        flex: 1 1 auto;
                        color: #1890ff;
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 20px;

                        .old{
                            display: block;
                            font-size: 12px;
                            color: #ccc;
                            font-weight: 400;
                            text-decoration: line-through;
                        }
                    }

                    .btn-type-1{
                        flex: 0 0 auto;
                        width: 80px;
                        height: 28px;
                        line-height: 28px;
                        font-size: 14px;
                        color: #fff;
                        background: #1890ff;
                        text-align: center;
                        border-radius: 18px;
                        margin-top: 5px;
                    }

                    .btn-type-2{
                        flex: 0 0 auto;
                        width: 80px;
                        height: 28px;
                        line-height: 28px;
                        font-size: 14px;
                        color: #1890ff;
                        border: 1px solid #1890ff;
                        text-align: center;
                        margin-top: 5px;
                    }

                    .btn-type-3{
                        flex: 0 0 auto;
                        width: 28px;
                        height: 28px;
                        padding-top: 5px;
                        color: #fff;
                        background: #1890ff;
                        border-radius: 100%;
                        text-align: center;
                        margin-top: 5px;
                    }
                }
            }
            
        }
        
    }
    .haveSpace{
        margin-bottom: 20px;
    }
</style>

<template>
    <div class="singleRowProduct-module"  :class="{haveSpace: !data.hideSpace}">
        <module-name :name="name"></module-name>
        <controler @edit="edit();data.editVisible=!data.editVisible" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        <edit :data="data" @submit="$emit('submit')" :visible="data.editVisible" :name="name" @close="data.editVisible=false"  />
        
        <div @click="edit();data.visible=true">
            <div class="product-list" v-if="!data.list.length">
                <div class="product-item" :key="i" v-for="i in 2">
                    <img :src="normalData.url" />
                    <div class="box">
                        <p class="name">{{normalData.name}}</p>
                        <div class="pro-info">
                            <div class="left">
                                ￥{{normalData.price}} <span class="old">￥{{normalData.marketPrice}}</span>
                            </div>
                            <div class="btn-type-1" v-if="data.btnType===1">立即购买</div>
                            <div class="btn-type-2" v-if="data.btnType===2">立即购买</div>
                            <div class="btn-type-3" v-if="data.btnType===3"><Icon type="ios-cart-outline" size=20></Icon></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="product-list" v-else>
                <div class="product-item" :key="index" v-for="(item,index) in data.list">
                    <img :src="$store.state.pageManageConfig.imghost+item.ImgUrl" />
                    <div class="box">
                        <p class="name">{{item.Name}}</p>
                        <div class="pro-info">
                            <div class="left">
                                ￥{{item.Price}} <span class="old">￥{{item.MarketPrice}}</span>
                            </div>
                            <div class="btn-type-1" v-if="data.btnType===1">立即购买</div>
                            <div class="btn-type-2" v-if="data.btnType===2">立即购买</div>
                            <div class="btn-type-3" v-if="data.btnType===3"><Icon type="ios-cart-outline" size=20></Icon></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';



export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        edit,
        
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
    data () {
        return {
            
            btnType: 1, //按钮三种样式
            normalData: {
                url: this.$store.state.pageManageConfig.staticImage.noPic,
                name: '此处是商品名称-此处是商品名称',
                price: 99.00,
                marketPrice: 100.00,
            }
        };
    },
    methods: {
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        
        close(){
            this.$emit('visibleChange', false)
        }

    },
    mounted () {
    },
    watch: {
        
    }
};
</script>

