<style scoped lang="less">
    .singleRowProduct-module{
        .mainBox{

          .noImg{
            width: 100%;
          }
          .imgBox{
            --length:1;
            text-align:center;
            flex-shrink:0;
            overflow:hidden;
            width: calc(100% / var(--length));
            min-width: 20%;
            max-width: 100%;
            img{
              width:100%;
              object-fit:cover;
            }
          }
        }
        
    }
    .haveSpace{
        margin-bottom: 20px;
    }
</style>

<template>
    <div class="singleRowProduct-module"  @mouseenter="data.visible=true" @mouseleave="data.visible=false" style="position: relative;padding-left: 40px;padding-right: 45px;" :class="{haveSpace: !data.hideSpace}">
        <module-name :name="name"></module-name>
        <controler @edit="edit();data.editVisible=true" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        
        
        <div class="mainBox">
          <div class="noImg" v-if="!data.list||!data.list.length">
            <img :src="$store.state.pageManageConfig.staticImage.noPic1" />
          </div>
          <div v-else>
            <div class="flex flex-align-start flex-justify-start flex-wrap">
              <div class="imgBox" v-for="(v,i) in data.list" :style="{'--length':data.list.length>5?5:data.list.length,}" :key="i">
                <img :src="imgApi+v.img" :style="{'height':height}" alt="" />
                <div class="font-14 ellipsis" style="line-height:25px;padding:0px 5px;width:100%">{{v.title}}</div>
              </div>
            </div>
          </div>
        </div>

        
		    <div class="cover" v-if="data.visible" style="position: absolute;left:40px;top:0;right: 45px;height: 100%;border: 3px solid #0000FF;"></div>
    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';
import config from "@/config"


export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        edit,
        
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
    data () {
        return {
          imgApi:config.IMG_BASE
        };
    },
    computed:{
      height(){
        let length = this.data.list.length||0
        if(length){
          //现在第一张图的实际高度
          return 375/this.data.list[0].scale/(length>5?5:length) + 'px'
        }else{
          return 375/(length>5?5:length) + 'px'
        }
      }
    },
    mounted () {

    },
    methods: {
      
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        
        close(){
            this.$emit('visibleChange', false)
        }

    },
};
</script>

