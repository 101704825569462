<style scoped lang="less">
    .module-name{
        position: absolute;
        font-size: 14px;
        text-align: center;
        line-height: 16px;
		width: 20px;
		padding: 10px 3px;
        background: #fff;
        border-radius: 20px;
        z-index: 1;
        transform: translateX(-150%);
		box-sizing: content-box;
    }
</style>

<template>
    <div class="module-name">
        {{name}}
    </div>
</template>

<script>


export default {
    name: 'module-name',
    components: {
    },
    props: {
        name: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
        };
    },
    methods: {
    },
    mounted () {
    },
    watch: {
    }
};
</script>

