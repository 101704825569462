<style lang="less">
.reply-container {
	
	max-height: 60vh;
	overflow: auto;
	
	.el-radio-group{
		display: block;
		line-height: 20px;
	}
	.el-radio{
		display: flex;
		margin-bottom: 20px;
		border-bottom: 1px solid #eee;
	}
	.el-radio__label{
		flex: 1 1 auto;
	}
	.reply-title{
		font-weight: bold;
		margin-bottom: 10px;
		white-space: normal;
	}
	.reply-content{
		color: #666;
		display: flex;
		margin-bottom: 10px;
		
		.reply-content-tit{
			flex: 0 0 auto;
		}
		.reply-content-cont{
			flex: 1 1 auto;
			overflow: hidden;
			white-space: normal;
			
			.reply-content-cont-img{
				float: left;
				margin-right: 10px;
				margin-bottom: 10px;
				width: 100px;
				height: 100px;
				overflow: hidden;
				border: 1px solid #eee;
				
				img{
					display: block;
					width: 100%;
				}
			}
		}
	}
	
	.no-list{
		display: flex;
		align-items: center;
	}
}
</style>
<template>
	<el-dialog :visible="visible" width='650px' title="选择小程序客服回复内容" :append-to-body="true" @close="cancel">
		
		<el-button type="primary" style="margin-bottom: 20px;" v-if="list.length" @click="toAdd">添加回复内容</el-button>
		
		<div class="reply-container">
			<el-radio-group size="small" v-if="list.length" v-model="radio">
				<el-radio :label="i" v-for="(item, i) in list" :key="item.Id">
					<div class="reply-title">{{item.CommunityGroupCodeTitle}}</div>
					<div class="reply-content" v-if="item.CommunityGroupCodeText">
						<span class="reply-content-tit">回复文本：</span>
						<div class="reply-content-cont">{{item.CommunityGroupCodeText}}</div>
					</div>
					<div class="reply-content" v-if="item.CommunityGroupCodeImgUrl && item.CommunityGroupCodeImgUrl.length">
						<span class="reply-content-tit">回复图片：</span>
						<div class="reply-content-cont">
							<div class="reply-content-cont-img" v-for="(obj, index) in item.CommunityGroupCodeImgUrl" :key="index">
								<img :src="imgUrl + obj">
							</div>
						</div>
					</div>
					<div class="reply-content" style="font-size: 12px;color: #C03639;" v-if="item.IsCommunityGroupLockEmployeeFirst">
						若有锁粉店员，优先回复锁粉店员企业微信二维码图片：已开启
					</div>
				</el-radio>
			</el-radio-group>
			<div class="no-list" v-else>商城还未添加小程序客服自动回复内容，请<el-link type="primary" @click="toAdd">点击此处前往添加</el-link></div>
		</div>
		

		<div slot="footer">
			<el-button type="primary" @click="btnSure">确定</el-button>
		</div>

	</el-dialog>
</template>
<script>
	import api from '@/api/other.js';
	import config from '@/config/index'

	export default {
		components: {},
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			data: {
				type: Object,
				default: () => {
					return {}
				}
			}
		},
		watch: {
			visible(value) {
				this.show = value;

				if (value) {
					this.getData();
				}
			}
		},
		data() {
			return {
				show: false,
				radio: '',
				list: [],
				imgUrl: config.IMG_BASE,
			};
		},
		methods: {
			async getData() {
				const res = await api.communityGroupCodeList({});
				if (res.IsSuccess){
					this.list = res.Result;
					console.log(this.data)
					if (this.data.selectedData.data){
						this.list.map((item, index)=>{
							if (this.data.selectedData.data.Id == item.Id){
								this.radio = index;
							}
						})
					}
				}
				
			},
			cancel() {
				this.$emit('cancel');
			},
			btnSure() {
				console.log(this.radio)
				if (this.radio === ''){
					this.$message.error('请选择回复内容')
				}
				
				this.$emit('change', {
					type: 'autoReply',
					text: '客服|' + this.list[this.radio].CommunityGroupCodeTitle,
					data: JSON.parse(JSON.stringify(this.list[this.radio]))
				})
				this.cancel();
			},
			toAdd(){
				this.$router.push({
					name: 'Codeconfig'
				})
			}
		},

		mounted() {

		},
		created() {

		},
		dispatch() {

		}
	};
</script>
