<style scoped lang="less">
	.item{
		padding: 15px;
		margin-top: 20px;
		background-color: #f5f5f5;
		
		.top{
			display: flex;
			justify-content: space-between;
			font-size: 14px;
			
			.left{
				// font-size: 15px;
			}
			.right{
				color: #d00000;
				cursor: pointer;
			}
		}
		
		
		.link-info {
			height: 24px;
			line-height: 24px;
			background: #409eff;
			padding: 0 5px;
			margin-top: 10px;
			color: #fff;
			font-size: 12px;
		
			display: flex;
		
			.cont {
				flex: 1 1 auto;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		
			.icon {
				flex: 0 0 auto;
			}
		}
		
		
		.product-list {
			padding-top: 10px;
			overflow: hidden;
			margin-top: 10px;
		
			.product-item {
				position: relative;
				float: left;
				width: 65px;
				height: 65px;
				margin-right: 10px;
				margin-bottom: 10px;
			}
		
			.img {
				position: relative;
				width: 65px;
				height: 65px;
				overflow: hidden;
				cursor: pointer;
		
				img {
					display: block;
					width: 100%;
				}
			}
		
			.control {
				position: absolute;
				top: -10px;
				right: -10px;
			}
		}
		
		
		.expand{
			color: #409eff;
			margin-top: 10px;
			cursor: pointer;
			
			display: flex;
			
			.text{
				width: 120px;
			}
			
			.arrow{
				width: 8px;
				height: 8px;
				border-left: 1px solid #409eff;
				border-bottom: 1px solid #409eff;
				transform: rotate(-45deg);
				margin-top: 2px;
			}
			
			.arrow-up{
				width: 8px;
				height: 8px;
				border-left: 1px solid #409eff;
				border-top: 1px solid #409eff;
				transform: rotate(45deg);
				margin-top: 6px;
			}
		}
		
		.add-btn {
			text-align: center;
			border: 1px solid #ddd;
			font-size: 16px;
			color: #666;
			height: 40px;
			line-height: 40px;
			text-align: center;
			cursor: pointer;
			width: 300px;
			margin: 10px auto 0;
		}
	}
</style>

<template>
	<div class="item">
		<div class="top">
			<div class="left">{{name}}</div>
			<div class="right" @click="deleteActivity">删除活动</div>
		</div>
		
		<div class="link-info">
			<div class="cont">{{data.Name}}</div>
			<!-- <div class="icon" @click="deleteData()">
				<i class="el-icon-close" style="color: #fff;font-size: 16px;"></i>
			</div> -->
		</div>
		
		<div class="expand" v-if="!data.expand" @click="handleExpand">
			<div class="text">展开管理活动商品</div>
			<div class="arrow"></div>
		</div>
		<div class="expand" v-else @click="handleExpand">
			<div class="text">收起活动商品</div>
			<div class="arrow-up"></div>
		</div>
		
		<div v-if="data.expand">
			<div v-if="data.list && data.list.length">
				<draggable class="product-list" :list="data.list" :options="{ animation: 300, handle: '.product-item' }">
					<transition-group name="bounce-out-left">
						<div class="product-item" :key="item.ProductId" v-for="item in data.list">
							<div class="img"><img :src="$store.state.pageManageConfig.imghost + item.ImgUrl" /></div>
							<div class="control" @click="deleteProduct(item)"><i class="el-icon-error" style="font-size: 24px;opacity: .6;"></i></div>
						</div>
					</transition-group>
				</draggable>
			</div>
			<div v-else>
				<div style="text-align: center;color: #999;padding-bottom: 15px;padding-top: 15px;">暂未选择商品</div>
			</div>
		
		
			<div class="add-btn" @click="showShopModal">
				<i class="el-icon-circle-plus-outline" style="margin-right: 5px;vertical-align: -2px;font-size: 18px;"></i>
				选择商品
			</div>
		</div>
		
	</div>
</template>

<script>

	import draggable from 'vuedraggable';
	export default {
		name: 'item',
		components: {
			draggable
		},
		props: {
			data: {
				type: Object,
				default: () => {
					return {};
				}
			},
			name: {
				type: String,
				default: '活动'
			}
		},
		data() {
			return {
			};
		},
		watch:{
			data(){
				// console.log(this.data.list, 'watch')
			}
		},
		methods: {
			deleteProduct(record){
				this.$emit('deleteProduct', record)
			},
			deleteActivity(){
				this.$emit('deleteActivity', this.data)
			},
			showShopModal(){
				this.$emit('selectShop', this.data)
			},
			handleExpand(){
				this.$emit('expand', !this.data.expand)
			}
		},
		mounted() {
			console.log(this.data.list)
		}
	};
</script>
