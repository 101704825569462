<style scoped lang="less">

    .singleRowProduct-module{
        
        .title{
            padding: 20px 25px;
            font-size: 16px;
            background: #fff;
        }
        .center{
            position: relative;
            text-align: center;

            &:after{
                content: '';
                position: absolute;
                right: 15px;
                top: 26px;
                width: 12px;
                height: 12px;
                border-top: 2px solid #999;
                border-right: 2px solid #999;
                transform: rotate(45deg);
            }
        }
        
    }
    .haveSpace{
        margin-bottom: 20px;
    }
    
</style>

<template>
    <div class="singleRowProduct-module"  :class="{haveSpace: !data.hideSpace}">
        <module-name :name="name"></module-name>
        <controler @edit="edit();data.editVisible=!data.editVisible" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        <edit :data="data" @submit="$emit('submit')" :visible="data.editVisible" :name="name" @close="data.editVisible=false"  />
        
        <div class="title" @click="edit();data.visible=true" :class="{center:data.style===2}">{{data.title||"请输入标题"}}</div>

    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';

export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        edit
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
    data () {
        return {
        };
    },
    methods: {
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        
        close(){
            this.$emit('visibleChange', false)
        }

    },
    mounted () {
    },
    watch: {
        
    }
};
</script>

