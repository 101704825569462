<style scoped lang="less">
	.singleRow-module-edit{
        padding: 20px 20px 30px;
        background: #fff;
        font-size: 14px;
		
		.title{
		    position: relative;
		    font-size: 18px;
		    padding-bottom: 20px;
		    margin-bottom: 25px;
		    border-bottom: 1px solid #ddd;
		}
		
		.tip{
			font-size: 12px;
			color: #999;
			width: 370px;
			line-height: 18px;
		}
		
		.add-btn {
			text-align: center;
			border: 1px dashed #ddd;
			font-size: 16px;
			color: #666;
			height: 48px;
			line-height: 48px;
			text-align: center;
			margin-bottom: 18px;
			cursor: pointer;
			width: 100%;
			margin-top: 30px;
		}
		
    .link-info {
			width: 100%;
      height: 28px;
      line-height: 28px;
      background: #409eff;
      padding: 0 5px;
      margin-bottom: 10px;
      color: #fff;
      font-size: 14px;

      display: flex;

      .cont {
        flex: 1 1 auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .icon {
        flex: 0 0 auto;
      }
    }
		
	}
	
	.mod-footer{
	    margin-top: 30px;
	    padding-top: 20px;
	    border-top: 1px solid #ddd;
	
	    display: flex;
	
	    .left{
	        flex: 1 1 auto;
	        padding-top: 6px;
	    }
	}

  .blue-box{
    border: 1px solid #409EFF;
    padding: 6px 10px;
  }

    
</style>

<template>
	<!-- <transition name="scale"> -->
	<div class="singleRow-module-edit" v-if="visible">

		<p class="title">
			{{name}}
			<Icon @click="close" type="ios-close-empty" size=32 class="icon-close" color="#999"></Icon>
		</p>

    <div class="flex flex-column flex-align-center">
      <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/routuantuangou-defaulatImg.png"
       style="width:170px;height:131px" alt="">
      <div class="color-666666" style="margin-top:54px">
        该模块自动显示当前已添加的肉团团购商品数据
      </div>
    </div>

		<div class="mod-footer">
			<div class="left">
				<el-checkbox v-model="data.hideSpace"><span style="font-size: 14px;color:#666">隐藏模块下方的间距</span></el-checkbox>
			</div>
			<!-- <div class="right">
		            <Button type="primary" @click="saveData">保存</Button>
		        </div> -->
		</div>
		
	</div>
	<!-- </transition> -->
</template>

<script>
	import config from '@/config/index'
	export default {
		name: 'normal-module-edit',
		components: {

    },
		props: {
			data: {
				type: Object,
				default: () => {
					return {}
				}
			},
			visible: {
				type: Boolean,
				default: false
			},
			name: {
				type: String,
				default: ''
			}
		},
		data() {
			return {

			};
		},
		methods: {
			close() {
				this.$emit('close')
			},
			
		},
		mounted() {

		},
		watch: {
			data(value) {}
		}
	};
</script>
