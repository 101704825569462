<style scoped lang="less">
    .module-controler{
        position: absolute;
        right: 0;
        font-size: 14px;
        text-align: center;
        line-height: 16px;
        width: 32px;
        padding: 10px 0;
        background: #fff;
        z-index: 1;
        cursor: pointer;
    }
</style>

<template>
    <div class="module-controler" v-if="visible" :style="{right: right}">
		<i class="el-icon-setting" @click="edit" style="font-size: 22px;color:#1890ff"></i><br/>
        <!-- <Icon @click="edit" type="gear-b" size=22 color="#1890ff"></Icon><br/> -->
        <p v-if="move">
			<i class="el-icon-arrow-up" @click="moveUp" style="font-size: 16px;color:#999"></i>
		</p>
        <p v-if="move">
			<i class="el-icon-arrow-down" @click="moveDown" style="font-size: 16px;color:#999"></i>
		</p>
        <p v-if="del">
            <img style="margin-top:4px;" @click="remove" :src="$store.state.pageManageConfig.staticImage.deleteIcon" />
            <!-- <Icon type="ios-trash-outline" size=26 color="#999"></Icon> -->
        </p>
    </div>
</template>

<script>


export default {
    name: 'module-controler',
    components: {
    },
    props: {
        move: {
            type: Boolean,
            default: true
        },
        del: {
            type: Boolean,
            default: true
        },
        visible: {
            type: Boolean,
            default: false
        },
		right: {
			type: String,
			default: '0px'
		}
    },
    data () {
        return {
        };
    },
    methods: {
        edit(){
            this.$emit('edit');
        },
        moveUp(){
            this.$emit('moveUp');
        },
        moveDown(){
            this.$emit('moveDown');
        },
        remove(){
            this.$emit('remove');
        }
    },
    mounted () {
    },
    watch: {
    }
};
</script>

