<style scoped lang="less">
	.singleRow-module-edit{
        padding: 20px 20px 30px;
        background: #fff;
        font-size: 14px;
		
		.title{
		    position: relative;
		    font-size: 18px;
		    padding-bottom: 20px;
		    margin-bottom: 25px;
		    border-bottom: 1px solid #ddd;
		}
		
		.tip{
			font-size: 12px;
			color: #999;
			width: 370px;
			line-height: 18px;
		}
		
		.add-btn {
			text-align: center;
			border: 1px dashed #ddd;
			font-size: 16px;
			color: #666;
			height: 48px;
			line-height: 48px;
			text-align: center;
			margin-bottom: 18px;
			cursor: pointer;
			width: 370px;
			margin-top: 30px;
		}
		
		
		.share-image-wraper{
			display: flex;
			align-items: center;
			
			.right{
				width: 226px;
				margin-left: 10px;
				padding-top: 8px;
				
				.right-tip{
					font-size: 12px;
					color: #999;
					line-height: 16px;
				}
				.right-clear{
					font-size: 12px;
					color: #1890ff;
					cursor: pointer;
				}
			}
		}
		
		.share-uploader .el-upload {
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
			
		}
		
		.share-uploader .el-upload:hover {
			border-color: #409EFF;
		}
		
		.share-uploader {
			font-size: 28px;
			color: #8c939d;
			width: 120px;
			height: 67.5px;
			line-height: 67.5px;
			text-align: center;
			border: 1px dashed #ddd;
			overflow: hidden;
		}
		.avatar-uploader-icon{
			font-size: 28px;
			color: #8c939d;
			width: 120px;
			height: 67.5px;
			line-height: 67.5px;
			text-align: center;
		}
		
		.avatar {
			width: 120px;
			height: 67.5px;
			display: block;
		}
		
		
		.videoBox {
			position: relative;
		
			.deletIcon {
				width: 152px;
				height: 152px;
				position: absolute;
				top: 0;
				left: 0;
				background: rgba(0, 0, 0, .4);
				cursor: pointer;
		
				.deleteImg {
					position: absolute;
					font-size: 20px;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
		
			}
		
			.Displayblock {
				display: block;
			}
		
			.Displaynone {
				display: none;
			}
		}
		
	}
	
	.mod-footer{
	    margin-top: 30px;
	    padding-top: 20px;
	    border-top: 1px solid #ddd;
	
	    display: flex;
	
	    .left{
	        flex: 1 1 auto;
	        padding-top: 6px;
	    }
	}

  .blue-box{
    border: 1px solid #409EFF;
    padding: 6px 10px;
  }

    
</style>

<template>
	<!-- <transition name="scale"> -->
	<div class="singleRow-module-edit" v-if="visible">

		<p class="title">
			{{name}}
			<Icon @click="close" type="ios-close-empty" size=32 class="icon-close" color="#999"></Icon>
		</p>

    <div>
      <div class="blue-box font-14 color-666666 line-font-1_5">
        <span class="font-bold">模块说明：</span>
        <span>
          在商城页面内，添加【邀请分享员】模块，推手或员工进入商城，点击模块邀请他们成为小店分享员，助力视频号带货。
        </span>
        <el-button type="text" style="margin:0px;padding:0px" @click="exampleShow=true">查看示例</el-button>
      </div>
      <div class="margin-top-30" v-if="shopMsg.IsUse">
        <div class="font-16 font-bold">当前绑定视频号小店信息：</div>
        <div class="form">
          <div class="flex flex-align-center color-606266 margin-top-20">
            <div class="form-label font-bold">店铺头像</div>
            <img :src="shopMsg.HeadImgUrl" style="width50px;height:50px;border-radius:50%" alt="">
          </div>
          <div class="flex flex-align-center color-606266 margin-top-20">
            <div class="form-label font-bold">店铺名称</div>
            <div class="font-14">
              {{shopMsg.Nickname}}
            </div>
          </div>
          <div class="flex flex-align-center color-606266 margin-top-20">
            <div class="form-label font-bold">AppID</div>
            <div class="font-14">
              {{shopMsg.AppId}}
            </div>
          </div>
        </div>
      </div>
      <div class="font-14 color-666666 margin-top-30" style="text-align:center" v-else>
        商城还未绑定视频号小店，请
        <el-button type="text" @click="$router.push({path:'/videoShop/videoShopBinding'})">前往绑定</el-button>
      </div>
    </div>

		<div class="mod-footer" v-if="shopMsg.IsUse">
			<div class="left">
				<el-checkbox v-model="data.hideSpace"><span style="font-size: 14px;color:#666">隐藏模块下方的间距</span></el-checkbox>
			</div>
			<!-- <div class="right">
		            <Button type="primary" @click="saveData">保存</Button>
		        </div> -->
		</div>
		

    <el-dialog :visible.sync="exampleShow" title="示例" width="740px">
      <div class="flex flex-justify-between" style="width:100%;max-height:500px;overflow:hidden">
        <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/invitingSharer (3).png" style="width:218px" alt="">
        <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/invitingSharer (2).png" style="width:218px" alt="">
        <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/invitingSharer (1).png" style="width:218px" alt="">
      </div>
    </el-dialog>
	</div>
	<!-- </transition> -->
</template>

<script>
	import config from '@/config/index'
	import {
		channelShopchannelShopInfo
	} from "@/api/goods"
	export default {
		name: 'normal-module-edit',
		components: {},
		props: {
			data: {
				type: Object,
				default: () => {
					return {}
				}
			},
			visible: {
				type: Boolean,
				default: false
			},
			name: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
        exampleShow:false,
				shopMsg:{}
			};
		},
		methods: {
			close() {
				this.$emit('close')
			},
			async getInfo(){
				try{
					let res = await channelShopchannelShopInfo()
					if(res.IsSuccess){
						this.shopMsg = res.Result
					}
				}finally{
					//
				}
			}
			
		},
		mounted() {
			this.getInfo()
		},
		watch: {
			data(value) {}
		}
	};
</script>
