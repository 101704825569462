<template>
	<div class="item">
		<div class="img">
			<img :src="item.AnchorImgUrl" alt="">
		</div>
		<div class="right">
			<div class="room-name">{{item.RoomName}}</div>
			<div>
				<div class="state" v-if="item.State == 1">
					<img src="https://cdn.dkycn.cn/images/melyshop/live-icon-6.gif" mode="widthFix"></img>
					<div>直播中</div>
				</div>
				<div class="not-start" v-if="item.State==0">
					<div class="state" style="background: #30d5a0;">
						<img src="https://cdn.dkycn.cn/images/melyshop/live-icon-2.png" mode="widthFix"></img>
						<div>预告</div>
					</div>
					<div class="time" v-if="item.timeStr">{{item.timeStr}}</div>
				</div>
				
				<div class="state" style="background: #999999;" v-if="item.State == 2">
					<!-- <img src="https://cdn.dkycn.cn/images/melyshop/live-icon-2.png" mode="widthFix"></img> -->
					<div>已结束</div>
				</div>
			</div>
		
			<div class="name">
				<img src="https://cdn.dkycn.cn/images/melyshop/live-icon-3.png"></img>
				{{item.AnchorNickName}}
			</div>
		</div>
		<div class="btn">
			<div></div>
			<img src="https://cdn.dkycn.cn/images/melyshop/live-icon-4.png"></img>
		</div>
	</div>
</template>

<script>
	export default {
	    name: 'live-item',
	    components: {
	    },
	    props: {
	        item: {
	            type: Object,
	            default: ()=>{
	                return {}
	            }
	        }
	    },
	    data () {
	        return {
	        };
	    },
	    methods: {
	
	    },
	    mounted () {
	    },
	    watch: {
	    }
	};
</script>

<style lang="less" scoped>
	.item {
		background-color: #fff;
		padding: 15px;
		display: flex;
		align-items: center;
	
		.img {
			flex: 0 0 auto;
			width: 80px;
			overflow: hidden;
			margin-right: 15px;
			
			img{
				display: block;
				width: 100%;
			}
		}
	
		.right {
			flex: 1 1 auto;
			overflow: hidden;
			display: flex;
			min-height: 80px;
			flex-direction: column;
			justify-content: space-around;
	
			.room-name {
				font-size: 15px;
				font-weight: bold;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			
			.not-start{
				display: flex;
				align-items: center;
				
				.time{
					font-size: 11px;
					color: #999;
					margin-left: 5px;
				}
			}
	
			.state {
				background: #fb4a4a;
				color: #fff;
				display: inline-flex;
				align-items: center;
				border-radius: 3px;
				padding: 2px 5px;
				font-size: 11px;
	
				img {
					display: block;
					width: 8px;
					margin-right: 3px;
				}
				
				div{
					line-height: 1;
				}
			}
	
			.name {
				display: flex;
				align-items: center;
				font-size: 12px;
				color: #999;
	
				img {
					width: 13px;
					height: 13px;
					margin-right: 3px;
				}
			}
		}
		
		.btn{
			flex: 0 0 auto;
			margin-left: 15px;
			min-height: 80px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			
			img{
				display: block;
				width: 37px;
				height: 37px;
			}
		}
	}
</style>
