<style scoped lang="less">

    .module-content{
        
        .picture-list{

            display: flex;
            flex-wrap: wrap;
            padding: 15px 0 20px;
            background:#fff;

            .img{
                width: 90px;
                margin-bottom: 8px;
                overflow: hidden;
                

                &:nth-last-child(-n+4){
                    margin-bottom: 0
                }

                img{
                    display: block;
                    width: 50px;
                    height: 50px;
                    border-radius: 100%;
                    margin: 0 auto;
                }
                p{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 12px;
                    margin-top:10px;
                    height: 18px;
                    line-height: 18px;
                    text-align: center;
                }
            }
            
        }

        .picture-list-1{

            white-space: nowrap;
            overflow: hidden;

            .img{
                display: inline-block;
                margin-right: 2px;
                width: 114px;
                padding-bottom: 7px;
                
                overflow: hidden;
                background:#fff;
                
                img{
                    display: block;
                    width: 114px;
                    height: 114px;
                    border: 1px solid #ddd;
                }
                p{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 12px;
                    margin-top: 7px;
                    height: 18px;
                    line-height: 18px;
                    text-align: center;
                }

            }



            
            
        }
        
    }
    .haveSpace{
        margin-bottom: 20px;
    }
    
</style>

<template>
    <div class="module-content"  :class="{haveSpace: !data.hideSpace}">
        <module-name :name="name"></module-name>
        <controler @edit="edit();data.editVisible=!data.editVisible" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        <edit :data="data" @submit="$emit('submit')" :visible="data.editVisible" :name="name" @close="data.editVisible=false"  />
        
        <div @click="edit();data.visible=true">
            <div class="picture-list" v-if="!data.list.length&&data.style===1">
                <div class="img" :key="i" v-for="i in 4">
                    <img :src="$store.state.pageManageConfig.staticImage.noPic" />
                    <p>标题内容</p>
                </div>
            </div>
            <div class="picture-list-1" v-if="!data.list.length&&data.style===2">
                <div class="img" :key="i" v-for="i in 4">
                    <img :src="$store.state.pageManageConfig.staticImage.noPic" />
                    <p>标题内容</p>
                </div>
            </div>


            <div class="picture-list" v-if="data.list.length&&data.style===1">
                <div class="img" :key="item.uid" v-for="(item,index) in data.list">
                    <img :src="$store.state.pageManageConfig.imghost+item.imgUrl" />
                    <p>{{item.name}}</p>
                </div>
                <div class="img" v-if="data.list.length%4>0" :key="i" v-for="i in (4-data.list.length%4)" style="border:none;background:transparent">
                </div>
            </div>

            <div class="picture-list-1" v-if="data.list.length&&data.style===2">
                <div class="img" :key="item.uid" v-for="(item, index) in data.list">
                    <img :src="$store.state.pageManageConfig.imghost+item.imgUrl" />
                    <p>{{item.name}}</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';

export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        edit
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
    data () {
        return {
        };
    },
    methods: {
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        
        close(){
            this.$emit('visibleChange', false)
        }

    },
    mounted () {
    },
    watch: {
        
    }
};
</script>

