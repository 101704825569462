<template>
	<div class="item">
		<div class="img">
			<img :src="item.AnchorImgUrl" alt="">
			<div class="state-info">
				<div class="state" v-if="item.State == 1">
					<img src="https://cdn.dkycn.cn/images/melyshop/live-icon-6.gif" mode="widthFix"></img>
					直播中
				</div>
				<div class="not-start" style="background: #30d5a0;padding-right: 5px;" v-if="item.State==0">
					<div class="state" style="background: #30d5a0;">
						<img style="width: 10px;" src="https://cdn.dkycn.cn/images/melyshop/live-icon-2.png" mode="widthFix"></img>
						预告
					</div>
					<div class="time" v-if="item.timeStr" style="color: #fff;">{{item.timeStr}}</div>
				</div>
				<div class="state" style="background: #999999;" v-if="item.State == 2">
					已结束
				</div>
			</div>
		</div>
		<div class="bottom">
			<div class="left">
				<div class="room-name">{{item.RoomName}}</div>

				<div class="name">
					主播：{{item.AnchorNickName}}
				</div>
			</div>
			<div class="right" v-if="item.ProductCoverImgUrl">
				<img :src="item.ProductCoverImgUrl"/>
				<div>￥{{item.ProductPrice}}</div>
			</div>
		</div>
		
		<!-- <div class="btn">
			<div></div>
			<img src="https://cdn.dkycn.cn/images/melyshop/live-icon-4.png"></img>
		</div> -->
	</div>
</template>

<script>
	export default {
	    name: 'live-item1',
	    components: {
	    },
	    props: {
	        item: {
	            type: Object,
	            default: ()=>{
	                return {}
	            }
	        }
	    },
	    data () {
	        return {
	        };
	    },
	    methods: {
	
	    },
	    mounted () {
	    },
	    watch: {
	    }
	};
</script>

<style lang="less" scoped>
	.item {
		background-color: #fff;
		border-radius: 4px;
		overflow: hidden;
		box-shadow: 0 0 6px 1px rgba(0,0,0,.2);
	
		.img {
			position: relative;
			flex: 0 0 auto;
			width: 100%;
			height: 173px;
			overflow: hidden;
			margin-right: 15px;
			
			img{
				display: block;
				width: 100%;
			}
			
			.state-info{
				position: absolute;
				left: 0;
				top: 5px;
			}
			
			.not-start{
				display: flex;
				align-items: center;
				
				.time{
					font-size: 11px;
					color: #999;
					margin-left: 5px;
				}
			}
				
			.state {
				background: #fb4a4a;
				color: #fff;
				display: inline-flex;
				align-items: center;
				// border-radius: 3px;
				padding: 2px 5px;
				font-size: 11px;
				
				img {
					display: block;
					width: 8px;
					margin-right: 3px;
				}
			}
		}
		
		.bottom{
			display: flex;
			overflow: hidden;
			padding: 10px 5px 10px 10px;
			
			.right{
				flex: 0 0 auto;
				width: 50px;
				background-color: #f5f5f5;
				
				img{
					display: block;
					width: 100%;
					height: 40px;
				}
				
				div{
					font-size: 12px;
					color: #fb4a4a;
					padding: 5px;
					text-align: center;
				}
			}
		}
	
		.left {
			flex: 1 1 auto;
			overflow: hidden;
			display: flex;
			height: 62px;
			flex-direction: column;
			justify-content: space-between;
			margin-right: 5px;
	
			.room-name {
				font-size: 15px;
				font-weight: bold;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			
			
	
			.name {
				display: flex;
				align-items: center;
				font-size: 12px;
				color: #999;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100px;
	
				img {
					width: 13px;
					height: 13px;
					margin-right: 3px;
				}
			}
		}
		
		.btn{
			flex: 0 0 auto;
			margin-left: 15px;
			min-height: 80px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			
			img{
				display: block;
				width: 37px;
				height: 37px;
			}
		}
	}
</style>

