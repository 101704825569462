<style scoped lang="less">
    .search-box{
        margin-bottom: 20px;
    }
    .table-box{
        margin-bottom: 15px;
    }
	
	.shop-name{
		display: flex;
	}
	.shop-name .img{
		flex: 0 0 auto;
	}
	.shop-name .name{
		flex: 1 1 auto;
		overflow: hidden;
		display: flex;
		align-items: center;
	}
</style>
<template>
    <el-dialog
        :visible="show"
        width='990px'
        title="淘客商品标签"
        @close="cancel">


        <div class="table-box">
            <el-table :data="data" v-loading="loading" row-key="TagId" ref="tab" @selection-change="handleSelectionChange" max-height="500">
            	<el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
            	<el-table-column prop="TagName" label="商品标签" width="700px"></el-table-column>
            </el-table>
		</div>

        <el-pagination :current-page="pageIndex" :total="total" :page-size="pageSize" @current-change="pageChange"></el-pagination>
        
        <div slot="footer">
            <el-button type="primary" @click="btnSure">确定</el-button>
        </div>

    </el-dialog>
</template>
<script>
    import {
      taoKeProductTaglist
    } from "@/api/sv3.0.0"
   import config from '@/config/index'
    export default {
        components: {
            
        },
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            isSingle: { //是否是单选
                type: Boolean,
                default: false
            },
            selectedList: {
                type: Array,
                default: ()=>{
                    return []
                }
            }
        },
        data () {
            return {
                keywords: '',
                pageIndex: 1,
                pageSize: 10,
                total: 0,
                loading: false,
                selectedData: [],
                singleData: {},
                data: [],
                show: false,
				        imgUrl: config.IMG_BASE
            };
        },
        methods: {
            async getData(){
                const params = {
                    PlatformType:1
                }
                
                this.loading = true;
                
                const result = await taoKeProductTaglist(params);
                
                this.data = result.Result;
                this.pageSize = result.Result.length
                this.total = result.Result.length;
                this.loading = false;
				
                if (!this.isReady){
                  if (this.$refs['tab']){
                    this.selectedList.map(item => {
                      this.$refs['tab'].toggleRowSelection(item, true);
                    })
                    this.isReady = true;
                  }
                }

            },

			getRowKey(row){
				return row.Id
			},
            cancel(){
                this.$emit('cancel');
            },
            pageChange(value){
                this.pageIndex = value;
                this.getData();
            },
            search(){
                this.pageIndex = 1;
                this.getData();
            },
			//当前页全选
			handleSelectionChange(val) {
				this.selectedData = val;
			},
            selectedRow(record, row){
                this.singleData = row;
            },
            btnSure(){
                this.$emit('change', this.selectedData);
                this.cancel();
            }
        },
        watch: {
            visible(value){
                this.show = value;

                if (value){
					if (this.$refs['tab']){
						this.$refs['tab'].clearSelection()
						this.selectedList.map(item => {
							this.$refs['tab'].toggleRowSelection(item, true);
						})
					}
                    this.getData();
                }
            }
        },
        mounted () {
            // this.selectedList.map(item => {
            // 	this.$refs['tab'].toggleRowSelection(item, true);
            // })
            this.getData();
        },
        created () {
			
        },
        dispatch () {
            
        }
    };
</script>
