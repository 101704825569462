<style scoped lang="less">
    .singleRowProduct-module{
        
        .product-list{

            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;

            .product-item{

                width: 115px;
                background: #fff;
                padding-bottom: 10px;
                margin-bottom: 10px;

                &:nth-last-child(-n+3){
                    margin-bottom: 0;
                }

                img{
                    width: 115px;
                    height: 115px;
                    border: 1px solid #ddd;
                }

                .name{
                    margin: 5px 5px 10px;
                    font-size: 14px;
                    text-align: center;
                    
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                
                .pro-info{
                    display: flex;
                    margin: 0 5px;

                    .left{
                        flex: 1 1 auto;
                        color: #1890ff;
                        font-weight: bold;
                        font-size: 16px;
                        margin-top: -3px;

                        .old{
                            font-size: 16px;
                            color: #ccc;
                            font-weight: 400;
                            text-decoration: line-through;
                        }
                    }
                    .textCenter{
                        text-align: center;
                    }
                }
            }

            .towItem{
                width:175px;

                img{
                    width: 175px;
                    height: 175px;
                }
            }
            
        }

        .label-list{
            overflow: hidden;
            padding: 15px 0 20px 8px;

            .label-item{
                float: left;
                width: 60px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                font-size: 12px;
                color: #fff;
                background: #1890ff;
                margin-right: 15px;

                &:last-child{
                    margin-right: 0;
                }
            }
        }
        
    }
    .haveSpace{
        margin-bottom: 20px;
    }
</style>

<template>
    <div class="singleRowProduct-module"  :class="{haveSpace: !data.hideSpace}">
        <module-name :name="name"></module-name>
        <controler @edit="edit();data.editVisible=!data.editVisible" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        <edit :data="data" @submit="$emit('submit')" :visible="data.editVisible" :name="name" @close="data.editVisible=false"  />
        
        <div @click="edit();data.visible=true">
            <div class="label-list">
                <div class="label-item" :key="index" v-for="(item, index) in data.list.length ? data.list : normalLabelList">
                    {{item.label}}
                </div>
            </div>

            <div class="product-list">
                <div class="product-item" :class="{towItem: data.style===1}" :key="i" v-for="i in data.style===1 ? 2 : 3">
                    <img :src="normalData.url" />
                    <p class="name">{{normalData.name}}</p>
                    <div class="pro-info">
                        <div class="left textCenter">
                            ￥{{normalData.price}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';



export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        edit,
        
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
    data () {
        return {
            btnType: 1, //按钮三种样式
            normalData: {
                url: this.$store.state.pageManageConfig.staticImage.noPic,
                name: '此处是商品名称',
                price: 99.00,
                marketPrice: 100.00,
            },
            normalLabelList: [
                {
                    label: '标签1'
                },
                {
                    label: '标签2'
                },
                {
                    label: '标签3'
                },
                {
                    label: '标签4'
                },
                {
                    label: '标签5'
                }
            ]
        };
    },
    methods: {
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        
        close(){
            this.$emit('visibleChange', false)
        }

    },
    mounted () {
    },
    watch: {
        
    }
};
</script>

