<style scoped lang="less">
    .add-friends-module{
		
		
		.custom{
			background-color: #fff;
			padding: 10px;
			display: flex;
			align-items: center;
			overflow: hidden;
			
			.img{
				flex: 0 0 auto;
				width: 40px;
				height: 40px;
				
				img{
					width: 40px;
					height: 40px;
				}
			}
			
			
			.txt{
				flex: 1 1 auto;
				display: -webkit-box; 
				-webkit-box-orient:vertical; 
				-webkit-line-clamp: 2; 
				overflow: hidden;
				line-height: 20px;
				max-height: 40px;
				font-size: 14px;
				margin: 0 10px;
			}
			.btn{
				flex: 0 0 auto;
				width: 75px;
				height: 28px;
				line-height: 28px;
				text-align: center;
				color: #fff;
				font-size: 13px;
				background-color: #dd3131;
				border-radius: 14px;
			}
		}
    }
    .haveSpace{
        margin-bottom: 10px;
    }
	
	
</style>

<template>
    <div class="add-friends-module" @mouseenter="data.visible=true" @mouseleave="data.visible=false"  style="position: relative;padding-left: 40px;padding-right: 45px;" :class="{haveSpace: !data.hideSpace}">
        <!-- <module-name :name="name"></module-name> -->
        <controler @edit="edit();data.editVisible=true" :move="false" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        

		<div class="custom" v-if="data.style === 1">
			<div class="img">
				<img v-if="data.entranceImageType===1" :src="imgUrl + data.entranceImageUrlNormal">
				<img v-else :src="imgUrl + data.entranceImageUrl">
			</div>
			<div class="txt">{{data.describe}}</div>
			<div class="btn">{{data.buttonText}}</div>
		</div>
		<div class="custom-img" v-else>
			<img style="width: 100%;display: block;" v-if="data.imageUrl" :src="imgUrl + data.imageUrl">
			<div v-else style="height: 50px;background-color: #bbb;"></div>
		</div>
		
		<div class="cover" v-if="data.visible" style="position: absolute;left:40px;top:0;right: 45px;height: 100%;border: 3px solid #0000FF;"></div>
		
    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';

import config from '@/config/index';

export default {
    name: 'add-friends',
    components: {
        moduleName,
        controler,
        edit,
        
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
    data () {
        return {
            imgUrl: config.IMG_BASE,
            btnType: 1, //按钮三种样式
            normalData: {
                url: this.$store.state.pageManageConfig.staticImage.noPic,
                name: '此处是商品名称',
                price: 99.00,
                marketPrice: 100.00,
            }
        };
    },
    methods: {
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        
        close(){
            this.$emit('visibleChange', false)
        }

    },
    mounted () {
    },
    watch: {
        
    }
};
</script>

