<style lang="less">

    .singleRowpicture-module{
		position: relative;
        
        .product-list{

            img{
                display: block;
                width: 100%;
            }

            .item{
                margin-bottom: 10px;

                &:last-child{
                    margin-bottom: 0;
                }
            }
            
        }
        
    }
    .haveSpace{
        margin-bottom: 10px;
    }
	
	.swiper-pagination{
		position: absolute;
		bottom: 10px;
		left: 50%;
		transform: translateX(-50%);
	}
	.swiper-pagination span{
		margin-right: 5px;
	}
	.swiper-pagination-bullet{
		margin-right: 5px;
	}
    
</style>

<template>
    <div class="singleRowpicture-module" @mouseenter="data.visible=true" @mouseleave="data.visible=false" style="position: relative;padding-left: 40px;padding-right: 45px;" :class="{haveSpace: !data.hideSpace}">
        <module-name :name="name"></module-name>
        <controler @edit="edit();data.editVisible=true" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        <!-- <edit :data="data" @submit="$emit('submit')" :visible="data.editVisible" :name="name" @close="data.editVisible=false"  /> -->
        
        <div>
            <div class="product-list" v-if="!data.list.length">
                <img :src="$store.state.pageManageConfig.staticImage.noPic1" />
            </div>
			<div class="product-list" v-else-if="data.list.length==1">
			    <img :src="$store.state.pageManageConfig.imghost+data.list[0].imgUrl"/>
			</div>
            <div class="product-list" v-else>
               <!-- <div class="item" :key="index" v-for="(item,index) in data.list">
                    <img :src="$store.state.pageManageConfig.imghost+item.imgUrl" />
                </div> -->
				<swiper :options="swiperOption" ref="mySwiper">
				    <swiper-slide :key="index" v-for="(item,index) in data.list">
				        <img :src="$store.state.pageManageConfig.imghost+item.imgUrl" />
				    </swiper-slide>
				</swiper>
				<div class="swiper-pagination" slot="pagination"></div>
            </div>
        </div>
		
		<div class="cover" v-if="data.visible" style="position: absolute;left:40px;top:0;right: 45px;height: 100%;border: 3px solid #0000FF;z-index: 1;"></div>

    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';

import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        edit,
		swiper,
		swiperSlide
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
    data () {
        return {
			swiperOption: {
			    autoplay: true,
			    pagination: {
			        el: '.swiper-pagination'
			    }
			},
        };
    },
    methods: {
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        
        close(){
            this.$emit('visibleChange', false)
        }

    },
    mounted () {
    },
    watch: {
        
    }
};
</script>

