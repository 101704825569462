<style scoped lang="less">
	.normal-module-edit {
		// position: absolute;
		// right: -530px;
		width: 480px;
		padding: 20px 20px 30px;
		background: #fff;

		.title {
			position: relative;
			font-size: 18px;
			padding-bottom: 20px;
			margin-bottom: 25px;
			border-bottom: 1px solid #ddd;
		}

		.icon-close {
			position: absolute;
			right: 0;
			top: -12px;
			cursor: pointer;
		}

		.tip {
			margin-right: 10px;
			font-size: 12px;
			color: #999;
			line-height: 18px;
			margin-top: 10px;
			width: 300px;
		}
	}
	
	.share-image-wraper{
		display: flex;
		align-items: center;
		
		.right{
			width: 190px;
			margin-left: 10px;
			padding-top: 8px;
			
			.right-tip{
				font-size: 12px;
				color: #999;
				line-height: 20px;
			}
			.right-clear{
				font-size: 14px;
				color: #1890ff;
				cursor: pointer;
			}
		}
	}

	.share-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		
	}

	.share-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.share-uploader {
		font-size: 28px;
		color: #8c939d;
		width: 120px;
		height: 96px;
		line-height: 96px;
		text-align: center;
		border: 1px dashed #ddd;
		overflow: hidden;
	}
	.avatar-uploader-icon{
		font-size: 28px;
		color: #8c939d;
		width: 120px;
		height: 96px;
		line-height: 96px;
		text-align: center;
	}

	.avatar {
		width: 120px;
		height: 96px;
		display: block;
	}
	
	.normal-module-edit ::v-deep .el-color-picker--medium .el-color-picker__trigger{
		width: 160px;
	}
</style>

<template>
	<!-- <transition name="scale"> -->
	<div class="normal-module-edit" v-if="visible">

		<p class="title">
			{{name}}
			<!-- <i @click="close" class="el-icon-close icon-close" style="font-size: 32px;color: #999;"></i> -->
		</p>

		<el-form :model="data" label-width="80px">
			<el-form-item label="页面标题" prop="title">
				<el-input v-model="data.title" style="width: 300px;"></el-input>
			</el-form-item>
			<el-form-item label="页面底色" prop="title">
				<el-color-picker v-model="data.bgColor" style="width: 100px;"></el-color-picker>
			</el-form-item>
			<!-- <el-form-item label="分享图片" prop="title">
				<div class="share-image-wraper">
					<el-upload class="share-uploader" :action="imgApi" :show-file-list="false" :on-success="handleSuccess"
					 :before-upload="beforeUpload">
						<img v-if="data.shareImage" :src="imgUrl + data.shareImage" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<div class="right">
						<div class="right-tip">建议图片宽高比5:4,宽高比不匹配时,图片将被压缩或拉伸以铺满画面;大小请控制在2M以内</div>
						<div class="right-clear" @click="handleClear">清空图片</div>
					</div>
				</div>
				
			</el-form-item>
			<el-form-item label="分享描述" prop="title">
				<el-input v-model="data.shareTitle" maxlength="25" style="width: 300px;"></el-input>
				<div class="tip">用户分享页面给微信好友时，将自动使用该描述；若未配置描述，将使用页面标题</div>
			</el-form-item> -->
		</el-form>

	</div>
	<!-- </transition> -->
</template>

<script>
	import config from '@/config/index'
	export default {
		name: 'normal-module-edit',
		components: {},
		props: {
			data: {
				type: Object,
				default: () => {
					return {}
				}
			},
			visible: {
				type: Boolean,
				default: false
			},
			name: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				obj: this.data,
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
			};
		},
		methods: {
			close() {
				this.$emit('close')
			},
			handleSuccess(res, file) {
				this.data.shareImage = res[0];
				// this.$forceUpdate()
			},
			beforeUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isLt2M) {
					this.$message.error('图片大小请控制在2M及以内');
				}
				return isLt2M;
			},
			handleClear(){
				this.data.shareImage = '';
			}
		},
		mounted() {},
		watch: {
			data(value) {
				this.obj = value;
			}
		}
	};
</script>
