<style scoped lang="less">
    .singleRowProduct-module{
        
        .product-list{
            .product-item{
                
                background: #fff;

                &:last-child{
                    margin-bottom: 0;
                }

                .name{
                    margin: 10px 10px 5px;
                    font-size: 14px;
                    
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

            }
            
        }
        .video-box{
            position: relative;

            .coverImage{
                position: absolute;
                left:0;
                top:0;
                width: 100%;
                height: 100%;
            }
        }
        
    }
    .haveSpace{
        margin-bottom: 10px;
    }
</style>

<template>
    <div class="singleRowProduct-module"  @mouseenter="data.visible=true" @mouseleave="data.visible=false" style="position: relative;padding-left: 40px;padding-right: 45px;" :class="{haveSpace: !data.hideSpace}">
        <module-name :name="name"></module-name>
        <controler @edit="edit();data.editVisible=true" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        <!-- <edit :data="data" @submit="$emit('submit')" :visible="data.editVisible" :name="name" @close="data.editVisible=false"  /> -->
        
        <div>
          <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/invitingSharer-contentImg.png" style="width:100%;padding:10px 15px" alt="">
        </div>

		<div class="cover" v-if="data.visible" style="position: absolute;left:40px;top:0;right: 45px;height: 100%;border: 3px solid #0000FF;"></div>
    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';
import config from '@/config/index'


export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        edit,
        
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
    data () {
        return {
            
        };
    },
	computed:{
	},
    methods: {
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        
        close(){
            this.$emit('visibleChange', false)
        }

    },
    mounted () {
    },
    watch: {
        
    }
};
</script>

