<style scoped lang="less">
    .singleRowProduct-module{
        
    }
    .haveSpace{
        margin-bottom: 20px;
    }
</style>

<template>
    <div class="singleRowProduct-module"  @mouseenter="data.visible=true" @mouseleave="data.visible=false" style="position: relative;padding-left: 40px;padding-right: 45px;" :class="{haveSpace: !data.hideSpace}">
        <module-name :name="name"></module-name>
        <controler @edit="edit();data.editVisible=true" @moveUp="$emit('moveUp')" :visible="data.visible" @moveDown="$emit('moveDown')" @remove="$emit('remove')" ></controler>
        
        
        <div class="">
          <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/dockRoutuan-defaultShopImg3.png"
          style="width:100%" alt="">
        </div>

        
		    <div class="cover" v-if="data.visible" style="position: absolute;left:40px;top:0;right: 45px;height: 100%;border: 3px solid #0000FF;"></div>
    </div>
</template>

<script>

import moduleName from '../../moduleName';
import controler from '../../controler';
import edit from './edit';


export default {
    name: 'normal-module',
    components: {
        moduleName,
        controler,
        edit,
        
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        
    },
    data () {
        return {

        };
    },
    mounted () {
    },
    methods: {
        edit(){
            this.$emit('visibleChange', this.data.visible)
        },
        
        close(){
            this.$emit('visibleChange', false)
        }

    },
};
</script>

