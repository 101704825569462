<style scoped lang="less">
    .singleRow-module-edit{
        position: absolute;
        right: -530px;
        width: 480px;
        padding: 20px 20px 30px;
        background: #fff;
        font-size: 14px;

        .title{
            position: relative;
            font-size: 18px;
            padding-bottom: 20px;
            margin-bottom: 25px;
            border-bottom: 1px solid #ddd;
        }

        .icon-close{
            position: absolute;
            right: 0;
            cursor: pointer;
        }

        .tip{
            margin-bottom: 20px;
        }

        .product-list{
            .product-item{
                margin-bottom: 20px;

                .tools{
                    overflow: hidden;
                    margin-bottom: 8px;

                    .left{
                        float: left;
                    }
                    .right{
                        float: right;
                        color: #f00000;
                        cursor: pointer;
                        margin-right: 30px;
                    }
                }
            }
            
        }

        .tip1{
            text-align: center;
            color: #999;

            .count{
                color: #1890ff;
            }
        }

        .add-btn{
            text-align: center;
            border: 1px dashed #ddd;
            font-size: 16px;
            color: #666;
            height: 48px;
            line-height: 48px;
            text-align: center;
            margin-top: 10px;
            cursor: pointer;
        }

        .tip2{
            margin-top: 30px;
            margin-bottom: 10px;
        }

        .mod-footer{
            margin-top: 50px;
            padding-top: 20px;
            border-top: 1px solid #ddd;

            display: flex;

            .left{
                flex: 1 1 auto;
                padding-top: 6px;
            }
        }


    }

    
</style>

<template>
    <transition name="scale">
        <div class="singleRow-module-edit" v-if="visible">
            
            <p class="title">
                {{name}} 
                <Icon  @click="close" type="ios-close-empty" size=32 class="icon-close" color="#999"></Icon>
            </p>

            <p class="tip" style="color: #999">请设置标签名称，并选择标签对应的商品分类（最多添加5个）</p>

            <draggable class="product-list" :list="data.list" :options="{animation: 300, handle:'.product-item'}">
                <transition-group name="bounce-out-left">
                    <div class="product-item" :key="item.uid" v-for="(item, index) in data.list">
                        <div class="tools">
                            <span class="left">标签{{index+1}}与分类</span>
                            <span class="right" @click="deleteData(index)">删除</span>
                        </div>
                        <div class="cont">
                            <Input style="width: 200px" v-model="item.label" placeholder="请输入标签名称" :maxlength="5" />
                            -
                            <Select style="width: 200px" v-model="item.classifyId">
                                <Option :value="record.Id" :key="record.Id" v-for="record in classifyList">{{record.CategoryName}}</Option>
                            </Select>
                        </div>
                    </div>
                </transition-group>
            </draggable>

            <p class="tip1">您已添加<span class="count">{{data.list.length}}</span>个标签</p>
            <div class="add-btn" @click="addLabel">
                <Icon type="ios-plus-outline" style="margin-right: 5px;vertical-align: -2px;" size=18></Icon>
                添加标签{{data.list.length}}/5
            </div>

            <p class="tip2">商品展示样式</p>
            <RadioGroup v-model="data.style">
                <Radio :label="1"><span style="font-size: 14px;color:#666;margin-right: 20px">样式一(两列)</span></Radio>
                <Radio :label="2"><span style="font-size: 14px;color:#666">样式二(三列)</span></Radio>
            </RadioGroup>

            <div class="mod-footer">
                <div class="left">
                    <Checkbox v-model="data.hideSpace"><span style="font-size: 14px;color:#666">隐藏模块下方的间距</span></Checkbox>
                </div>
                <div class="right">
                    <Button type="primary" @click="saveData">保存</Button>
                </div>
            </div>

        </div>
    </transition>
</template>

<script>

import draggable from 'vuedraggable';

export default {
    name: 'normal-module-edit',
    components: {
        draggable
    },
    props: {
        data: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        visible: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            classifyList: []
        };
    },
    methods: {
        async getData(){
            const result = await this.$ajax('getCategoryList', {IsFirstCategory: true});
            this.classifyList = result || [];
        },
        close(){
            this.$emit('close')
        },
        addLabel(){

            if (this.data.list.length >= 5){
                this.$Message.error('最多添加五个标签');
                return;
            }

            this.data.list.push({
                label: '',
                classifyId: 0,
                uid: this.guid()
            })
        },
        deleteData(index){
            this.data.list.splice(index, 1);
        },
        saveData(){
            this.$emit('submit')
        },
        guid(){
            var a=function(){return(65536*(1+Math.random())|0).toString(16).substring(1)};
            return a()+a()+"-"+a()+"-"+a()+"-"+a()+"-"+a()+a()+a()
        }

    },
    mounted () {
        this.getData();
    },
    watch: {
        data(value){
            //this.$set(this.data,'btnType',1)
            
            //this.data.btnType = 1;
            //console.log(value)
        }
    }
};
</script>

